import { type ApolloError } from '@apollo/client'
import { Temporal } from '@js-temporal/polyfill'
import gql from 'graphql-tag'
import React, { useEffect } from 'react'
import { ActivityIndicator, TouchableOpacity } from 'react-native'
import { HStack, Text } from 'react-stacked'

import { type DeliveryTimeSlotFragment, GetTimeSlotsDocument, Periodicity, useSetDeliveryTimeSlotDisabledMutation } from '../../../types/graphql'
import ignoreAsync from '../../util/ignoreAsync'
import serializeZonedDateTimeRange, { type ZonedDateTimeRange } from '../../util/serializeZonedDateTimeRange'
import { ACTIVE_BUTTON_COLOR, INACTIVE_BUTTON_COLOR } from '../PanicButton'

gql`
  mutation SetDeliveryTimeSlotDisabled($deliveryTimeSlotId: ID!, $disabled: Boolean!, $periodicityInput: PeriodicityInput, $restaurantId: ID!) {
    setDeliveryTimeSlotDisabled(
      deliveryTimeSlotId: $deliveryTimeSlotId
      disabled: $disabled
      periodicityInput: $periodicityInput
      restaurantId: $restaurantId
    )
  }
`

interface TimeSlotProps {
  dateTimeRange: ZonedDateTimeRange | null
  now: Temporal.Instant
  onError: (error?: ApolloError) => void
  periodicity: Periodicity
  periodicityDateTimeRange: ZonedDateTimeRange | null
  queueTime: Temporal.Duration
  restaurantId: string
  timeSlot: DeliveryTimeSlotFragment
}

const TimeSlot: React.FC<TimeSlotProps> = ({ dateTimeRange, now, onError, queueTime, periodicity, periodicityDateTimeRange, restaurantId, timeSlot }) => {
  const [setDeliveryTimeSlotDisabled, { error, loading }] = useSetDeliveryTimeSlotDisabledMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GetTimeSlotsDocument, variables: { restaurantId, dateTimeRange: dateTimeRange == null ? null : serializeZonedDateTimeRange(dateTimeRange) } }],
    variables: {
      deliveryTimeSlotId: timeSlot.id,
      disabled: !(timeSlot.disabled ?? false),
      periodicityInput: { dateTimeRange: periodicityDateTimeRange == null ? null : serializeZonedDateTimeRange(periodicityDateTimeRange), periodicity },
      restaurantId
    }
  })

  const orderTimeIsPassed = timeSlot.value == null ? false : Temporal.Duration.compare(now.until(timeSlot.value), queueTime) < 0
  const periodicityDataIncomplete = periodicityDateTimeRange?.end == null && periodicity !== Periodicity.None

  useEffect(() => {
    onError(error)
  }, [error])

  const active = timeSlot.disabled !== true
  const disabled = loading || periodicityDataIncomplete || orderTimeIsPassed

  const handlePress = ignoreAsync(async () => {
    if (disabled) return
    await setDeliveryTimeSlotDisabled()
  })

  return (
    <TouchableOpacity onPress={handlePress}>
      <HStack
        alignItems='center'
        backgroundColor={disabled ? '#eee' : active ? ACTIVE_BUTTON_COLOR : INACTIVE_BUTTON_COLOR}
        borderRadius={2}
        height={75}
        justifyContent='center'
        padding={8}
        width={75}
      >
        {loading
          ? <ActivityIndicator color='white' size='small' />
          : <Text align='center' color='#fff' size={16}>{timeSlot.label ?? 'No name'}</Text>}
      </HStack>
    </TouchableOpacity>
  )
}

export default TimeSlot
