import { gql } from '@apollo/client'
import { MaterialIcons } from '@expo/vector-icons'
import { Temporal } from '@js-temporal/polyfill'
import React, { useEffect, useMemo, useState } from 'react'
import { ActivityIndicator, TouchableOpacity } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'
import sortOn from 'sort-on'

import { useGetAccountDataQuery } from '../../types/graphql'
import FormContainer from '../components/FormContainer'
import Layout, { ScreenType } from '../components/Layout'
import PasswordPrompt from '../components/PasswordPrompt'
import useDate from '../util/useDate'
import useNavigation from '../util/useNavigation'

gql`
  query GetAccountData {
    me {
      id

      username

      restaurantsWithAccess(access:Write) {
        id

        name
      }
    }

    sudoMode {
      id

      expiresAt
    }
  }
`

const AccountEdit: React.FC = () => {
  const [navigation] = useNavigation()

  const [isOpen, setIsOpen] = useState(false)

  const { data, loading } = useGetAccountDataQuery()

  const now = useDate({ interval: 'minute' })

  const isInSudoMode = useMemo(() => {
    if (data?.sudoMode?.expiresAt == null) return false

    return Temporal.Instant.compare(Temporal.Now.instant(), Temporal.Instant.from(new Date(data.sudoMode.expiresAt).toISOString())) < 0
  }, [data?.sudoMode?.expiresAt, now])

  const restaurantId = useMemo(() => sortOn(data?.me?.restaurantsWithAccess ?? [], 'name')[0]?.id, [data?.me?.restaurantsWithAccess])

  const handleCancel = (): void => {
    setIsOpen(false)
    navigation.canGoBack() ? navigation.pop() : navigation.replace('RestaurantGetStarted', { restaurantId })
  }

  const handleSuccess = (): void => {
    setIsOpen(false)
  }

  const handleEmailChange = (): void => {
    navigation.navigate('EditUsername', {})
  }

  useEffect(() => {
    if (loading) return
    if (isInSudoMode) return

    setIsOpen(true)
  }, [loading, isInSudoMode])

  return (
    <Layout hideTitle screenType={ScreenType.Form} title='Konto'>
      {!loading ? null : (
        <>
          <Spacer height={24} />
          <ActivityIndicator />
        </>
      )}

      {!(isInSudoMode ?? false) ? <HStack grow={1} wrap /> : (
        <FormContainer gap={20} title='Konto'>
          <TouchableOpacity onPress={handleEmailChange}>
            <HStack alignItems='center' justifyContent='space-between'>
              <VStack>
                <Text size={19}>E-post</Text>

                <Text color='#666666' paddingTop={4} size={14}>{data?.me?.username}</Text>
              </VStack>

              <MaterialIcons name='edit' size={24} />
            </HStack>
          </TouchableOpacity>
        </FormContainer>
      )}

      {!isOpen ? null : <PasswordPrompt onCancel={handleCancel} onSuccess={handleSuccess} />}
    </Layout>
  )
}

export default AccountEdit
