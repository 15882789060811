import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons'
import React, { useMemo } from 'react'
import { type UseFormReturn, useFieldArray, useForm } from 'react-hook-form'
import { ActivityIndicator, TouchableOpacity } from 'react-native'
import { HStack, VStack } from 'react-stacked'
import unwrap from 'ts-unwrap'

import { type FullAddonProductFragment, type FullMenuAlternativeGroupFragment, type MenuAlternativeGroupInput, useGetAddonProductsQuery } from '../../types/graphql'
import yup, { type ObjectSchema, yupResolver } from '../lib/validation'
import formatCurrency from '../util/formatCurrency'
import ignoreAsync from '../util/ignoreAsync'
import { normalize } from '../util/normalize'

import Bin from './Bin'
import FormContainer from './FormContainer'
import SubmitFormButtons from './SubmitFormButtons'
import { CheckBox, IntegerField, Select, TextField } from './fields'

interface AlternativeItem {
  addonProductId?: string
  countableUsage?: number
  isAddonProduct: boolean
  isDefault?: boolean
  name?: string
}

interface AlternativeGroupFormFields {
  alternativeItems: AlternativeItem[]
  forceChoice: boolean
  isCountable: boolean
  maxChoices: number
  minChoices: number
  name: string
}

interface AlternativeItemRowProps {
  addonProducts?: readonly FullAddonProductFragment[] | null
  form: UseFormReturn<AlternativeGroupFormFields>
  index: number
  loadingAddonProducts: boolean
  onMoveDown?: () => void
  onMoveUp?: () => void
}

const AlternativeItemRow: React.FC<AlternativeItemRowProps> = ({ addonProducts, form, index, loadingAddonProducts, onMoveDown, onMoveUp }) => {
  const isAddonProduct: boolean = form.watch(`alternativeItems.${index}.isAddonProduct`)
  const isCountableProduct: boolean = form.watch('isCountable')

  return (
    <HStack alignItems='center' basis={1} gap={8} grow={1}>
      <VStack>
        <MaterialCommunityIcons name='chevron-up' onPress={onMoveUp} size={24} />
        <MaterialCommunityIcons name='chevron-down' onPress={onMoveDown} size={24} />
      </VStack>

      <HStack alignItems='center' wrap>
        <VStack basis={1} grow={1} minWidth={220} paddingHorizontal={normalize(8, 8)} paddingVertical={normalize(4, 4)}>
          {isAddonProduct
            ? loadingAddonProducts
              ? <ActivityIndicator />
              : (
                <Select
                  form={form}
                  name={`alternativeItems.${index}.addonProductId`}
                  options={(addonProducts ?? []).filter(({ name, properties }) => name != null && properties?.price != null).map(({ id, name, properties }) => ({
                    value: id,
                    title: `${unwrap(name)}, ${formatCurrency(unwrap(properties?.price))}`
                  }))}
                  title='Tilläggsprodukt'
                />
              )
            : (
              <TextField
                form={form}
                name={`alternativeItems.${index}.name`}
                title='Namn'
              />
            )}
        </VStack>

        <VStack grow={1} paddingHorizontal={8} paddingVertical={4}>
          <CheckBox
            form={form}
            name={`alternativeItems.${index}.isAddonProduct`}
            title='Tilläggsprodukt'
          />
        </VStack>

        <VStack grow={1} paddingHorizontal={8} paddingVertical={4}>
          <CheckBox
            form={form}
            name={`alternativeItems.${index}.isDefault`}
            title='Förvalt alternativ'
          />
        </VStack>

        <VStack grow={1} minWidth={164} paddingHorizontal={8} paddingVertical={4}>
          {!isCountableProduct ? null : (
            <IntegerField
              form={form}
              name={`alternativeItems.${index}.countableUsage`}
              title='Förbrukar antal'
            />
          )}
        </VStack>
      </HStack>
    </HStack>
  )
}

const schema: ObjectSchema<AlternativeGroupFormFields> = yup.object({
  alternativeItems: yup.array().of<AlternativeItem>(
    yup.object({
      addonProductId: yup.string().when('isAddonProduct', {
        is: true,
        then: schema => schema.min(1, 'En tilläggsprodukt måste väljas').required('En tilläggsprodukt måste väljas')
      }),
      countableUsage: yup.number().when('isCountable', {
        is: true,
        then: schema => schema.required('Ange antal val detta alternativ förbrukar')
      }),
      isAddonProduct: yup.boolean().required(),
      isDefault: yup.boolean().required(),
      name: yup.string().when('isAddonProduct', {
        is: false,
        then: schema => schema.trim().min(1).required('Namn måste anges')
      })
    }).required()
  ).required(),
  forceChoice: yup.boolean().required(),
  isCountable: yup.boolean().required(),
  minChoices: yup.number().required('Måste ange ett minsta belopp'),
  maxChoices: yup.number().required('Måste ange ett maxbelopp'),
  name: yup.string().trim().min(1).required()
})

interface FormProps {
  initialValues?: FullMenuAlternativeGroupFragment | null
  onDismiss: () => void
  onDelete?: () => void
  onSave: (input: MenuAlternativeGroupInput) => void
  restaurantId: string
  saving?: boolean
}

const AlternativeGroupForm: React.FC<FormProps> = ({ initialValues, onDismiss, onDelete, onSave, restaurantId, saving }) => {
  const { data, loading: loadingAddonProducts } = useGetAddonProductsQuery({ variables: { restaurantId } })

  const values = useMemo(() => ({
    alternativeItems: initialValues?.alternativeItems?.map(item => ({
      addonProductId: item.addonProduct?.id,
      countableUsage: item.countableUsage ?? 1,
      isAddonProduct: item.addonProduct != null,
      isDefault: item.isDefault ?? false,
      name: item.name ?? ''
    })) ?? [{
      countableUsage: 1,
      isAddonProduct: false,
      isDefault: false,
      name: ''
    }],
    forceChoice: initialValues?.forceChoice ?? false,
    isCountable: initialValues?.isCountable ?? false,
    maxChoices: initialValues?.maxChoices ?? 0,
    minChoices: initialValues?.minChoices ?? 0,
    name: initialValues?.name ?? ''
  }), [initialValues])

  const form = useForm<AlternativeGroupFormFields>({
    criteriaMode: 'all',
    resolver: yupResolver(schema),
    values
  })

  const fieldArray = useFieldArray({
    control: form.control,
    name: 'alternativeItems'
  })

  function handleSave (formInput: AlternativeGroupFormFields): void {
    onSave({
      ...formInput,
      alternativeItems: formInput.alternativeItems.map(item => ({
        addonProductId: item.isAddonProduct ? unwrap(item.addonProductId) : undefined,
        countableUsage: item.countableUsage,
        isDefault: item.isDefault,
        name: item.isAddonProduct ? unwrap(data?.restaurant?.menu?.addonProducts?.find(product => product.id === item.addonProductId)?.name).toString() : unwrap(item.name)
      }))
    })
  }

  return (
    <FormContainer gap={16} wide>
      <TextField
        form={form}
        name='name'
        title='Namn'
      />

      <VStack gap={10}>
        <CheckBox
          form={form}
          name='isCountable'
          title='Möjlighet att lägga till flera av samma alternativ'
        />

        <CheckBox
          form={form}
          name='forceChoice'
          title='Visa alternativ när produkten läggs i varukorgen'
        />
      </VStack>

      <VStack gap={8}>
        {fieldArray.fields.map((field, index) => (
          <HStack
            key={field.id}
            alignItems='center'
            backgroundColor={['#e8e8e8', '#d8d8d8'][index % 2]}
            borderRadius={4}
            gap={8}
            paddingHorizontal={16}
            paddingVertical={8}
          >
            <AlternativeItemRow
              addonProducts={data?.restaurant?.menu?.addonProducts?.filter(addonProduct => !(addonProduct.isDeliveryCost ?? false))}
              form={form}
              index={index}
              loadingAddonProducts={loadingAddonProducts}
              onMoveDown={index === fieldArray.fields.length - 1 ? undefined : () => {
                fieldArray.swap(index, index + 1)
                form.clearErrors()
              }}
              onMoveUp={index === 0 ? undefined : () => {
                fieldArray.swap(index, index - 1)
                form.clearErrors()
              }}
            />

            <Bin
              backgroundColor={['#ddd', '#f4f4f4'][index % 2]}
              onSelected={() => {
                fieldArray.remove(index)
              }}
            />
          </HStack>
        ))}

        <TouchableOpacity
          onPress={() => {
            fieldArray.append({
              addonProductId: '',
              countableUsage: 1,
              isAddonProduct: false,
              isDefault: false,
              name: ''
            })
          }}
        >
          <HStack alignItems='center' height={50} justifyContent='center'>
            <MaterialIcons name='add-circle' size={24} />
          </HStack>
        </TouchableOpacity>
      </VStack>

      <HStack gap={24} wrap>
        <VStack basis={1} grow={1} minWidth={60} paddingVertical={5}>
          <IntegerField
            form={form}
            name='minChoices'
            title='Minsta antal val'
          />
        </VStack>

        <VStack basis={1} grow={1} minWidth={60} paddingVertical={5}>
          <IntegerField
            form={form}
            name='maxChoices'
            title='Max antal val'
          />
        </VStack>
      </HStack>

      <SubmitFormButtons
        deleting={false}
        disableDeleteButton={saving}
        disableSaveButton={saving}
        onCancel={onDismiss}
        onDelete={onDelete}
        onSave={ignoreAsync(form.handleSubmit(handleSave))}
        saving={saving}
      />
    </FormContainer>
  )
}
export default AlternativeGroupForm
