import { MaterialIcons } from '@expo/vector-icons'
import { Button, Dialog, DialogContent, Menu, MenuItem, type PopoverProps } from '@mui/material'
import { useFocusEffect } from '@react-navigation/native'
import dotProp from 'dot-prop-immutable'
import gql from 'graphql-tag'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { TouchableOpacity } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'
import unwrap from 'ts-unwrap'

import { GetPageMenuDocument, type MenuCategoryFieldsFragment, type MenuProductFieldsFragment, type MenuProductPropertiesFieldsFragment, MenuType, RearrangalStatus, useGetPageMenuQuery, useRearrangeMenuCategoriesMutation, useRearrangeMenuProductsMutation } from '../../types/graphql'
import { AddButton, SortButton } from '../components/Buttons'
import DraggableList from '../components/DraggableList'
import InactiveBadge from '../components/InactivityBadge'
import Layout, { type Breadcrumb } from '../components/Layout'
import MultiEditForm, { type MultiEditFormProps } from '../components/MultiEditForm/MultiEditForm'
import CategoryToggleButton from '../components/RestaurantMenu/CategoryToggleButton'
import PageToggleButton from '../components/RestaurantMenu/PageToggleButton'
import { ProductToggle } from '../components/RestaurantMenu/ProductToggle'
import ProductVariantToggle from '../components/RestaurantMenu/ProductVariantToggle'
import ProductVariantView from '../components/RestaurantMenu/ProductVariantView'
import SubmitFormButtons from '../components/SubmitFormButtons'
import ignoreAsync from '../util/ignoreAsync'
import * as MenuTypes from '../util/menuTypes'
import { normalize } from '../util/normalize'
import useNavigation from '../util/useNavigation'
import usePersistedMenuPageScroll from '../util/usePersistedMenuPageScroll'

gql`
  mutation RearrangeMenuCategories($restaurantId: ID!, $menuPageId: ID!, $order: [ID!]!) {
    rearrangeMenuCategories(
      restaurantId: $restaurantId
      menuPageId: $menuPageId
      order: $order
    )
  }

  mutation RearrangeMenuProducts($restaurantId: ID!, $menuCategoryId: ID!, $order: [ID!]!) {
    rearrangeMenuProducts(
      restaurantId: $restaurantId
      menuCategoryId: $menuCategoryId
      order: $order
    )
  }
`

interface MultiEditData {
  changeType: MultiEditFormProps['changeType']
  products: readonly MenuProductFieldsFragment[]
}

interface MoreButtonProps {
  large?: boolean
  onMultiEdit: (event: MultiEditData) => void
  products: readonly MenuProductFieldsFragment[]
}

const MoreButton: React.FC<MoreButtonProps> = ({ large, onMultiEdit, products }) => {
  const [anchorEl, setAnchorEl] = useState<PopoverProps['anchorEl']>(null)

  const disabled = useMemo(() => products.length === 0, [products])

  const [someOpeningHours, somePrinters, someSecondaryPrinters, someAlternativeGroups] = useMemo(() => {
    function some<K extends keyof MenuProductPropertiesFieldsFragment> (field: K): boolean {
      for (const product of products) {
        for (const menuType of MenuTypes.ALL) {
          const properties = product[MenuTypes.productFieldName(menuType)]
          const value = properties?.[field]

          if (value != null && (!Array.isArray(value) || value.length > 0)) return true
        }
      }
      return false
    }

    return (['openingHours', 'printAt', 'secondaryPrinters', 'alternativeGroups'] as const).map(some)
  }, [products])

  const handleOnClick = useCallback((changeType: MultiEditData['changeType']) => () => {
    setAnchorEl(null)
    onMultiEdit({ changeType, products })
  }, [onMultiEdit, products, setAnchorEl])

  return (
    <>
      <Button onClick={event => setAnchorEl(event.currentTarget)}>
        <MaterialIcons name='more-vert' size={(large ?? false) ? 28 : 22} />
      </Button>
      <Menu
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        open={Boolean(anchorEl)}
      >
        <MenuItem disabled={disabled} onClick={handleOnClick('course')}>
          <Text>Justera rätt</Text>
        </MenuItem>
        <MenuItem disabled={disabled} onClick={handleOnClick('price')}>
          <Text>Justera priser</Text>
        </MenuItem>
        <MenuItem disabled={disabled} onClick={handleOnClick('reportGroup')}>
          <Text>Ändra rapportgrupper</Text>
        </MenuItem>
        <MenuItem disabled={disabled} onClick={handleOnClick('openingHours')}>
          <Text>{someOpeningHours ? 'Ändra' : 'Lägg till'} öppettider</Text>
        </MenuItem>
        <MenuItem disabled={disabled} onClick={handleOnClick('printerQueues')}>
          <Text>{somePrinters ? 'Ändra' : 'Lägg till'} skrivare</Text>
        </MenuItem>
        <MenuItem disabled={disabled} onClick={handleOnClick('secondaryPrinters')}>
          <Text>{someSecondaryPrinters ? 'Ändra' : 'Lägg till'} sekundärskrivare</Text>
        </MenuItem>
        <MenuItem disabled={disabled} onClick={handleOnClick('alternativeGroups')}>
          <Text>{someAlternativeGroups ? 'Ändra' : 'Lägg till'} alternativ</Text>
        </MenuItem>
      </Menu>
    </>
  )
}

function* extractVariants (product: MenuProductFieldsFragment): Generator<{ menuType: MenuType, variant: MenuProductPropertiesFieldsFragment }, void> {
  for (const menuType of [MenuType.EatIn, MenuType.TakeAway, MenuType.Delivery]) {
    const variant = product[MenuTypes.productFieldName(menuType)]
    if (variant?.reportGroup == null) continue

    yield { menuType, variant }
  }
}

interface CategoryViewProps {
  category: MenuCategoryFieldsFragment
  onDragEnd: (menuCategoryId: string) => (rearrangedProducts: MenuProductFieldsFragment[]) => void
  handleMultiEdit: (event: MultiEditData) => void
  hasMenuWriteAccess: boolean
}

const CategoryView: React.FC<CategoryViewProps> = ({ category, onDragEnd, handleMultiEdit, hasMenuWriteAccess }) => {
  const [navigation, { restaurantId, menuPageId }] = useNavigation<'MenuPageView'>()

  const [, setPersistedMenuPageScrollSettings] = usePersistedMenuPageScroll()

  const handleCreateProduct = useCallback((category: MenuCategoryFieldsFragment) => () => {
    setPersistedMenuPageScrollSettings({ menuPageId, pageYOffset: window.pageYOffset })
    navigation.navigate('MenuProductCreate', { restaurantId, menuCategoryId: category.id })
  }, [restaurantId, setPersistedMenuPageScrollSettings])

  const handleEditCategory = useCallback((category: MenuCategoryFieldsFragment) => () => {
    setPersistedMenuPageScrollSettings({ menuPageId, pageYOffset: window.pageYOffset })
    navigation.navigate('MenuCategoryEdit', { restaurantId, menuCategoryId: category.id })
  }, [restaurantId, setPersistedMenuPageScrollSettings])

  const handleProductPress = useCallback((product: MenuProductFieldsFragment) => () => {
    setPersistedMenuPageScrollSettings({ menuPageId, pageYOffset: window.pageYOffset })
    navigation.navigate('MenuProductEdit', { restaurantId, menuProductId: product.id })
  }, [restaurantId, setPersistedMenuPageScrollSettings])

  return (
    <VStack key={category.id} backgroundColor='#DDD' borderRadius={8} maxWidth={1024} padding={normalize(8, 10)}>
      <HStack>
        <HStack alignItems='center' grow={1} wrap>
          <CategoryToggleButton disabled={category.temporarilyDisabled ?? false} menuCategoryId={category.id} restaurantId={restaurantId} />
          {!(category.temporarilyDisabled ?? false)
            ? null
            : (
              <>
                <Spacer width={6} />
                <MaterialIcons color='red' name='warning' size={18} />
                <Spacer width={6} />
                <Text>Kategorin är inaktiverad</Text>
              </>
            )}

          <Spacer width={8} />

          <TouchableOpacity onPress={handleEditCategory(category)} style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text size={22} weight='bold'>{category.name}</Text>

            <Spacer width={8} />

            {!hasMenuWriteAccess ? null : <MaterialIcons name='edit' size={18} />}
          </TouchableOpacity>
        </HStack>

        {!hasMenuWriteAccess ? null : (
          <MoreButton
            onMultiEdit={handleMultiEdit}
            products={category.products ?? []}
          />
        )}
      </HStack>

      <Spacer height={8} />

      {!hasMenuWriteAccess ? null : (
        <AddButton
          icon='add-circle'
          onPress={handleCreateProduct(category)}
          title='Lägg till produkt'
        />
      )}

      <Spacer height={4} />

      <VStack alignItems='center'>
        <DraggableList
          data={category?.products ?? []}
          disabled={!hasMenuWriteAccess}
          keyExtractor={product => product.id}
          onDragEnd={!hasMenuWriteAccess ? undefined : onDragEnd(category.id)}
          renderItem={(dragHandle, product) => {
            const variants = Array.from(extractVariants(product))

            if (variants.length === 0) return null

            return (
              <VStack>
                <HStack>
                  <HStack alignItems='center' padding={8}>
                    <ProductToggle product={product} />
                  </HStack>

                  <TouchableOpacity onPress={handleProductPress(product)} style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                    <Spacer width={8} />

                    <Text weight='bold'>{product.name ?? ''}</Text>

                    <Spacer width={8} />

                    {product.quantityAvailable === 0
                      ? <InactiveBadge label='Slutsåld' />
                      : product.quantityAvailable != null && product.quantityAvailable <= 5
                      ? <InactiveBadge background='orange' label='Snart slut' />
                      : product.quantityAvailable != null
                      ? <InactiveBadge background='green' label='Lager' />
                      : null}

                    <Spacer grow={1} />

                    <MaterialIcons name='edit' size={18} />

                    <Spacer width={12} />
                  </TouchableOpacity>
                </HStack>

                <HStack>
                  <VStack justifyContent='center' padding={8}>
                    {dragHandle}
                    <Spacer height={12} />
                  </VStack>

                  <TouchableOpacity onPress={handleProductPress(product)} style={{ flex: 1 }}>
                    <VStack gap={8} grow={1} paddingBottom={8} paddingRight={8}>
                      {variants.map(({ menuType, variant }) => (
                        <ProductVariantView
                          key={menuType}
                          menuType={menuType}
                          titleIcon={<ProductVariantToggle menuType={menuType} product={product} />}
                          variant={variant}
                        />
                      ))}
                    </VStack>
                  </TouchableOpacity>
                </HStack>
              </VStack>
            )
          }}
          separator={<Spacer height={12} />}
        />
      </VStack>
    </VStack>
  )
}

const MenuPageView: React.FC = () => {
  const [navigation, { restaurantId, menuPageId }] = useNavigation<'MenuPageView'>()

  const { data, loading } = useGetPageMenuQuery({ variables: { restaurantId, menuPageId } })

  const [rearrangeMenuProducts] = useRearrangeMenuProductsMutation({ awaitRefetchQueries: true })
  const [rearrangeMenuCategories] = useRearrangeMenuCategoriesMutation({ awaitRefetchQueries: true })

  const [collapseCategories, setCollapseCategories] = useState(false)
  const [sortedCategoryIds, setSortedCategoryIds] = useState<string[]>([])
  const [sortedCategoryIdsChanged, setSortedCategoryIdsChanged] = useState(false)

  const [multiEditData, setMultiEditData] = useState<MultiEditData | null>(null)

  const hasMenuWriteAccess = data?.restaurant?.hasMenuWriteAccess ?? false
  const allProducts = useMemo(() => data?.restaurant?.menu?.page?.categories?.flatMap(category => category.products ?? []), [data?.restaurant?.menu?.page?.categories])

  const breadcrumbs = useMemo<Breadcrumb[]>(() => [
    { link: ['MenuView', { restaurantId }], title: 'Meny' }
  ], [restaurantId])

  const [persistedMenuPageScrollSettings, setPersistedMenuPageScrollSettings] = usePersistedMenuPageScroll()

  useFocusEffect(
    useCallback(() => {
      if (!loading && persistedMenuPageScrollSettings?.menuPageId === menuPageId) {
        setTimeout(() => window.scrollTo(0, persistedMenuPageScrollSettings.pageYOffset), 0)
        setPersistedMenuPageScrollSettings(null)
      }
    }, [loading, menuPageId, persistedMenuPageScrollSettings])
  )

  useEffect(() => {
    setSortedCategoryIds(data?.restaurant?.menu?.page?.categories?.map(cat => cat.id) ?? [])
  }, [data?.restaurant?.menu?.page?.categories])

  const handleDragEnd: CategoryViewProps['onDragEnd'] = (menuCategoryId: string) =>
    ignoreAsync(async (rearrangedProducts: MenuProductFieldsFragment[]) => {
      await rearrangeMenuProducts({
        variables: { restaurantId, menuCategoryId, order: rearrangedProducts.map(p => p.id) },
        optimisticResponse: { rearrangeMenuProducts: RearrangalStatus.Rearranged },

        update: (proxy) => {
          const categoryIndex = unwrap(data?.restaurant?.menu?.page?.categories?.findIndex(category => category.id === menuCategoryId))

          const newData = dotProp.set(data, `restaurant.menu.page.categories.${categoryIndex}.products`, rearrangedProducts)

          proxy.writeQuery({ query: GetPageMenuDocument, variables: { restaurantId, menuPageId }, data: newData })
        },
        refetchQueries: [{ query: GetPageMenuDocument, variables: { restaurantId, menuPageId } }]
      })
    })

  const handleDragEndCategories = (rearrangedCategories: MenuCategoryFieldsFragment[]): void => {
    setSortedCategoryIds(rearrangedCategories.map(category => category.id))
    setSortedCategoryIdsChanged(true)
  }

  const handleSort = (): void => {
    setSortedCategoryIds(data?.restaurant?.menu?.page?.categories?.map(cat => cat.id) ?? [])
    setSortedCategoryIdsChanged(false)
    setCollapseCategories(true)
  }

  const handleSaveCategoriesSort = ignoreAsync(async () => {
    setCollapseCategories(false)

    await rearrangeMenuCategories({
      variables: { restaurantId, menuPageId, order: sortedCategoryIds },
      optimisticResponse: { rearrangeMenuCategories: RearrangalStatus.Rearranged },

      update: (proxy) => {
        const categories = sortedCategoryIds.map(id => unwrap(data?.restaurant?.menu?.page?.categories?.find(cat => cat.id === id)))

        const newData = { ...data, restaurant: { ...data?.restaurant, menu: { ...data?.restaurant?.menu, page: { ...data?.restaurant?.menu?.page, categories } } } }

        proxy.writeQuery({ query: GetPageMenuDocument, variables: { restaurantId, menuPageId }, data: newData })
      },
      refetchQueries: [
        { query: GetPageMenuDocument, variables: { restaurantId, menuPageId } }
      ]
    })
  })

  if (loading) {
    return <Layout breadcrumbs={breadcrumbs} loading title={data?.restaurant?.menu?.page?.name} />
  }

  if (collapseCategories) {
    return (
      <Layout breadcrumbs={breadcrumbs} title={data?.restaurant?.menu?.page?.name}>
        <VStack maxWidth={1024} padding={20}>
          <Text size={22} weight='bold'>Sortera underkategorier</Text>

          <Spacer height={12} />

          <DraggableList
            data={sortedCategoryIds.map(id => unwrap(data?.restaurant?.menu?.page?.categories?.find(cat => cat.id === id)))}
            keyExtractor={category => category.id}
            onDragEnd={handleDragEndCategories}
            renderItem={(dragHandle, category) => (
              <HStack alignItems='center' padding={12}>
                {dragHandle}

                <Spacer width={12} />

                <Text size={16}>{category.name}</Text>
              </HStack>
            )}
          />

          <Spacer height={24} />

          <SubmitFormButtons
            disableSaveButton={!sortedCategoryIdsChanged}
            onCancel={() => setCollapseCategories(false)}
            onSave={handleSaveCategoriesSort}
            titleForSubmit='Spara sortering'
          />
        </VStack>
      </Layout>
    )
  }

  // Note by Tobias: I could unfortunately not get anything other than material-ui Dialog to work perfectly well with nested scroll views

  return (
    <Layout breadcrumbs={breadcrumbs} hideTitle title={data?.restaurant?.menu?.page?.name}>
      {multiEditData == null ? null : (
        <Dialog open scroll='paper'>
          <DialogContent>
            <MultiEditForm
              changeType={multiEditData.changeType}
              onDismiss={() => setMultiEditData(null)}
              products={multiEditData.products}
              restaurantId={restaurantId}
            />
          </DialogContent>
        </Dialog>
      )}

      <VStack gap={24} maxWidth={1024} padding={20}>
        <HStack>
          <HStack alignItems='center' gap={8} grow={1} wrap>
            {data?.restaurant?.menu?.page == null ? null : (
              <HStack alignItems='center' gap={6}>
                <PageToggleButton disabled={data?.restaurant?.menu?.page?.temporarilyDisabled ?? false} menuPageId={data?.restaurant?.menu?.page?.id} restaurantId={restaurantId} />

                {!(data?.restaurant?.menu?.page?.temporarilyDisabled ?? false) ? null : (
                  <>
                    <MaterialIcons color='red' name='warning' size={18} />
                    <Text>Huvudkategorin är inaktiverad</Text>
                  </>
                )}
              </HStack>
            )}

            <TouchableOpacity onPress={() => navigation.navigate('MenuPageEdit', { restaurantId, menuPageId })} style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Text size={28} weight='bold'>{data?.restaurant?.menu?.page?.name}</Text>

              <Spacer width={8} />

              {!hasMenuWriteAccess ? null : <MaterialIcons name='edit' size={20} />}
            </TouchableOpacity>
          </HStack>

          {!hasMenuWriteAccess ? null : (
            <MoreButton
              large
              onMultiEdit={setMultiEditData}
              products={allProducts ?? []}
            />
          )}
        </HStack>

        {data?.restaurant?.menu?.page?.categories?.length === 0 ?
          <Text color='grey'>För att kunna lägga till produkter i din meny behöver du först skapa en underkategori</Text>
          : null}

        {!(data?.restaurant?.hasMenuWriteAccess ?? false) ? null : (
          <HStack gap={8} paddingHorizontal={4} wrap>
            <VStack grow={1}>
              <AddButton
                icon='add-circle'
                onPress={() => navigation.navigate('MenuCategoryCreate', { restaurantId, menuPageId })}
                title='Lägg till underkategori'
              />
            </VStack>

            <VStack grow={1}>
              <SortButton
                onPress={handleSort}
                title='Sortera underkategorier'
              />
            </VStack>
          </HStack>
        )}

        {data?.restaurant?.menu?.page?.categories?.map(category => (
          <CategoryView
            key={category.id}
            category={category}
            handleMultiEdit={setMultiEditData}
            hasMenuWriteAccess={data?.restaurant?.hasMenuWriteAccess ?? false}
            onDragEnd={handleDragEnd}
          />
        ))}
      </VStack>
    </Layout>
  )
}

export default MenuPageView
