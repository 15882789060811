import { FontAwesome5, MaterialIcons } from '@expo/vector-icons'
import gql from 'graphql-tag'
import { parsePhoneNumber } from 'libphonenumber-js'
import React from 'react'
import { Image, TouchableOpacity } from 'react-native'
import { HStack, Text } from 'react-stacked'

import { useGetWhiteLabelDashboardDataQuery } from '../../types/graphql'
import Layout, { ScreenType } from '../components/Layout'
import { ACCENT_COLOR } from '../lib/color'
import openLink from '../util/openLink'
import showAlert from '../util/showAlert'
import useNavigation from '../util/useNavigation'

import DashboardContainer from './DashboardContainer'
import { SUPPORT_EMAIL_ADRESS, SUPPORT_PHONE_NUMBER, handleSupportEmailPress, handleSupportPhonePress } from './RestaurantDashboard'

gql`
  query GetWhiteLabelDashboardData($whiteLabelId: ID!) {
    whiteLabel(id: $whiteLabelId) {
      id

      androidPackageName
      appIconUrl
      appName
      label
      iosAppstoreId
    }
  }
`

const WhiteLabelDashboard: React.FC = () => {
  const [, { whiteLabelId }] = useNavigation<'WhiteLabelDashboard'>()

  const { data, loading } = useGetWhiteLabelDashboardDataQuery({ variables: { whiteLabelId } })

  const appName = data?.whiteLabel?.appName ?? data?.whiteLabel?.label ?? ''

  const handleAndroidBadgePress = (): void => {
    if (data?.whiteLabel?.androidPackageName == null) {
      showAlert('App i Google Play Store saknas', 'Det finns ingen app i Google Play Store för denna app, vilket kan betyda att den blivit nekad av Google eller att den inte har publicerats ännu. Kontakta service@gastrogate.io för en uppdatering.')

      return
    }

    openLink(`http://play.google.com/store/apps/details?id=${data.whiteLabel.androidPackageName}`)
  }

  const handleIosBadgePress = (): void => {
    if (data?.whiteLabel?.iosAppstoreId == null) {
      showAlert('App i App Store saknas', 'Det finns ingen app i App Store för denna app, vilket kan betyda att den blivit nekad av Apple eller att den inte har publicerats ännu. Kontakta service@gastrogate.io för en uppdatering.')

      return
    }

    openLink(`https://apps.apple.com/se/app/id${data.whiteLabel.iosAppstoreId}`)
  }

  return (
    <Layout
      hideTitle
      loading={loading}
      screenType={ScreenType.Dashboard}
      title='Dashboard'
    >
      <DashboardContainer gap={16}>
        <HStack alignItems='center' gap={12}>
          {data?.whiteLabel?.appIconUrl == null
            ? null
            : <Image source={{ uri: data.whiteLabel.appIconUrl }} style={{ width: 60, height: 60, borderRadius: 12 }} />}

          <Text size={24}>{appName} (app)</Text>
        </HStack>

        <HStack alignItems='center' gap={12}>
          {/* Get it on Google Play */}
          <TouchableOpacity onPress={handleAndroidBadgePress}>
            <Image
              source={{ uri: 'https://locousercontent.com/F0SRjNc-uz3uav_y1pcWlUws4zn5COjNCeii4IiGXXq-_YSnZkD7gJbWsx41IZos/original.svg' }}
              style={{
                aspectRatio: 646 / 250,
                height: 'auto',
                width: 150
              }}
            />
          </TouchableOpacity>

          {/* Download on the App Store */}
          <TouchableOpacity onPress={handleIosBadgePress}>
            <Image
              source={{ uri: 'https://locousercontent.com/smNbuGjnaAnUJucKIVCjX3Ek-nx_xitoK0zBXU_apsuq0AHQYlW6T3-q16wZ0Jyr/original.svg' }}
              style={{
                aspectRatio: 135 / 40,
                height: 'auto',
                width: 150
              }}
            />
          </TouchableOpacity>
        </HStack>

        <TouchableOpacity onPress={() => openLink('https://help.gastrogate.io/')}>
          <HStack>
            <FontAwesome5 color={ACCENT_COLOR} name='question' style={{ paddingRight: 15 }} />
            <Text alignSelf='center' size={12}>Länk till Helpcenter</Text>
          </HStack>
        </TouchableOpacity>

        <TouchableOpacity onPress={() => openLink('https://gastrogate.io/')}>
          <HStack>
            <FontAwesome5 color={ACCENT_COLOR} name='file-alt' style={{ paddingRight: 15 }} />
            <Text alignSelf='center' size={12}>Länk till gastrogate.io</Text>
          </HStack>
        </TouchableOpacity>

        <TouchableOpacity onPress={handleSupportPhonePress}>
          <HStack>
            <MaterialIcons color={ACCENT_COLOR} name='phone' style={{ paddingRight: 10 }} />
            <Text alignSelf='center' size={12}>{parsePhoneNumber(SUPPORT_PHONE_NUMBER).format('NATIONAL')}</Text>
          </HStack>
        </TouchableOpacity>

        <TouchableOpacity onPress={handleSupportEmailPress}>
          <HStack>
            <MaterialIcons color={ACCENT_COLOR} name='email' style={{ paddingRight: 10 }} />
            <Text alignSelf='center' size={12}>{SUPPORT_EMAIL_ADRESS}</Text>
          </HStack>
        </TouchableOpacity>
      </DashboardContainer>
    </Layout>
  )
}

export default WhiteLabelDashboard
