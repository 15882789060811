import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons'
import React from 'react'
import { TouchableOpacity } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'
import unreachable from 'ts-unreachable'

import { FixedDeliveryTime, type FullPaymentMethodFragment, type FullTillFragment, MenuTypeFilter, SendToKitchen, TillLayout } from '../../types/graphql'
import { ACCENT_COLOR } from '../lib/color'
import formatPaymentMethodTitle from '../util/formatPaymentMethodTitle'

import ListItemWrapper from './ListItemWrapper'

const fixedDeliveryTimeTitle = (fixedDeliveryTime: FixedDeliveryTime): string => {
  switch (fixedDeliveryTime) {
    case FixedDeliveryTime.Asap:
      return 'Snarast'
    case FixedDeliveryTime.Any:
      return 'Valbar'
    default:
      unreachable(fixedDeliveryTime)
  }
}

const notificationTitle = (handoffChoice: SendToKitchen): string => {
  switch (handoffChoice) {
    case SendToKitchen.Always:
      return 'Alltid på'
    case SendToKitchen.Ask:
      return 'Valbar'
    case SendToKitchen.Never:
      return 'Avstängd'
    default:
      unreachable(handoffChoice)
  }
}

const menuTypeName = (menuTypeFilter: MenuTypeFilter): string => {
  switch (menuTypeFilter) {
    case MenuTypeFilter.All:
      return 'Valbar'
    case MenuTypeFilter.EatIn:
      return 'Äta här'
    case MenuTypeFilter.Delivery:
      return 'Leverans'
    case MenuTypeFilter.TakeAway:
      return 'Ta med'
    case MenuTypeFilter.GiftCard:
      return 'Presentkort'
    default:
      unreachable(menuTypeFilter)
  }
}

const tillLayoutName = (tillLayout: TillLayout | null | undefined): string => {
  if (tillLayout == null) return ''

  switch (tillLayout) {
    case TillLayout.List:
      return 'Lista'
    case TillLayout.Modern:
      return 'Modern'
    case TillLayout.Retro:
      return 'Retro'
    default:
      unreachable(tillLayout)
  }
}

const tillLayoutIcon = (tillLayout: TillLayout | null | undefined): JSX.Element | null => {
  if (tillLayout == null) return null

  switch (tillLayout) {
    case TillLayout.List:
      return <MaterialCommunityIcons color='green' name='format-list-text' size={24} />
    case TillLayout.Modern:
      return <MaterialIcons color={ACCENT_COLOR} name='view-headline' size={24} />
    case TillLayout.Retro:
      return <MaterialIcons color='blue' name='view-comfy' size={24} />
    default:
      unreachable(tillLayout)
  }
}

const ViewSummary: React.FC<{ default?: string, title: string, visible: boolean | null | undefined }> = ({ default: _default, title, visible }) => {
  return (
    <HStack alignItems='center'>
      <Text>{title}{_default == null ? '' : ` (${_default})`}</Text>

      <Spacer width={8} />

      <MaterialIcons color={(visible ?? false) ? 'green' : 'red'} name={(visible ?? false) ? 'done' : 'clear'} size={14} />
    </HStack>
  )
}

interface RestaurantTillListItemProps {
  onEditPress: () => void
  hasSkipSlipPrintOnPrinterQueuesAccess: boolean
  item: FullTillFragment
  supportedPaymentMethods: FullPaymentMethodFragment[]
}

const RestaurantTillListItem: React.FC<RestaurantTillListItemProps> = ({ onEditPress, hasSkipSlipPrintOnPrinterQueuesAccess, item, supportedPaymentMethods }) => {
  const tillPaymentMethods = item?.paymentMethods

  return (
    <TouchableOpacity onPress={onEditPress}>
      <ListItemWrapper>
        <VStack grow={1}>
          <Text size={16}>{item.name}</Text>

          <Spacer grow={1} height={8} />

          <Text>Betalsätt:</Text>

          <Spacer height={8} />

          <VStack alignItems='start'>
            {supportedPaymentMethods.map(paymentMethod => (
              <ViewSummary
                key={paymentMethod.id}
                title={formatPaymentMethodTitle(paymentMethod)}
                visible={tillPaymentMethods?.some(({ id }) => id === paymentMethod.id) ?? false}
              />
            ))}
          </VStack>
        </VStack>

        <Spacer width={8} />

        <VStack alignItems='end' grow={1}>
          <HStack alignItems='center'>
            <Text>Layout - {tillLayoutName(item.layout)}</Text>

            <Spacer width={8} />

            {tillLayoutIcon(item.layout)}
          </HStack>

          <ViewSummary
            title='Kategorier'
            visible={item.showsCategoryBar}
          />

          <ViewSummary
            default={item.receiptPrinterQueue?.name == null ? 'Ej vald' : item.receiptPrinterQueue?.name}
            title='Kvittoskrivare'
            visible={item.receiptPrinterQueue != null}
          />

          <ViewSummary
            title='Logotyp'
            visible={item.showsLogo}
          />

          <ViewSummary
            title='Dricks'
            visible={item.showsTip ?? true}
          />

          <ViewSummary
            default={item.showsMenuType == null ? undefined : menuTypeName(item.showsMenuType)}
            title='Menytyp'
            visible={item.showsMenuType === MenuTypeFilter.All}
          />

          {item.preferredApiEnvironment == null
            ? null
            : (
              <ViewSummary
                default={item.preferredApiEnvironment == null ? undefined : item.preferredApiEnvironment}
                title='API-miljö'
                visible
              />
            )}

          <ViewSummary
            default={item.fixedDeliveryLocation?.name == null ? 'Valbar' : item.fixedDeliveryLocation?.name}
            title='Plats'
            visible={item.fixedDeliveryLocation == null}
          />

          <ViewSummary
            default={notificationTitle(item.sendOrderToKitchen ?? SendToKitchen.Ask)}
            title='Notifikation till kök'
            visible={item.sendOrderToKitchen !== SendToKitchen.Never}
          />

          <ViewSummary
            default={hasSkipSlipPrintOnPrinterQueuesAccess ? 'Valbar' : 'Ej valbar, premiumfunktion'}
            title='Ignorerade bongskrivare'
            visible={(item.skipSlipPrintOnPrinterQueues?.length ?? 0) > 0}
          />

          <ViewSummary
            title='Rabatter'
            visible={item.showsDiscountField}
          />

          <ViewSummary
            title='Sök'
            visible={item.showsSearch}
          />

          <ViewSummary
            default={fixedDeliveryTimeTitle(item.fixedDeliveryTime ?? FixedDeliveryTime.Any)}
            title='Tid'
            visible={item.fixedDeliveryTime == null || item.fixedDeliveryTime === FixedDeliveryTime.Any}
          />

          <ViewSummary
            title='Töm kundvagn'
            visible={item.showsEmptyShoppingCartButton}
          />

          <ViewSummary
            title='Öppettider'
            visible={item.showsOpeningStatus}
          />

          <ViewSummary
            title='Öppna notor'
            visible={item.showsOpenOrders}
          />
        </VStack>

        <Spacer width={16} />

        <MaterialIcons name='edit' size={24} />
      </ListItemWrapper>
    </TouchableOpacity>
  )
}

export default RestaurantTillListItem
