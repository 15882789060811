import { MaterialIcons } from '@expo/vector-icons'
import dotProp from 'dot-prop-immutable'
import gql from 'graphql-tag'
import React from 'react'
import { TouchableOpacity } from 'react-native'
import { HStack, Text } from 'react-stacked'

import { type FullMenuCourseFragment, GetMenuCourseListDocument, useGetMenuCourseListQuery, useRearrangeMenuCoursesMutation } from '../../types/graphql'
import { AddButton } from '../components/Buttons'
import DraggableList from '../components/DraggableList'
import Layout, { ScreenType } from '../components/Layout'
import ListContainer from '../components/ListContainer'
import logError from '../util/logError'
import useNavigation from '../util/useNavigation'

gql`
  query GetMenuCourseList($restaurantId: ID!){
    restaurant(id: $restaurantId) {
      id

      menu {
        courses {
          id

          name
        }
      }
    }
  }

  mutation RearrangeMenuCourses($restaurantId: ID!, $order: [ID!]!) {
    rearrangeMenuCourses(
      restaurantId: $restaurantId
      order: $order
    )
  }
`

const MenuCourseList: React.FC = () => {
  const [navigation, { restaurantId }] = useNavigation<'MenuCourseList'>()
  const { data, loading } = useGetMenuCourseListQuery({ variables: { restaurantId } })
  const [rearrangeMenuCourses] = useRearrangeMenuCoursesMutation({ awaitRefetchQueries: true })

  const handleDragEnd = (rearrangedMenuCourses: FullMenuCourseFragment[]): void => {
    rearrangeMenuCourses({
      optimisticResponse: { rearrangeMenuCourses: null },
      refetchQueries: [{ query: GetMenuCourseListDocument, variables: { restaurantId } }],
      update: (proxy) => {
        const newData = dotProp.set(data, 'restaurant.menu.courses', rearrangedMenuCourses)

        proxy.writeQuery({ query: GetMenuCourseListDocument, variables: { restaurantId }, data: newData })
      },
      variables: { restaurantId, order: rearrangedMenuCourses.map(course => course.id) }
    }).catch(logError)
  }

  return (
    <Layout hideTitle loading={loading} screenType={ScreenType.List} title='Rätter'>
      <ListContainer title='Rätter'>
        <AddButton
          onPress={() => navigation.navigate('MenuCourseCreate', { restaurantId })}
          title='Skapa ny rätt'
        />

        <DraggableList
          data={data?.restaurant?.menu?.courses ?? []}
          keyExtractor={course => course.id}
          onDragEnd={handleDragEnd}
          renderItem={(dragHandle, course) => (
            <HStack alignItems='center' paddingHorizontal={8} paddingVertical={12}>
              {dragHandle}

              <TouchableOpacity onPress={() => navigation.navigate('MenuCourseEdit', { restaurantId, menuCourseId: course.id })} style={{ alignItems: 'center', flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                <Text backgroundColor='#fefefe' borderRadius={5} padding={16} size={20}>{course.name}</Text>

                <MaterialIcons name='edit' size={16} />
              </TouchableOpacity>
            </HStack>
          )}
        />
      </ListContainer>
    </Layout>
  )
}

export default MenuCourseList
