import React, { type ReactNode, useState } from 'react'
import { type FlexAlignType, TouchableOpacity, type TouchableOpacityProps } from 'react-native'
import { HStack, VStack } from 'react-stacked'

function alignItems (input: 'baseline' | 'center' | 'end' | 'start' | 'stretch' | null | undefined): FlexAlignType | undefined {
  if (input == null) return undefined
  if (input === 'start') return 'flex-start'
  if (input === 'end') return 'flex-end'

  return input
}

const MaybeTouchable: React.FC<{ children: ReactNode, onPress: TouchableOpacityProps['onPress'] }> = ({ children, onPress }) => {
  if (onPress == null) return <>{children}</>

  return <TouchableOpacity onPress={onPress}>{children}</TouchableOpacity>
}

export const rowColor = (index: number): string => ['#fff', '#fec'][index % 2]
const kBackgroundColor: unique symbol = '_backgroundColor' as any
const kCollapseBorder: unique symbol = '_collapseBorder' as any
const kColumns: unique symbol = '_columns' as any

interface ColumnProps {
  align?: 'baseline' | 'center' | 'end' | 'start' | 'stretch'
  flexGrow?: number
  hidden?: boolean
  paddingHorizontal?: number
  paddingLeft?: number
  paddingRight?: number
  width?: number
}

export const Column: React.FC<ColumnProps> = () => null

interface CellProps extends ColumnProps {
  children?: ReactNode
  onPress?: TouchableOpacityProps['onPress']
  borderColor?: string
  borderWidth?: number
  paddingTop?: number
}

export const Cell: React.FC<CellProps> = (props) => {
  if (props.hidden ?? false) return null

  return (props.onPress == null)
    ? (
      <VStack
        alignItems={props.align}
        basis={props.width != null ? undefined : 1}
        borderColor={props.borderColor}
        borderWidth={props.borderWidth}
        grow={props.flexGrow ?? (props.width == null ? 1 : 0)}
        paddingHorizontal={props.paddingHorizontal}
        paddingLeft={props.paddingLeft}
        paddingRight={props.paddingRight}
        paddingTop={props.paddingTop}
        shrink={0}
        width={props.width}
      >
        {props.children}
      </VStack>
    )
    : (
      <TouchableOpacity
        onPress={props.onPress}
        style={{
          alignItems: alignItems(props.align),
          flexBasis: props.width != null ? undefined : 1,
          flexGrow: props.flexGrow ?? (props.width == null ? 1 : 0),
          paddingHorizontal: props.paddingHorizontal,
          paddingLeft: props.paddingLeft,
          paddingRight: props.paddingRight,
          flexShrink: 0,
          width: props.width
        }}
      >
        {props.children}
      </TouchableOpacity>
    )
}

interface RowProps {
  [kBackgroundColor]?: string
  [kCollapseBorder]?: boolean
  [kColumns]?: ColumnProps[]
  align?: 'baseline' | 'center' | 'end' | 'start' | 'stretch'
  backgroundColor?: string
  children: ReactNode
  paddingBottom?: number
  paddingTop?: number
  paddingVertical?: number
  onPress?: TouchableOpacityProps['onPress']
  borderStyle?: 'solid' | 'dotted' | 'dashed' | undefined
  borderWidth?: number
  borderColor?: string
}

export const Row: React.FC<RowProps> = (props) => (
  <MaybeTouchable onPress={props.onPress}>
    <HStack
      alignItems={props.align}
      backgroundColor={props.backgroundColor ?? props[kBackgroundColor]}
      borderBottomWidth={(props[kCollapseBorder] ?? false) ? 0 : undefined}
      borderColor={props.borderColor}
      borderStyle={props.borderStyle}
      borderWidth={props.borderWidth}
      paddingBottom={props.paddingBottom}
      paddingTop={props.paddingTop}
      paddingVertical={props.paddingVertical}
    >
      {React.Children.map(props.children, (child, index) => {
        if (child == null) return null

        // @ts-expect-error There is some type incompatibility between React.Children.map & React.cloneElement
        return React.cloneElement(child, { ...props[kColumns]?.[index], ...child.props })
      })}
    </HStack>
  </MaybeTouchable>
)

interface RowGroupProps {
  [kBackgroundColor]?: string
  [kColumns]?: ColumnProps[]
  children: ReactNode
}

export const RowGroup: React.FC<RowGroupProps> = (props) => {
  return (
    <>
      {React.Children.map(props.children, (child) => {
        if (child == null) return null

        if (typeof child !== 'object') {
          throw new Error('RowGroup can only contain Row')
        }

        // @ts-expect-error There is some type incompatibility between React.Children.map & React.cloneElement
        return React.cloneElement(child, { ...props, ...child.props })
      })}
    </>
  )
}

interface ExpandableRowProps extends Omit<RowProps, 'onPress'> {
  dropdown: () => JSX.Element
}

export const ExpandableRow: React.FC<ExpandableRowProps> = (props) => {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <VStack>
      <TouchableOpacity onPress={() => setIsExpanded(!isExpanded)}>
        <HStack
          alignItems={props.align}
          backgroundColor={props.backgroundColor ?? props[kBackgroundColor]}
          paddingBottom={props.paddingBottom}
          paddingTop={props.paddingTop}
          paddingVertical={props.paddingVertical}
        >
          {React.Children.map(props.children, (child, index) => {
            if (child == null) return null

            // @ts-expect-error There is some type incompatibility between React.Children.map & React.cloneElement
            return React.cloneElement(child, { ...props[kColumns]?.[index], ...child.props })
          })}
        </HStack>
      </TouchableOpacity>

      {!isExpanded
        ? null
        : props.dropdown?.()}
    </VStack>
  )
}

interface TableProps {
  borderRadius?: number
  children: ReactNode
}

export const Table: React.FC<TableProps> = ({ borderRadius, children }) => {
  const columns: ColumnProps[] = []
  const childrenCount = React.Children.count(children)

  return (
    <VStack borderRadius={borderRadius}>
      {React.Children.map(children, (child, index) => {
        if (child == null) return

        if (typeof child !== 'object') {
          throw new Error('Table can only contain Column & Row')
        }

        if ('type' in child && child.type === Column) {
          columns.push(child.props)
          return
        }

        // @ts-expect-error There is some type incompatibility between React.Children.map & React.cloneElement
        return React.cloneElement(child, {
          [kBackgroundColor]: rowColor(index),
          [kCollapseBorder]: index < childrenCount - 1,
          [kColumns]: columns
        })
      })}
    </VStack>
  )
}
