import { MaterialIcons } from '@expo/vector-icons'
import gql from 'graphql-tag'
import React, { useState } from 'react'
import { ActivityIndicator, TouchableOpacity } from 'react-native'
import { HStack, Text } from 'react-stacked'
import unreachable from 'ts-unreachable'

import { MenuType, useSetMenuTypeTemporarilyDisabledMutation } from '../../../types/graphql'
import ignoreAsync from '../../util/ignoreAsync'
import { ACTIVE_BUTTON_COLOR, INACTIVE_BUTTON_COLOR } from '../PanicButton'
import VerificationDialog from '../VerificationDialog'

gql`
  mutation SetMenuTypeTemporarilyDisabled($disabled: Boolean!, $menuType: MenuType!, $restaurantId: ID!) {
    setMenuTypeTemporarilyDisabled(
      disabled: $disabled
      menuType: $menuType
      restaurantId: $restaurantId
    )
  }
`

function fieldNameFromMenuType (menuType: MenuType): 'deliveryTemporarilyDisabled' | 'eatInTemporarilyDisabled' | 'takeAwayTemporarilyDisabled' | 'giftCardTemporarilyDisabled' {
  switch (menuType) {
    case MenuType.Delivery:
      return 'deliveryTemporarilyDisabled'
    case MenuType.EatIn:
      return 'eatInTemporarilyDisabled'
    case MenuType.TakeAway:
      return 'takeAwayTemporarilyDisabled'
    case MenuType.GiftCard:
      return 'giftCardTemporarilyDisabled'
    default:
      unreachable(menuType)
  }
}

export function titleFromMenuType (menuType: MenuType): 'Leverans' | 'Äta här' | 'Ta med' | 'Presentkort' {
  switch (menuType) {
    case MenuType.Delivery:
      return 'Leverans'
    case MenuType.EatIn:
      return 'Äta här'
    case MenuType.TakeAway:
      return 'Ta med'
    case MenuType.GiftCard:
      return 'Presentkort'
    default:
      unreachable(menuType)
  }
}

interface MenuTypeToggleButtonProps {
  disabled: boolean
  menuType: MenuType
  restaurantId: string
}

const MenuTypeToggleButton: React.FC<MenuTypeToggleButtonProps> = ({ disabled, menuType, restaurantId }) => {
  const [setMenuTypeTemporarilyDisable, { loading: isSubmitting }] = useSetMenuTypeTemporarilyDisabledMutation()

  const [showTemporarilyDisabledConfirmation, setShowTemporarilyDisabledConfirmation] = useState(false)

  const fieldName = fieldNameFromMenuType(menuType)

  const handleTemporarilyDisable = ignoreAsync(async () => {
    const newValue = !disabled

    await setMenuTypeTemporarilyDisable({
      variables: {
        disabled: newValue,
        menuType,
        restaurantId
      },
      update (cache) {
        cache.modify({
          id: cache.identify({ __typename: 'Restaurant', id: restaurantId }),
          fields: { [fieldName]: () => newValue }
        })
      }
    })
  })

  return (
    <>
      <TouchableOpacity
        onPress={() => {
          if (disabled) {
            handleTemporarilyDisable()
          } else {
            setShowTemporarilyDisabledConfirmation(true)
          }
        }}
        style={{ flex: 1 }}
      >
        <HStack alignItems='center' backgroundColor={disabled ? INACTIVE_BUTTON_COLOR : ACTIVE_BUTTON_COLOR} borderRadius={2} gap={12} justifyContent='center' paddingHorizontal={24} paddingVertical={8}>
          {isSubmitting ? (
            <ActivityIndicator
              color='white'
              size='small'
            />
          ) : (
            <>
              <MaterialIcons color='#fff' name='power-settings-new' size={16} />
              <Text color='#fff' size={16} transform='uppercase'>{titleFromMenuType(menuType)}</Text>
            </>
          )}
        </HStack>
      </TouchableOpacity>
      {!showTemporarilyDisabledConfirmation ? null : (
        <VerificationDialog
          callToActionLabel='Bekräfta'
          errorMessage={undefined}
          loading={isSubmitting}
          onDismiss={() => {
            setShowTemporarilyDisabledConfirmation(false)
          }}
          onSubmit={() => {
            setShowTemporarilyDisabledConfirmation(false)
            handleTemporarilyDisable()
          }}
          open
          prompt={`Är du säker på att du vill inaktivera ${titleFromMenuType(menuType)}? Om du avaktiverar det kommer dina gäster inte att kunna beställa från ${titleFromMenuType(menuType)} i vår beställningsapp, men du kommer fortfarande att vara synlig.`}
          title={`Inaktivera ${titleFromMenuType(menuType)}`}
        />
      )}
    </>
  )
}

export default MenuTypeToggleButton
