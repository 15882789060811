import { FontAwesome5, MaterialIcons } from '@expo/vector-icons'
import QRCode from '@qrcode/react-native'
import gql from 'graphql-tag'
import { parsePhoneNumber } from 'libphonenumber-js'
import React from 'react'
import { Image, Linking, TouchableOpacity } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'

import { useGetRestaurantDashboardDataQuery } from '../../types/graphql'
import BusinessBoosterItem from '../components/BusinessBoosterItem'
import Divider from '../components/Divider'
import Layout, { ScreenType } from '../components/Layout'
import { ACCENT_COLOR } from '../lib/color'
import formatCurrency from '../util/formatCurrency'
import logError from '../util/logError'
import openLink from '../util/openLink'
import useNavigation from '../util/useNavigation'

import DashboardContainer from './DashboardContainer'

gql`
  query GetRestaurantDashboardData($restaurantId: ID!) {
    restaurant(id: $restaurantId) {
      id

      hasDeliveryHeroAccess
      hasOrderDisplaySystemAccess
      hasPersonalkollenAccess
      isOwner: hasAccess(access:Write)
    }
  }
`

export const SUPPORT_EMAIL_ADRESS = 'service@gastrogate.io'
export const SUPPORT_PHONE_NUMBER = '+46213609550'

export const handleSupportEmailPress = (): void => {
  Linking.openURL(`mailto:${SUPPORT_EMAIL_ADRESS}`).catch(logError)
}

export const handleSupportPhonePress = (): void => {
  Linking.openURL(`tel:${SUPPORT_PHONE_NUMBER}`).catch(logError)
}

const RestaurantDashboard: React.FC = () => {
  const [navigation, { restaurantId }] = useNavigation<'RestaurantDashboard'>()

  const { data, loading } = useGetRestaurantDashboardDataQuery({ variables: { restaurantId } })

  const isOwner = data?.restaurant?.isOwner ?? false

  const hasDeliveryHeroAccess = data?.restaurant?.hasDeliveryHeroAccess ?? false
  const hasOrderDisplaySystemAccess = data?.restaurant?.hasOrderDisplaySystemAccess ?? false
  const hasPersonalkollenAccess = data?.restaurant?.hasPersonalkollenAccess ?? false

  if (loading) {
    return <Layout loading title='Dashboard' />
  }

  return (
    <Layout screenType={ScreenType.Dashboard} title='Dashboard'>
      <DashboardContainer gap={12}>
        <Text size={24}>Integrationer och tillval</Text>

        <Text size={12}>Här kan du lägga till integrationer och tillval som passar dig och din verksamhet. Du kan lägga till och ta bort integrationer och tillval när du vill.</Text>

        <BusinessBoosterItem
          description='Öka kommunikationen med dina gäster med hjälp av vår smidiga orderskärm. Optimalt för expresskassa och QR-beställningar. Gästerna kan enkelt följa med i orderprocessen och se när deras beställning är redo att hämtas.'
          iconUrl='https://locousercontent.com/zYrBWceZFhCBZ3eJzKXXJFmIK5DVfKoXgxe1GtyWF_clJhn8bUpvP0P5hjuIGELi/original.svg'
          isActive={hasOrderDisplaySystemAccess}
          onActivationPress={() => !isOwner ? null : navigation.navigate('RestaurantOrderDisplaySystem', { restaurantId })}
          price={`${formatCurrency(34900)}/mån`}
          readMoreUrl='https://eu1.hubs.ly/H06rRhJ0'
          title='Orderskärm'
        />

        <BusinessBoosterItem
          description='Genom att koppla ihop Loco med Personalkollen får du försäljning och dricks inläst löpande. Följ din försäljning dag för dag, timme för timme och se hur den står sig mot personalkostnaden för att kunna optimera personalplaneringen.'
          iconUrl='https://locousercontent.com/6KL8hgwgczVq9fDUeVJMagMnkOMDGSz5x6NSFcX4pvnbnvHhA8jLrsfEd-S_zKVz/original.png'
          isActive={hasPersonalkollenAccess}
          onActivationPress={() => !isOwner ? null : navigation.navigate('RestaurantPersonalkollenView', { restaurantId })}
          price={`${formatCurrency(9900)}/mån`}
          readMoreUrl='https://eu1.hubs.ly/H06rRcF0'
          title='Personalkollen'
        />

        <BusinessBoosterItem
          description='Knyt samman Foodora med Loco för en enklare vardag. I köksskärmen LocoPOS hanterar ni inkommande beställningar, menyn synkas smidigt, och allt sparas i statistiken.'
          iconUrl='https://locousercontent.com/s_JC1hp6QlIKWBfgWDf_HvL5kQh5RvYPAofRdMXNiXt68JhVV76GxiySGwj-5UJ2/original.png'
          isActive={hasDeliveryHeroAccess}
          onActivationPress={() => !isOwner ? null : navigation.navigate('DeliveryDeliveryHeroEdit', { restaurantId })}
          price={`${formatCurrency(9900)}/mån + 1,5% serviceavgift per beställning`}
          readMoreUrl='https://eu1.hubs.ly/H06rRcM0'
          title='Foodora'
        />
      </DashboardContainer>

      <DashboardContainer gap={16}>
        <Text size={24}>Klar</Text>

        <Text size={12}>Vi är i mål, bra jobbat! Du är nu online och synlig för dina kunder. Om du har några frågor eller funderingar, tveka inte att höra av dig till oss så hjälper vi dig gärna.</Text>

        <TouchableOpacity onPress={() => openLink('https://help.gastrogate.io/')}>
          <HStack>
            <FontAwesome5 color={ACCENT_COLOR} name='question' style={{ paddingRight: 15 }} />
            <Text alignSelf='center' size={12}>Länk till Helpcenter</Text>
          </HStack>
        </TouchableOpacity>

        <TouchableOpacity onPress={() => openLink('https://gastrogate.io/')}>
          <HStack>
            <FontAwesome5 color={ACCENT_COLOR} name='file-alt' style={{ paddingRight: 15 }} />
            <Text alignSelf='center' size={12}>Länk till gastrogate.io</Text>
          </HStack>
        </TouchableOpacity>

        <TouchableOpacity onPress={handleSupportPhonePress}>
          <HStack>
            <MaterialIcons color={ACCENT_COLOR} name='phone' style={{ paddingRight: 10 }} />
            <Text alignSelf='center' size={12}>{parsePhoneNumber(SUPPORT_PHONE_NUMBER).format('NATIONAL')}</Text>
          </HStack>
        </TouchableOpacity>

        <TouchableOpacity onPress={handleSupportEmailPress}>
          <HStack>
            <MaterialIcons color={ACCENT_COLOR} name='email' style={{ paddingRight: 10 }} />
            <Text alignSelf='center' size={12}>{SUPPORT_EMAIL_ADRESS}</Text>
          </HStack>
        </TouchableOpacity>

        <Divider />

        <HStack gap={50} wrap>
          <VStack maxWidth={300}>
            <Text size={12} weight='500'>Tryck för att ladda ner LocoPos apparna.</Text>

            <Spacer height={24} />

            <TouchableOpacity onPress={() => openLink('https://apps.apple.com/se/app/locopos-order/id1539396976')}>
              <HStack alignItems='center'>
                <Image source={{ uri: 'https://locousercontent.com/OtJ_eCdGT7I2ps6RNgA_xCa0W01E1ltGJ7bJTq27qfjdGeeVaOrazfOErLX3iRf3/original.png' }} style={{ width: 60, height: 60, borderRadius: 12 }} />

                <VStack paddingLeft={14}>
                  <Text size={16}>LocoPOS Order</Text>
                  <Text paddingTop={5} size={12}>Ta betalt av dina kunder genom LocoPOS Order kassasystem och expresskassa</Text>
                </VStack>
              </HStack>
            </TouchableOpacity>

            <Spacer height={24} />

            <TouchableOpacity onPress={() => openLink('https://apps.apple.com/se/app/locopos/id1176103744')}>
              <HStack alignItems='center'>
                <Image source={{ uri: 'https://locousercontent.com/WRpmsUQ3KfGSFjS2WMWaMxy3RbuHbRuwjbfAl9c4VoKKM1q3b7l6mUYa4tzAF7UJ/original.png' }} style={{ width: 60, height: 60, borderRadius: 12 }} />

                <VStack paddingLeft={14}>
                  <Text size={16}>LocoPOS</Text>
                  <Text paddingTop={5} size={12}>Hanterar inkommande beställningar, lagerhantering, returer och bongutskrifter</Text>
                </VStack>
              </HStack>
            </TouchableOpacity>
          </VStack>

          <VStack>
            <Text size={12} weight='500'>Skanna för att ladda ner LocoPos apparna.</Text>
            <Spacer height={24} />
            <HStack gap={24}>
              <VStack>
                <Text paddingBottom={12}>LocoPOS Order</Text>
                <QRCode size={100} value='https://apps.apple.com/se/app/locopos-order/id1539396976' />
              </VStack>
              <VStack>
                <Text paddingBottom={12}>LocoPOS</Text>

                <QRCode size={100} value='https://apps.apple.com/se/app/locopos/id1176103744' />
              </VStack>
            </HStack>
          </VStack>
        </HStack>
      </DashboardContainer>
    </Layout>
  )
}

export default RestaurantDashboard
