import { Ionicons, MaterialIcons } from '@expo/vector-icons'
import { Temporal } from '@js-temporal/polyfill'
import gql from 'graphql-tag'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { ActivityIndicator, FlatList, Image, TouchableOpacity } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'
import WithSeparator from 'react-with-separator'
import sortOn from 'sort-on'
import unreachable from 'ts-unreachable'
import unwrap from 'ts-unwrap'
import key from 'weak-key'

import { FortnoxAccountingImportJobStatus, type GetSieReportQueryVariables, type SieReportFilter, type SieReportSourceFragment, type SieVerificationFragment, type SieVerificationSource, SieVerificationType, useGetSieReportQuery, useGetSieReportViewDataQuery, useMarkFortnoxAccountingImportAsManuallyImportedMutation, useRetryFortnoxAccountingImportMutation, useSetOrganizationAccountantEmailMutation } from '../../types/graphql'
import BusinessBoosterItem from '../components/BusinessBoosterItem'
import ButtonGroup from '../components/ButtonGroup'
import { PrimaryButton, SecondaryButton } from '../components/Buttons'
import DateRangeSelect from '../components/DateRangeSelect'
import Divider from '../components/Divider'
import FormContainer from '../components/FormContainer'
import Layout from '../components/Layout'
import { Cell, Column, Row, Table } from '../components/Table'
import { TextField } from '../components/TextField'
import VerificationDialog from '../components/VerificationDialog'
import Warning from '../components/Warning'
import { CheckBox } from '../components/fields'
import { INPUT_BORDER_COLOR } from '../lib/color'
import type TimePeriod from '../util/TimePeriod'
import dateRangeFromTimePeriod, { type PlainDateRange } from '../util/dateRangeFromTimePeriod'
import formatCurrency from '../util/formatCurrency'
import formatDateTime from '../util/formatDateTime'
import ignoreAsync from '../util/ignoreAsync'
import logError from '../util/logError'
import { normalize } from '../util/normalize'
import openLink from '../util/openLink'
import serializeZonedDateTimeRange from '../util/serializeZonedDateTimeRange'
import showAlert from '../util/showAlert'
import useNavigation from '../util/useNavigation'

gql`
  fragment SieReportSource on SIEVerificationSource {
    __typename

    ... on PhysicalControlUnitOutage {
      id

      createdAt
      restoredAt
    }

    ... on StripeReport {
      id
    }

    ... on ZReport {
      id

      serialNumber
    }
  }

  fragment LogEntries on FortnoxAccountingImportJobLogEntry {
    id

    message
    timestamp
  }

  fragment SieVerification on SIEVerification {
    id

    date
    description
    validationError

    fortnoxAccountingImportJob {
      id

      status

      logEntries {
        ...LogEntries
      }
    }

    restaurants {
      id

      name

      cashregister {
        id

        name
      }
    }

    source {
      ...SieReportSource
    }

    transactions {
      accountNumber
      amount
    }
  }

  query GetSIEReport($organizationId: ID!, $datePeriod: DateTimeRangeInput!, $filter: SieReportFilter) {
    organization (id: $organizationId) {
      id

      name

      sieAccounts {
        id

        defaultDescription
        defaultNumber
        description
        key
        number
      }

      sieReport(datePeriod: $datePeriod, filter: $filter) {
        id

        sieFileData

        datePeriod {
          start
          end
        }

        verifications {
          ...SieVerification
        }
      }
    }
  }

  query GetSieReportViewData($organizationId: ID!) {
    organization (id: $organizationId) {
      id

      accountantEmail
      fortnoxAccountingImportEnabled
      hasFortnoxAccountingImportAccess
      isOwner: hasAccess(access:Write)

      fortnoxAccountingImportFailedVerifications {
        ...SieVerification
      }

      restaurants {
        id

        name
      }
    }
  }

  mutation MarkFortnoxAccountingImportAsManuallyImported($organizationId: ID!, $sieVerificationId: ID!) {
    markFortnoxAccountingImportAsManuallyImported(
      organizationId: $organizationId
      sieVerificationId: $sieVerificationId
    ) {
      id

      status

      logEntries {
        ...LogEntries
      }
    }
  }

  mutation RetryFortnoxAccountingImport($organizationId: ID!, $sieVerificationId: ID!) {
    retryFortnoxAccountingImport(
      organizationId: $organizationId
      sieVerificationId: $sieVerificationId
    ) {
      id

      status

      logEntries {
        ...LogEntries
      }
    }
  }

  mutation SetOrganizationAccountantEmail($organizationId: ID!, $email: EmailAddress!) {
    setOrganizationAccountantEmail(
      organizationId: $organizationId
      email: $email
    ) {
      id

      accountantEmail
    }
  }
`

function fortnoxAccountingImportJobStatusToLabel (status: FortnoxAccountingImportJobStatus | null | undefined): string {
  switch (status) {
    case FortnoxAccountingImportJobStatus.Failed:
      return 'Överföring misslyckades'
    case FortnoxAccountingImportJobStatus.Pending:
      return 'Överföring pågår'
    case FortnoxAccountingImportJobStatus.Success:
      return 'Överföring klar'
    default:
      return 'Okänd'
  }
}

function money (value: number | null | undefined): string {
  return value == null ? '' : formatCurrency(value)
}

const VerificationIcon: React.FC<{ type: SieVerificationSource['__typename'] | undefined }> = ({ type }) => {
  let color, icon
  switch (type) {
    case 'PhysicalControlUnitOutage':
      color = 'lightgray'
      icon = 'power-off' as const
      break
    case 'StripeReport':
      color = 'lightgray'
      icon = 'credit-card' as const
      break
    case 'ZReport':
      color = 'lightgray'
      icon = 'account-balance' as const
      break
    default:
      color = 'pink'
      icon = 'help' as const
      break
  }

  return <MaterialIcons color='white' name={icon} size={24} style={{ backgroundColor: color, borderRadius: 20, padding: 8 }} />
}

const getVerificationTitle = (verification: SieVerificationFragment): string => {
  switch (verification.source?.__typename) {
    case 'PhysicalControlUnitOutage':
      return `Driftstopp av kontrollenheten ${verification.description?.match(/kontrollenhet (\d+)/)?.[0] ?? ''}`
    case 'StripeReport':
      return verification.description?.match(/Stripe-rapport \S+/)?.[0] ?? 'Stripe-rapport'
    case 'ZReport':
      return `${verification.restaurants?.[0].name ?? ''}: ${verification.description?.match(/Z-rapport \d+/)?.[0] ?? ''}`
    default:
      return 'Verifikation'
  }
}

const getVerificationDescription = (verification: SieVerificationFragment): string => {
  switch (verification.source?.__typename) {
    case 'PhysicalControlUnitOutage': {
      const createdAt = verification.source.createdAt == null ? '' : formatDateTime(Temporal.Instant.from(verification.source.createdAt).toZonedDateTimeISO('Europe/Stockholm'), { dateStyle: 'medium', timeStyle: 'short' })
      const restoredAt = verification.source.restoredAt == null ? '' : formatDateTime(Temporal.Instant.from(verification.source.restoredAt).toZonedDateTimeISO('Europe/Stockholm'), { dateStyle: 'medium', timeStyle: 'short' })

      return `Driftstopp aktivt från ${createdAt} till ${restoredAt}\n${verification.date?.slice(0, 10) ?? ''}`
    }
    case 'StripeReport':
      return verification.date?.slice(0, 10) ?? ''
    case 'ZReport':
      return `${verification.date?.slice(0, 10) ?? ''}, ${verification?.source?.serialNumber ?? ''}`
    default:
      return 'Verifikation'
  }
}

const SieReportView: React.FC = () => {
  const [navigation, { organizationId }] = useNavigation<'SieReportView'>()

  const { data: sieReportViewData, loading: loadingSieReportViewData } = useGetSieReportViewDataQuery({ variables: { organizationId } })

  const timeZone = 'Europe/Stockholm'
  const initialTimePeriod: TimePeriod = { kind: 'LastMonth' }
  const initialDateRange = dateRangeFromTimePeriod(initialTimePeriod, Temporal.Now.instant(), timeZone)

  const [dateRange, setDateRange] = useState<PlainDateRange>(initialDateRange)
  const [filter, setFilter] = useState<SieReportFilter | null>(null)
  const [restaurantFilter, setRestaurantFilter] = useState<string[] | null>(null)
  const [retriesLoading, setRetriesLoading] = useState<string[]>([])
  const [selectedDateRange, setSelectedDateRange] = useState<PlainDateRange>(initialDateRange)
  const [showingMarkAsResolvedVerificationDialog, setShowingMarkAsResolvedVerificationDialog] = useState<SieVerificationFragment | null>(null)
  const [showFilter, setShowFilter] = useState(false)
  const [verificationTypeFilter, setVerificationTypeFilter] = useState<SieVerificationType[] | null>(null)

  const [setOrganizationAccountantEmail, { loading: loadingEmailSave }] = useSetOrganizationAccountantEmailMutation()
  const [markFortnoxAccountingImportAsManuallyImported, { error: markAsResolvedError, loading: markingAsResolved }] = useMarkFortnoxAccountingImportAsManuallyImportedMutation({
    onCompleted: () => setShowingMarkAsResolvedVerificationDialog(null)
  })
  const [retryFortnoxAccountingImport] = useRetryFortnoxAccountingImportMutation()

  const hasFortnoxAccountingImportAccess = sieReportViewData?.organization?.hasFortnoxAccountingImportAccess ?? false
  const hasOrganizationWriteAccess = sieReportViewData?.organization?.isOwner ?? false

  const dateTimeRangeLocal = useMemo(() => {
    return {
      start: dateRange.start.toZonedDateTime(timeZone),
      end: dateRange.end.add({ days: 1 }).toZonedDateTime(timeZone)
    }
  }, [dateRange, timeZone])

  const restaurantFilterOptions = sieReportViewData?.organization?.restaurants ?? []

  const variables = useMemo<GetSieReportQueryVariables>(() => {
    return {
      datePeriod: serializeZonedDateTimeRange(dateTimeRangeLocal),
      filter,
      organizationId
    }
  }, [dateTimeRangeLocal, filter, organizationId])

  const { data, loading, error } = useGetSieReportQuery({ variables })

  const reportName = useMemo(() => {
    if (data?.organization?.sieReport?.datePeriod?.start == null || data.organization.sieReport.datePeriod.end == null) {
      return 'SIE_RAPPORT'
    }

    const start = Temporal.Instant.from(data.organization.sieReport.datePeriod.start).toZonedDateTimeISO(timeZone).toPlainDate()
    const end = Temporal.Instant.from(data.organization.sieReport.datePeriod.end).toZonedDateTimeISO(timeZone).subtract({ nanoseconds: 1 }).toPlainDate()

    return `${start.toString().replace(/-/g, '')}-${end.toString().replace(/-/g, '')}.si`
  }, [data?.organization?.sieReport?.datePeriod, timeZone])

  const verificationList = sortOn(data?.organization?.sieReport?.verifications ?? [], '-date')

  const emailForm = useForm<{ email: string }>()

  const getDescription = (accountNumber: string): string => {
    const account = data?.organization?.sieAccounts?.find(account => account.number === accountNumber) ??
      data?.organization?.sieAccounts?.find(account => account.defaultNumber === accountNumber)

    return account?.description ?? account?.defaultDescription ?? 'N/A'
  }

  const handleDateRangeChange = (value: PlainDateRange): void => {
    setSelectedDateRange(value)
  }

  const handleRestaurantFilterPress = (restaurantId: string): void => {
    setRestaurantFilter(filter => {
      if (filter == null) return [restaurantId]

      let newFilter = []
      if (filter.includes(restaurantId)) {
        newFilter = filter.filter(id => id !== restaurantId)
      } else {
        newFilter = [...filter, restaurantId]
      }

      if (newFilter.length === 0) return null

      return newFilter
    })
  }

  const handleMarkAsResolvedPress = (sieVerificationId: string): void => {
    markFortnoxAccountingImportAsManuallyImported({
      variables: { organizationId, sieVerificationId }
    }).catch(logError)
  }

  const handleRetryPress = (sieVerificationId: string): void => {
    setRetriesLoading(retriesLoading => [...retriesLoading, sieVerificationId])

    retryFortnoxAccountingImport({
      onCompleted () {
        setRetriesLoading((ids) => ids.filter((id) => id !== sieVerificationId))
      },
      onError (error) {
        logError(error)
        setRetriesLoading((ids) => ids.filter((id) => id !== sieVerificationId))
      },
      variables: { organizationId, sieVerificationId }
    }).catch(logError)
  }

  const handleSaveEmail = ignoreAsync(emailForm.handleSubmit((): void => {
    if (data?.organization?.id == null) return

    setOrganizationAccountantEmail({ variables: { organizationId: data?.organization?.id, email: emailForm.getValues().email } }).catch(logError)
  }))

  const handleSearchPress = (): void => {
    setDateRange(selectedDateRange)
    setFilter({ restaurantIds: restaurantFilter, verificationTypes: verificationTypeFilter })
  }

  const handleShowSource = (source: SieReportSourceFragment, restaurantId: string | null | undefined): void => {
    switch (source.__typename) {
      case 'PhysicalControlUnitOutage':
        // We have no view for this yet
        break
      case 'StripeReport':
        navigation.navigate('StripeReportView', { organizationId, reportId: source.id })
        break
      case 'ZReport':
        navigation.navigate('CashregisterReportView', { restaurantId: unwrap(restaurantId), reportId: source.id })
        break
      default:
        unreachable(source)
    }
  }

  const handleToggleFilterPress = (): void => {
    setRestaurantFilter(null)
    setVerificationTypeFilter(null)
    setShowFilter(show => !show)
  }

  const handleVerificationTypeFilterPress = (type: SieVerificationType): void => {
    setVerificationTypeFilter(filter => {
      if (filter == null) return [type]

      let newFilter = []
      if (filter.includes(type)) {
        newFilter = filter.filter(t => t !== type)
      } else {
        newFilter = [...filter, type]
      }

      if (newFilter.length === 0) return null

      return newFilter
    })
  }

  interface VerificationItemProps {
    onMarkAsResolvedPress?: () => void
    onRetryPress?: () => void
    retrying?: boolean
    verification: SieVerificationFragment
  }

  const VerificationItem: React.FC<VerificationItemProps> = ({ onMarkAsResolvedPress, onRetryPress, retrying, verification }) => {
    const [showsSource, setShowsSource] = useState(false)

    const importJobStatus = (retrying ?? false) ? FortnoxAccountingImportJobStatus.Pending : verification.fortnoxAccountingImportJob?.status ?? null

    return (
      <VStack key={verification.id} padding={12}>
        <HStack alignItems='center' gap={12}>
          {onRetryPress == null ? null : (
            <PrimaryButton
              loading={retrying}
              onPress={onRetryPress}
              title='Försök igen'
            />
          )}

          <VerificationIcon type={verification.source?.__typename} />

          <VStack>
            <HStack alignItems='start' gap={4}>
              <Text size={16} weight='500'>{getVerificationTitle(verification)}</Text>
              {importJobStatus == null || importJobStatus === FortnoxAccountingImportJobStatus.Failed ? null : <Image source={{ uri: 'https://locousercontent.com/z_yhY7wbYARwU7N1tNva-85p6kGAvCGYzEbvLFr-Yt86-i7ieWOPU-8REaXWcCkH/original.png' }} style={{ borderRadius: 8, height: 16, width: 16 }} />}

              {importJobStatus !== FortnoxAccountingImportJobStatus.ManuallyImported ? null : <Text>Manuellt hanterad</Text>}

              {importJobStatus !== FortnoxAccountingImportJobStatus.Pending ? null : <Text>Överföring pågår</Text>}
            </HStack>

            <Text size={12}>{getVerificationDescription(verification)}</Text>
          </VStack>

          <Spacer grow={1} />

          {importJobStatus !== FortnoxAccountingImportJobStatus.Failed ? null : (
            <VStack>
              <HStack alignItems='center' gap={12}>
                <Image source={{ uri: 'https://locousercontent.com/z_yhY7wbYARwU7N1tNva-85p6kGAvCGYzEbvLFr-Yt86-i7ieWOPU-8REaXWcCkH/original.png' }} style={{ borderRadius: 8, height: 20, width: 20 }} />

                <VStack>
                  <Text color='red' weight='bold'>Överföring misslyckades</Text>

                  <TouchableOpacity onPress={() => showsSource ? showAlert('Loggen visas redan i avsnittet nedan') : setShowsSource(showsSource => !showsSource)}>
                    <Text color='#1e88e5'>Visa logg</Text>
                  </TouchableOpacity>
                </VStack>
              </HStack>
            </VStack>
          )}

          <VStack backgroundColor='lightgray' borderRadius={20} padding={8}>
            <MaterialIcons
              color='white'
              name={showsSource ? 'keyboard-arrow-up' : 'keyboard-arrow-down'}
              onPress={() => setShowsSource(showsSource => !showsSource)}
              size={24}
            />
          </VStack>
        </HStack>

        {verification.validationError == null
          ? null
          : <Text color='red' weight='bold'>{verification.validationError}</Text>}

        {!showsSource
          ? null
          : (
            <>
              <Spacer height={12} />

              {verification.fortnoxAccountingImportJob == null ? null : (
                <VStack borderColor='#003824' borderRadius={5} borderStyle='solid' borderWidth={1}>
                  <VStack gap={8} padding={12}>
                    <HStack gap={12} justifyContent='space-between'>
                      <Text size={16} weight='500'>Fortnox bokföringsimport</Text>

                      {onMarkAsResolvedPress == null ? null : (
                        <SecondaryButton
                          icon='check'
                          onPress={onMarkAsResolvedPress}
                          title='Markera som manuellt hanterad'
                        />
                      )}
                    </HStack>

                    <Text size={12}>Status: {fortnoxAccountingImportJobStatusToLabel(verification.fortnoxAccountingImportJob.status)}</Text>

                    {importJobStatus !== FortnoxAccountingImportJobStatus.Failed ? null : (
                      <TouchableOpacity onPress={() => openLink('https://help.gastrogate.io/fortnox-felkoder-/-felmeddelanden')}>
                        <Text color='#1e88e5' size={12}>Se vårt Helpcenter för mer hjälp att förstå felmeddelandena från Fortnox, och vad du behöver göra för att kunna synkronisera din verifikation</Text>
                      </TouchableOpacity>
                    )}

                    <HStack gap={12} justifyContent='space-between'>
                      <Text size={12}>Logg:</Text>

                      <Text size={12}>{verification.fortnoxAccountingImportJob.logEntries?.length ?? 0} poster</Text>
                    </HStack>

                    {sortOn(verification.fortnoxAccountingImportJob.logEntries ?? [], '-timestamp')?.map((logEntry) => (
                      <HStack key={logEntry.id} gap={12}>
                        {logEntry.timestamp == null ? null : <Text size={12}>{formatDateTime(Temporal.Instant.from(logEntry.timestamp).toZonedDateTimeISO(timeZone), { dateStyle: 'medium', timeStyle: 'short' })}</Text>}
                        <Text size={12}>{logEntry.message}</Text>
                      </HStack>
                    ))}
                  </VStack>
                </VStack>
              )}

              <Spacer height={24} />

              <Table>
                <Column paddingHorizontal={8} />
                <Column align='end' paddingHorizontal={8} width={128} />
                <Column align='end' paddingHorizontal={8} width={128} />

                <Row paddingVertical={8}>
                  <Cell>
                    <Text weight='bold'>Beskrivning</Text>
                  </Cell>
                  <Cell>
                    <Text weight='bold'>Konto</Text>
                  </Cell>
                  <Cell>
                    <Text weight='bold'>Summa</Text>
                  </Cell>
                </Row>

                {verification.transactions?.map((transaction) => (
                  <Row key={key(transaction)} paddingVertical={8}>
                    <Cell>
                      <Text>{transaction.accountNumber == null ? null : getDescription(transaction.accountNumber)}</Text>
                    </Cell>
                    <Cell>
                      <Text>{transaction.accountNumber}</Text>
                    </Cell>
                    <Cell>
                      <Text>{money(transaction.amount)}</Text>
                    </Cell>
                  </Row>
                ))}
              </Table>

              {verification.source == null || (verification.source.__typename !== 'StripeReport' && verification.source.__typename !== 'ZReport')
                ? null
                : (
                  <SecondaryButton
                    onPress={() => handleShowSource(unwrap(verification.source), verification.restaurants?.[0]?.id)}
                    title='Visa underlag'
                  />
                )}
            </>
          )}
      </VStack>
    )
  }

  useEffect(() => {
    emailForm.setValue('email', sieReportViewData?.organization?.accountantEmail ?? '')
  }, [sieReportViewData?.organization?.accountantEmail])

  return (
    <Layout loading={loadingSieReportViewData} title='Bokföring'>
      {showingMarkAsResolvedVerificationDialog == null ? null : (
        <VerificationDialog
          callToActionLabel='Hantera manuellt'
          errorMessage={markAsResolvedError?.message}
          loading={markingAsResolved}
          onDismiss={() => setShowingMarkAsResolvedVerificationDialog(null)}
          onSubmit={() => handleMarkAsResolvedPress(showingMarkAsResolvedVerificationDialog.id)}
          open
          prompt='Manuell hantering innebär att du hanterar bokföringen av denna verifikation manuellt, och verifikationen kommer inte försöka synkroniseras till Fortnox automatiskt. Är du säker på att du vill markera verifikationen som manuellt hanterad?'
          title='Manuell hantering'
        />
      )}

      <VStack gap={12} maxWidth={1024} padding={normalize(8, 20)}>
        <ButtonGroup
          buttons={[{ label: 'Bokföringsexport', value: 'sie' }, { label: 'Stripe', value: 'stripe' }]}
          onPress={(id) => {
            switch (id) {
              case 'stripe':
                navigation.navigate('StripeReportList', { organizationId })
                break
            }
          }}
          selected='sie'
        />

        <FormContainer gap={12} wide>
          <Text size={24} weight='bold'>Bokföringsexport</Text>

          <VStack maxWidth={640}>
            <Text>Här kan du automatiskt generera bokföringsunderlag. Du får en SIE-fil som går att importera direkt i alla svenska bokföringsprogram.</Text>
            <Spacer height={8} />
            <Text>Innan du laddar ner filen har du möjlighet att se hur verifikationerna ser ut här.</Text>
            <Spacer height={8} />
            <Text>Om du inte kan generera rapport innehållande gårdagen så beror det på att alla rapporter för den dagen inte är klara än.</Text>
          </VStack>

          <VStack gap={12} minWidth={250}>
            <TextField
              estimatedNumberOfCharacters={25}
              form={emailForm}
              name='email'
              title='E-postadress till ekonomiansvarig'
            />

            <HStack>
              <PrimaryButton
                loading={loadingEmailSave}
                onPress={handleSaveEmail}
                title='Spara ekonomiansvarig'
              />
            </HStack>
          </VStack>
        </FormContainer>

        <FormContainer gap={12} wide>
          <HStack alignItems='center' gap={4} justifyContent='space-between'>
            <HStack alignItems='center' gap={12}>
              {!(sieReportViewData?.organization?.hasFortnoxAccountingImportAccess ?? false) ? <Text size={24} weight='500'>Integration med externa bokföringssystem</Text> : (
                <>
                  <Image source={{ uri: 'https://locousercontent.com/z_yhY7wbYARwU7N1tNva-85p6kGAvCGYzEbvLFr-Yt86-i7ieWOPU-8REaXWcCkH/original.png' }} style={{ borderRadius: 8, height: 30, width: 30 }} />

                  <Text size={24} weight='500'>Fortnox</Text>

                  {(sieReportViewData?.organization?.fortnoxAccountingImportEnabled ?? false) ? null : (
                    <HStack alignItems='center' gap={4}>
                      <Ionicons color='red' name='warning-outline' size={24} />

                      <Text color='red' size={16} weight='500'>Inaktiverad</Text>
                    </HStack>
                  )}
                </>
              )}
            </HStack>

            <TouchableOpacity onPress={() => navigation.navigate('OrganizationFortnoxAccountingImportView', { organizationId: sieReportViewData?.organization?.id ?? '' })}>
              <HStack alignItems='center'>
                <MaterialIcons color='#1e88e5' name='open-in-new' size={24} />
              </HStack>
            </TouchableOpacity>
          </HStack>

          {!(sieReportViewData?.organization?.hasFortnoxAccountingImportAccess ?? false) ? (
            <>
              <Text>Synca automatiskt dina verifikationer till ditt bokföringsystem. Lägg till integrationen nedan för att läsa mer och acceptera användarvillkoren.</Text>

              <BusinessBoosterItem
                description='Automatisera bokföringen med en integration till fortnox. All bokföring och rapportering som inkluderas i SIE-filen kommer nu automatiskt att skickas över vid varje dagsavslut. Supersmidigt!'
                iconUrl='https://locousercontent.com/z_yhY7wbYARwU7N1tNva-85p6kGAvCGYzEbvLFr-Yt86-i7ieWOPU-8REaXWcCkH/original.png'
                isActive={hasFortnoxAccountingImportAccess}
                onActivationPress={() => (!hasOrganizationWriteAccess || organizationId == null) ? null : navigation.navigate('OrganizationFortnoxAccountingImportView', { organizationId })}
                price={`${formatCurrency(9900)}/mån per restaurang`}
                readMoreUrl='https://eu1.hubs.ly/H06rRf80'
                title='Fortnox bokföring'
              />
            </>
          ) : !(sieReportViewData?.organization?.fortnoxAccountingImportEnabled ?? false)
            ? (
              <VStack gap={12}>
                <Text color='#003823' size={16} weight='500'>Kopplingen till Fortnox är inte aktiv. Du måste fullborda kopplingen genom att länka Loco med Fortnox. Klicka nedan för att komma vidare.</Text>

                <PrimaryButton
                  onPress={() => navigation.navigate('OrganizationFortnoxAccountingImportView', { organizationId })}
                  title='Fullborda kopplingen till Fortnox'
                />
              </VStack>
            )
            : (
              <VStack gap={12}>
                <Text>Integrationen med Fortnox är aktiverad på denna organisation vilket betyder att verifikationer automatiskt synkroniseras till Fortnox Bokföring för det kopplade kontot.</Text>

                {/* <Text>Vid problem med bokföringen skickas ett mail till ekonomiansvarig och eventuella åtgärder som behöver tas listas nedan.</Text> */}
              </VStack>
            )}

          <Text size={22} weight='500'>Misslyckade överföringar</Text>

          <FlatList
            data={sieReportViewData?.organization?.fortnoxAccountingImportFailedVerifications ?? []}
            ItemSeparatorComponent={() => <Divider />}
            keyExtractor={item => item.id}
            ListEmptyComponent={() => (
              <VStack alignItems='center' justifyContent='center' padding={24}>
                <Text>Inga misslyckade överföringar</Text>
              </VStack>
            )}
            renderItem={({ item: verification }) => {
              const showsActionButtons = verification?.fortnoxAccountingImportJob?.status === FortnoxAccountingImportJobStatus.Failed || verification?.fortnoxAccountingImportJob?.status === FortnoxAccountingImportJobStatus.Pending

              return (
                <VerificationItem
                  onMarkAsResolvedPress={!showsActionButtons ? undefined : () => setShowingMarkAsResolvedVerificationDialog(verification)}
                  onRetryPress={!showsActionButtons ? undefined : () => handleRetryPress(verification.id)}
                  retrying={retriesLoading.includes(verification.id)}
                  verification={verification}
                />
              )
            }}
          />
        </FormContainer>

        <FormContainer gap={12} wide>
          <HStack justifyContent='space-between' wrap>
            <Text size={24} weight='500'>Verifikationer</Text>

            <SecondaryButton icon='cog' iconType='material-community' onPress={() => navigation.navigate('SieAccountList', { organizationId })} title='Ändra bokföringskonton' />
          </HStack>

          <HStack justifyContent='space-between' wrap>
            <HStack paddingTop={12} wrap>
              <HStack alignItems='baseline' wrap>
                <DateRangeSelect initialValues={{ plainDateRange: initialDateRange, timePeriodKind: initialTimePeriod.kind }} onDateRangeChange={handleDateRangeChange} timeZone={timeZone} />
              </HStack>

              {error?.message == null
                ? null
                : (
                  <>
                    <Spacer height={8} />

                    <Warning message={error?.message} />
                  </>
                )}
            </HStack>
          </HStack>

          <VStack gap={12}>
            <HStack alignItems='center' gap={12} justifyContent='end'>
              <TouchableOpacity onPress={handleToggleFilterPress}>
                <Text size={16} weight='300'>{showFilter ? 'Dölj' : 'Visa'} filter</Text>
              </TouchableOpacity>

              <HStack alignItems='center' wrap>
                {
                  /**
                   * // FIXME: This does not work on Internet Explorer nor Safari on iOS
                   * // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a
                   */
                }
                <Text>
                  <a
                    download={reportName}
                    href={`data:application/x-sie;base64,${data?.organization?.sieReport?.sieFileData ?? ''}`}
                    style={{ color: 'black' }}
                  >
                    Ladda ner rapport
                  </a>
                </Text>
              </HStack>
            </HStack>

            {!showFilter ? null : (
              <>
                {restaurantFilterOptions.length < 2 ? null : (
                  <VStack borderColor={INPUT_BORDER_COLOR} borderRadius={5} borderStyle='solid' borderWidth={1}>
                    <Text paddingLeft={8} paddingTop={8} size={16} weight='500'>Restaurang</Text>

                    <HStack wrap>
                      {restaurantFilterOptions?.map(restaurant => (
                        <CheckBox
                          key={restaurant.id}
                          checked={restaurantFilter?.includes(restaurant.id) ?? false}
                          onPress={() => handleRestaurantFilterPress(restaurant.id)}
                          title={restaurant.name ?? ''}
                        />
                      ))}
                    </HStack>
                  </VStack>
                )}

                <VStack borderColor={INPUT_BORDER_COLOR} borderRadius={5} borderStyle='solid' borderWidth={1}>
                  <Text paddingLeft={8} paddingTop={8} size={16} weight='500'>Rapporttyp</Text>

                  <HStack wrap>
                    <CheckBox
                      checked={verificationTypeFilter?.includes(SieVerificationType.ZReport) ?? false}
                      onPress={() => handleVerificationTypeFilterPress(SieVerificationType.ZReport)}
                      title='Z-rapport'
                    />

                    <CheckBox
                      checked={verificationTypeFilter?.includes(SieVerificationType.StripeReport) ?? false}
                      onPress={() => handleVerificationTypeFilterPress(SieVerificationType.StripeReport)}
                      title='Stripe'
                    />

                    <CheckBox
                      checked={verificationTypeFilter?.includes(SieVerificationType.PhysicalControlUnitOutage) ?? false}
                      onPress={() => handleVerificationTypeFilterPress(SieVerificationType.PhysicalControlUnitOutage)}
                      title='Driftstopp av kontrollenheten'
                    />
                  </HStack>
                </VStack>
              </>
            )}
          </VStack>

          <PrimaryButton
            onPress={handleSearchPress}
            title='Sök'
          />

          {loading
            ? <ActivityIndicator />
            : (
              <VStack>
                <WithSeparator separator={<Divider spacing={0} />}>
                  {verificationList?.map((verification) => <VerificationItem key={verification.id} verification={verification} />)}
                </WithSeparator>
              </VStack>
            )}
        </FormContainer>
      </VStack>
    </Layout>
  )
}

export default SieReportView
