import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import gql from 'graphql-tag'
import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FlatList, TouchableOpacity } from 'react-native'
import Spacer from 'react-spacer'
import { Text, VStack } from 'react-stacked'
import sortOn from 'sort-on'

import { type MetaOrganizationInput, useCreateMetaOrganizationMutation, useListMetaOrganizationsQuery } from '../../types/graphql'
import { AddButton, CancelButton } from '../components/Buttons'
import Layout from '../components/Layout'
import { TextField } from '../components/TextField'
import yup, { type ObjectSchema, yupResolver } from '../lib/validation'
import ignoreAsync from '../util/ignoreAsync'
import { normalize } from '../util/normalize'
import useNavigation from '../util/useNavigation'

gql`
  mutation CreateMetaOrganization($input: MetaOrganizationInput!) {
    createMetaOrganization(input: $input) {
      id
    }
  }

  query ListMetaOrganizations {
    metaOrganizations(first: 10000) {
      edges {
        node {
          id
          name
          createdAt
          updatedAt
        }
      }
    }
  }
`

interface CreateMetaOrganizationDialogProps {
  onDismiss: () => void
  visible: boolean
}

const schema: ObjectSchema<MetaOrganizationInput> = yup.object({
  name: yup.string().trim().min(1, 'Namn med minst ett tecken krävs').max(50, 'Namn med max 50 tecken tillåts').required()
})

const CreateMetaOrganizationDialog: React.FC<CreateMetaOrganizationDialogProps> = ({ visible, onDismiss }) => {
  const [navigation] = useNavigation<'MetaOrganizationList'>()
  const [create, { error, loading }] = useCreateMetaOrganizationMutation({ awaitRefetchQueries: true })

  const form = useForm<MetaOrganizationInput>({
    resolver: yupResolver(schema)
  })

  const handleSubmit = async (input: MetaOrganizationInput): Promise<void> => {
    await create({
      refetchQueries: ['ListMetaOrganizations'],
      variables: { input }
    })
    onDismiss()
    navigation.navigate('MetaOrganizationList', {})
  }

  return (
    <Dialog open={visible}>
      <DialogTitle>Skapa ny metaorganisation</DialogTitle>

      <DialogContent>
        <VStack>
          <TextField
            form={form}
            name='name'
            title='Namn'
          />

          <Spacer height={16} />

          {error == null ? null : String(error)}
        </VStack>
      </DialogContent>

      <DialogActions>
        <CancelButton key='cancel' disabled={loading} onPress={onDismiss} title='Avbryt' />
        <AddButton key='create' disabled={form.formState.isSubmitting} onPress={ignoreAsync(form.handleSubmit(handleSubmit))} title='Skapa' />
      </DialogActions>
    </Dialog>
  )
}

const MetaOrganizationList: React.FC = () => {
  const [navigation] = useNavigation<'MetaOrganizationList'>()
  const [isCreating, setIsCreating] = useState(false)
  const { data } = useListMetaOrganizationsQuery()

  const handleCreate = useCallback(() => setIsCreating(true), [])

  const handleOnDismissed = useCallback(() => setIsCreating(false), [setIsCreating])

  const handleSelect = useCallback((metaOrganizationId: string) => () => navigation.navigate('MetaOrganizationView', { metaOrganizationId }), [navigation])

  const metaOrganizations = sortOn(data?.metaOrganizations?.edges.map(edge => edge.node) ?? [], 'name')

  return (
    <Layout title='Metaorganisationer'>
      <CreateMetaOrganizationDialog onDismiss={handleOnDismissed} visible={isCreating} />

      <VStack maxWidth={768} padding={normalize(12, 20)}>
        <AddButton
          icon='add-circle'
          onPress={handleCreate}
          title='Skapa ny metaorganisation'
        />

        <Spacer height={24} />

        <FlatList
          data={metaOrganizations}
          keyExtractor={item => item.id}
          renderItem={({ item, index }) => (
            <TouchableOpacity
              onPress={handleSelect(item.id)}
              style={{ backgroundColor: index % 2 === 0 ? undefined : '#e1e1e1' }}
            >
              <Text padding={16} size={16}>{item.name ?? 'No name'}</Text>
            </TouchableOpacity>
          )}
        />
      </VStack>
    </Layout>
  )
}

export default MetaOrganizationList
