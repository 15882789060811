import gql from 'graphql-tag'
import React from 'react'
import { TouchableOpacity } from 'react-native'
import { HStack, Text, TextStyle } from 'react-stacked'
import sortOn from 'sort-on'

import { useGetOrganizationUserListQuery } from '../../types/graphql'
import { AddButton } from '../components/Buttons'
import Layout, { ScreenType } from '../components/Layout'
import ListContainer from '../components/ListContainer'
import useNavigation from '../util/useNavigation'

gql`
  query GetOrganizationUserList($organizationId: ID!) {
    organization(id: $organizationId) {
      id

      users {
        id

        email
        isOwner
        name
      }
    }
  }
`

const OrganizationUserList: React.FC = () => {
  const [navigation, { organizationId }] = useNavigation<'OrganizationUserList'>()

  const { data, loading } = useGetOrganizationUserListQuery({ variables: { organizationId } })

  return (
    <Layout hideTitle loading={loading} screenType={ScreenType.List} title='Användare'>
      <ListContainer title='Användare'>
        <AddButton
          onPress={() => navigation.navigate('OrganizationUserCreate', { organizationId })}
          title='Skapa ny användare'
        />

        {sortOn(data?.organization?.users ?? [], ['isOwner', 'name']).map(({ id, email, isOwner, name }) => (
          <TouchableOpacity key={id} onPress={() => navigation.navigate('OrganizationUserEdit', { organizationId, organizationUserId: id })}>
            <HStack backgroundColor='#fefefe' borderRadius={5} gap={12} justifyContent='space-between' padding={16}>
              <Text size={20}>
                {name}

                {!(isOwner ?? false)
                  ? null
                  : <TextStyle color='#999' size={20}>{' '}(ägare)</TextStyle>}
              </Text>

              <Text size={20}>{email}</Text>
            </HStack>
          </TouchableOpacity>
        ))}
      </ListContainer>
    </Layout>
  )
}

export default OrganizationUserList
