import { MaterialIcons } from '@expo/vector-icons'
import React from 'react'
import { Image, TouchableOpacity } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'

import { type FullPaymentMethodFragment } from '../../types/graphql'

import DisabledOverlay from './DisabledOverlay'
import ListItemWrapper from './ListItemWrapper'

const EMPTY_INFO_PLACEHOLDER = '\u2014' // emdash
const CHECK_MARK = '\u2713' // checkmark

const ViewSummary: React.FC<{ left: string, right: string }> = ({ left, right }) => {
  return (
    <HStack alignItems='center' wrap>
      <Text allowFontScaling numberOfLines={1} size={12} weight='600'>{left}</Text>

      <Spacer width={8} />

      <Text allowFontScaling numberOfLines={1} size={12}>{right}</Text>
    </HStack>
  )
}

interface RestaurantPaymentMethodListItemProps {
  item: FullPaymentMethodFragment
  onPress: (item: FullPaymentMethodFragment) => void
}

const RestaurantPaymentMethodListItem: React.FC<RestaurantPaymentMethodListItemProps> = ({ item, onPress }) => {
  const disabled = (item.isLegacy ?? false) && !(item.id === 'builtin-swish' || item.id === 'builtin-stripe')
  const isLegacySetup = (item.id === 'builtin-swish' || item.id === 'builtin-stripe') && item.paymentProvider == null

  return (
    <TouchableOpacity disabled={disabled} onPress={() => onPress(item)}>
      <DisabledOverlay backgroundColor='#00000044' disabled={disabled} message={<MaterialIcons color='white' name='lock' size={24} />}>
        <ListItemWrapper>
          <HStack grow={1} justifyContent='space-between' wrap>
            <HStack alignItems='center'>
              <VStack justifyContent='center' shrink={1}>
                <Image source={{ uri: item.iconUrl ?? undefined }} style={{ width: 60, height: 60, backgroundColor: '#fff' }} />
              </VStack>

              <VStack padding={8}>
                <Text size={16}>{item.name}</Text>
              </VStack>
            </HStack>

            <HStack grow={1}>
              {isLegacySetup ? null : (
                <VStack alignItems='end' grow={1}>
                  <ViewSummary
                    left='Ikon:'
                    right={item.iconUrl == null ? EMPTY_INFO_PLACEHOLDER : CHECK_MARK}
                  />

                  <ViewSummary
                    left='Betallösningsleverantör:'
                    right={item.paymentProvider ?? EMPTY_INFO_PLACEHOLDER}
                  />

                  <ViewSummary
                    left='Betalsättets namn på kvittot:'
                    right={item.receiptPaymentMethodLabel ?? EMPTY_INFO_PLACEHOLDER}
                  />

                  <ViewSummary
                    left='SIE kontonummer:'
                    right={item.sieAccountNumber ?? EMPTY_INFO_PLACEHOLDER}
                  />
                </VStack>
              )}
            </HStack>
          </HStack>

          {disabled
            ? null
            : isLegacySetup ? (
              <HStack alignItems='center'>
                <Text size={20}>Lägg till</Text>
                <MaterialIcons name='chevron-right' size={24} />
              </HStack>
            )
            : (
              <>
                <Spacer width={16} />

                <MaterialIcons name='edit' size={24} />
              </>
            )}
        </ListItemWrapper>
      </DisabledOverlay>
    </TouchableOpacity>
  )
}

export default RestaurantPaymentMethodListItem
