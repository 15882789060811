import { gql } from '@apollo/client'
import { MaterialIcons } from '@expo/vector-icons'
import React, { useEffect, useMemo, useState } from 'react'
import { ActivityIndicator, Image, Switch, TouchableOpacity } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'

import { useAcceptDeliveryHeroIntegrationTermsOfServiceMutation, useEnableDeliveryHeroIntegrationMutation, useGetDeliveryDeliveryHeroEditDataQuery, useUpdateDeliveryHeroIntegrationSettingsMutation } from '../../types/graphql'
import { PrimaryButton, SecondaryButton } from '../components/Buttons'
import Layout, { type Breadcrumb, ScreenType } from '../components/Layout'
import Link from '../components/Link'
import { PanicButton } from '../components/PanicButton'
import SetupContainer from '../components/SetupContainer'
import ToggleButton from '../components/ToggleButton'
import Warning from '../components/Warning'
import { ACCENT_COLOR } from '../lib/color'
import logError from '../util/logError'
import openLink from '../util/openLink'
import showAlert from '../util/showAlert'
import useNavigation from '../util/useNavigation'

gql`
  mutation AcceptDeliveryHeroIntegrationTermsOfService ($restaurantId: ID!, $input: AcceptDeliveryHeroIntegrationTermsOfServiceInput!) {
    acceptDeliveryHeroIntegrationTermsOfService(restaurantId: $restaurantId, input: $input) {
      id

      hasDeliveryHeroAccess

      deliveryHeroSetupQuestionnaire {
        hasAccount
        isReceivingOrders
      }
    }
  }

  mutation EnableDeliveryHeroIntegration ($restaurantId: ID!) {
    enableDeliveryHeroIntegration(restaurantId: $restaurantId) {
      id

      deliveryHeroEnabled
      hasDeliveryHeroAccess
    }
  }

  mutation UpdateDeliveryHeroIntegrationSettings ($restaurantId: ID!, $input: UpdateDeliveryHeroIntegrationSettingsInput!) {
    updateDeliveryHeroIntegrationSettings(restaurantId: $restaurantId, input: $input) {
      id

      autoAcceptDeliveryHeroOrders
    }
  }

  query GetDeliveryDeliveryHeroEditData($restaurantId: ID!) {
    restaurant(id: $restaurantId) {
      id

      autoAcceptDeliveryHeroOrders
      deliveryHeroChainId
      deliveryHeroVendorId
      deliveryHeroEnabled
      hasDeliveryHeroAccess
      hasDeliveryHeroCredentials

      deliveryHeroSetupQuestionnaire {
        hasAccount
        isReceivingOrders
      }
    }
  }
`

const DeliveryDeliveryHeroEdit: React.FC = () => {
  const [navigation, { restaurantId }] = useNavigation<'DeliveryDeliveryHeroEdit'>()

  const { data, error, loading } = useGetDeliveryDeliveryHeroEditDataQuery({ variables: { restaurantId } })
  const [acceptDeliveryHeroIntegrationTermsOfService, { error: acceptTermsOfServiceError, loading: acceptingTermsOfService }] = useAcceptDeliveryHeroIntegrationTermsOfServiceMutation()
  const [enableDeliveryHeroIntegration, { error: enableDeliveryHeroIntegrationError, loading: updatingDeliveryHeroEnabled }] = useEnableDeliveryHeroIntegrationMutation()
  const [updateDeliveryHeroIntegrationSettings, { error: deliveryHeroIntegrationSettingsError, loading: updatingDeliveryHeroIntegrationSettings }] = useUpdateDeliveryHeroIntegrationSettingsMutation()

  const deliveryHeroEnabled = data?.restaurant?.deliveryHeroEnabled ?? false
  const hasDeliveryHeroAccess = data?.restaurant?.hasDeliveryHeroAccess ?? false
  const autoAcceptDeliveryHeroOrders = data?.restaurant?.autoAcceptDeliveryHeroOrders ?? false
  const hasDeliveryHeroChainAndVendorId = data?.restaurant?.deliveryHeroChainId != null && data?.restaurant?.deliveryHeroVendorId != null

  const [hasAccount, setHasAccount] = useState<boolean | null>(null)
  const [isReceivingOrders, setIsReceivingOrders] = useState<boolean | null>(null)

  const hasNoFoodoraAccount = hasAccount === false
  const hasFoodoraAccountButNotReceivingOrders = hasAccount === true && isReceivingOrders === false
  const hasFoodoraAccountAndReceivingOrders = hasAccount === true && isReceivingOrders === true

  const handleAcceptPress = (): void => {
    if (hasAccount == null) {
      showAlert('Vänligen välj om restaurangen har ett konto hos Foodora eller inte')

      return
    }

    acceptDeliveryHeroIntegrationTermsOfService({
      variables: {
        restaurantId,
        input: {
          hasAccount,
          // Ensure isReceivingOrders is false if hasAccount
          isReceivingOrders: (hasAccount ?? false)
            ? (isReceivingOrders ?? false)
            : false
        }
      }
    }).catch(logError)
  }

  const handleDeliveryHeroEnabledUpdate = (): void => {
    enableDeliveryHeroIntegration({ variables: { restaurantId } }).catch(logError)
  }

  const handleDeliveryHeroIntegrationSettingsUpdate = (autoAcceptDeliveryHeroOrders: boolean): void => {
    updateDeliveryHeroIntegrationSettings({ variables: { restaurantId, input: { autoAcceptDeliveryHeroOrders } } }).catch(logError)
  }

  const breadcrumbs = useMemo<Breadcrumb[]>(() => [
    { link: ['RestaurantDeliveryList', { restaurantId }], title: 'Leverans' }
  ], [restaurantId])

  useEffect(() => {
    if (data?.restaurant?.deliveryHeroSetupQuestionnaire?.hasAccount == null) return
    if (data?.restaurant?.deliveryHeroSetupQuestionnaire?.isReceivingOrders == null) return

    setHasAccount(data.restaurant.deliveryHeroSetupQuestionnaire.hasAccount)
    setIsReceivingOrders(data.restaurant.deliveryHeroSetupQuestionnaire.isReceivingOrders)
  }, [data?.restaurant?.deliveryHeroSetupQuestionnaire])

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading} screenType={ScreenType.Form} title='Foodora'>
      {error?.message == null ? null : <Warning message={error.message} paddingBottom={12} />}

      {loading ? (
        <VStack alignItems='center' grow={1} justifyContent='center' padding={24}>
          <ActivityIndicator />
        </VStack>
      ) : (
        <VStack gap={12} grow={1} maxWidth={700}>
          <HStack>
            <SecondaryButton
              icon='chevron-left'
              onPress={() => navigation.navigate('RestaurantDeliveryList', { restaurantId })}
              title='Tillbaka'
            />
          </HStack>

          <SetupContainer
            disabled={hasDeliveryHeroAccess}
            disabledMessage={<MaterialIcons color='white' name='check-circle' size={48} />}
          >
            <VStack alignItems='center' gap={32} padding={24}>
              <Text color='#555' size={24}>Har restaurangen ett konto hos Foodora sedan tidigare?</Text>

              <HStack alignItems='center' gap={8} justifyContent='center'>
                <ToggleButton
                  disabled={hasDeliveryHeroAccess}
                  onPress={() => setHasAccount(false)}
                  title='Nej'
                  toggled={hasAccount === false}
                />

                <ToggleButton
                  disabled={hasDeliveryHeroAccess}
                  onPress={() => setHasAccount(true)}
                  title='Restaurangen har ett Foodora-konto'
                  toggled={hasAccount === true}
                />
              </HStack>
            </VStack>
          </SetupContainer>

          {hasAccount == null || hasNoFoodoraAccount ? null : (
            <SetupContainer
              disabled={hasDeliveryHeroAccess}
              disabledMessage={<MaterialIcons color='white' name='check-circle' size={48} />}
            >
              <VStack alignItems='center' gap={32} padding={24}>
                <Text color='#555' size={24}>Tar restaurangen emot beställningar via Foodora i dagsläget?</Text>

                <HStack alignItems='center' gap={8} justifyContent='center'>
                  <ToggleButton
                    disabled={hasDeliveryHeroAccess}
                    onPress={() => setIsReceivingOrders(false)}
                    title='Nej'
                    toggled={isReceivingOrders === false}
                  />

                  <ToggleButton
                    disabled={hasDeliveryHeroAccess}
                    onPress={() => setIsReceivingOrders(true)}
                    title='Restaurangen tar emot beställningar via Foodora'
                    toggled={isReceivingOrders === true}
                  />
                </HStack>
              </VStack>
            </SetupContainer>
          )}

          {!(hasNoFoodoraAccount || hasFoodoraAccountButNotReceivingOrders || hasFoodoraAccountAndReceivingOrders) ? null : (
            <SetupContainer
              disabled={hasDeliveryHeroAccess}
              disabledMessage={<MaterialIcons color='white' name='check-circle' size={48} />}
            >
              <HStack alignItems='center' gap={8}>
                <VStack alignItems='center' backgroundColor='orange' borderRadius={12} height={24} justifyContent='center' width={24}>
                  <Text color='white' size={16}>1</Text>
                </VStack>

                <Text color={ACCENT_COLOR} size={24}>Acceptera Terms of Service</Text>
              </HStack>

              <Text size={12}>För att kunna ta emot beställningar från Foodora till Loco behöver du ha ett Foodora-konto och göra kopplingen härigenom.</Text>
              <Text size={12}>Denna integration möjliggör</Text>
              <Text paddingLeft={8} size={12}>☑️ Beställning från Foodora dyker upp i LocoPOS Kitchen med tydlig markering och möjlighet att neka/acceptera, klarmarkera och markera för upphämtning (i enlighet med Foodoras föreskrifter)</Text>
              <Text paddingLeft={8} size={12}>☑️ Köksbongar markerade med "Foodora" kommer ut på skrivare</Text>
              <Text paddingLeft={8} size={12}>☑️ Syncning av meny "Leverans" till Foodora (Obs! existerande meny som ligger hos Foodora skrivs över till förmån för menyn i Loco)</Text>
              <Text paddingLeft={8} size={12}>☑️ Orderhistorik från Foodora som gått igenom Loco sparas och inkluderas i försäljningsstatistiken</Text>
              <Text paddingLeft={8} size={12}>☑️ Status för beställningar till Foodora dyker upp på Orderskärmen</Text>

              <Text size={12}>
                I samband med att du accepterar villkoren kommer vi att skicka en förfrågan till Foodora om att koppla ihop ditt konto med Loco, hur snabbt detta går beror på Foodoras supportteam, men det brukar vara klart inom 2 arbetsdagar.
              </Text>

              <Text size={12}>
                Notera att Foodora kan komma ha separata krav på sin tjänst som behöver åtgärdas från er sida innan integrationen går att använda.
              </Text>

              <Spacer height={12} />

              {hasNoFoodoraAccount
                ? <Text color={ACCENT_COLOR} size={24}>För dig utan konto hos Foodora</Text>
                : hasFoodoraAccountAndReceivingOrders
                ? <Text color={ACCENT_COLOR} size={24}>För dig som redan tar emot beställningar i Foodora</Text>
                : <Text color={ACCENT_COLOR} size={24}>För dig med konto hos Foodora, men som inte tar emot beställningar</Text>}

              <Text size={12}>ser processen för att koppla samman Loco och Foodora ut ungefär så här:</Text>
              <Text paddingLeft={8} size={12}>→ Du accepterar användarvillkoren</Text>
              {!(!(hasAccount ?? false) || ((hasAccount ?? false) && !(isReceivingOrders ?? false))) ? null : <Text paddingLeft={8} size={12}>→ Foodoras säljavdelning hör av sig till dig via e-postadressen länkad till din användare i Loco, och hjälper dig vidare i uppsättningen av Foodora</Text>}
              <Text paddingLeft={8} size={12}>→ Foodora kopplar ditt försäljningsställe i Foodora mot din restaurang i Loco</Text>
              <Text paddingLeft={8} size={12}>→ Du får ett e-postmeddelande om att Foodora är klara med inställningarna för sammankopplingen</Text>
              <Text paddingLeft={8} size={12}>→ Du sätter upp leveransmenyn genom att lägga till “Leverans” på de produkter du vill i menyn. Detta steg kan du påbörja även innan, eller om du har leveransmeny från Locos egna leverans så används samma meny.</Text>
              <Text paddingLeft={8} size={12}>→ Du trycker på knappen för menysynkronisering i Loco som gör att Loco-menyn kopieras till Foodora. Menyn synkroniseras också varje gång du sparar en produkt/uppdaterar menyn</Text>
              <Text paddingLeft={8} size={12}>→ Foodora slår på kopplingen, vilket gör att beställningarna nu kommer till LocoPOS Kitchen istället för Foodora-plattan</Text>

              <Spacer height={12} />

              <Text size={12}>
                Genom att acceptera villkoren för användandet av denna funktion bekräftar du att du läst våra <Link title='Terms of Service' url='https://gastrogate.notion.site/Terms-of-Service-Loco-d501714a78054cb5af6315c7ca0df83d' /> och att priset sätts i enlighet med rådande <Link title='prislista på gastrogate.io' url='https://gastrogate.io/foodora/' />.
              </Text>

              <Spacer height={12} />

              {acceptTermsOfServiceError?.message == null ? null : <Warning message={acceptTermsOfServiceError.message} paddingBottom={12} />}

              <PrimaryButton
                loading={acceptingTermsOfService}
                onPress={handleAcceptPress}
                title='Acceptera användarvillkor'
              />
            </SetupContainer>
          )}

          {!hasDeliveryHeroAccess ? null : (
            <SetupContainer
              disabled={hasDeliveryHeroChainAndVendorId}
              disabledMessage={<MaterialIcons color='white' name='check-circle' size={48} />}
            >
              <HStack alignItems='center' gap={8}>
                <VStack alignItems='center' backgroundColor='orange' borderRadius={12} height={24} justifyContent='center' width={24}>
                  <Text color='white' size={16}>2</Text>
                </VStack>

                <Text color={ACCENT_COLOR} size={24}>Foodora gör kopplingen mot Loco</Text>
              </HStack>

              <Text size={12}>Kopplingen mot Foodora innebär just nu en manuell process på grund av begränsningar i Foodoras system. Ett mail har skickats till Foodora, och vi väntar på att de sätter upp kopplingen.</Text>

              <Text size={12}>Beställningar fortsätts tas emot som vanligt via Foodoras hårdvara tills dess att Foodora slår ifrån dem. Det brukar de göra först efter menyn synkroniserats från Loco till Foodora av dig i nästa steg.</Text>
            </SetupContainer>
          )}

          {!hasDeliveryHeroChainAndVendorId ? null : (
            <SetupContainer
              disabled={deliveryHeroEnabled}
              disabledMessage={<MaterialIcons color='white' name='check-circle' size={48} />}
            >
              <HStack alignItems='center' gap={8}>
                <VStack alignItems='center' backgroundColor='orange' borderRadius={12} height={24} justifyContent='center' width={24}>
                  <Text color='white' size={16}>3</Text>
                </VStack>

                <Text color={ACCENT_COLOR} size={24}>Aktivering av sammankopplingen</Text>
              </HStack>

              <Text size={12}>Foodora har meddelat oss att de gjort de nödvändiga stegen som krävs för sammankoppling med Loco.</Text>

              {!(hasNoFoodoraAccount || hasFoodoraAccountButNotReceivingOrders) ? null : (
                <>
                  <Text color={ACCENT_COLOR} size={24}>Eftersom du inte tagit emot beställningar från Foodora tidigare,</Text>

                  <Text size={12}>krävs det att Foodora gjort de nödvändiga konfigurationerna från sitt håll för att du ska kunna aktivera kopplingen och synkronisera menyn. Från Locos sida kan vi inte se om detta är fallet. Vid problem med att aktivera "Loco-kopplingen och Synkronisera menyn" nedan kan du höra av dig till partner@foodora.se och säga att du inte kan synkronisera menyn - synkronisering av menyn ska fungera om allt är korrekt uppsatt.</Text>
                </>
              )}

              <Text color={ACCENT_COLOR} size={24}>Från Locos sida är det bara ett steg kvar🚶🏼‍♂️‍➡️</Text>

              <Text size={12}>Ställ in leveransmenyn så som du vill ha den, och aktivera sedan kopplingen och synkronisera menyn genom att klicka på knappen nedan. Vid lyckad aktivering kommer din Loco-meny att kopieras över till Foodora och beställningar kommer automatiskt börja trilla in i Loco, dyka upp i LocoPOS Kitchen och skrivas ut på köksskrivare i enlighet med övriga inställningar.</Text>

              <Text size={12}>⚠️ OBS! Om du har en leveransmeny från Locos egna leverans så används samma meny. Om du har en leveransmeny från Foodora så skrivs den över till förmån för menyn i Loco.</Text>
              <Text size={12}>⚠️⚠️ OBS! Vid lyckad synkronisering av menyn kommer beställningar från Foodora direkt in i LocoPOS Kitchen, bongar skrivs ut och beställningarna synas i Orderskärmen.</Text>

              {enableDeliveryHeroIntegrationError?.message == null ? null : <Warning message={enableDeliveryHeroIntegrationError.message} paddingBottom={12} />}

              {enableDeliveryHeroIntegrationError?.graphQLErrors[0].extensions?.code !== 'DELIVERY_HERO_NOT_CONNECTED' ? null : <Text size={12}>🙋🏽 Något har brustit i rutinerna. Vänligen hör av dig till service@gastrogate.io så kontaktar vi Foodora åt dig, och hjälper dig vidare.</Text>}
              {enableDeliveryHeroIntegrationError?.graphQLErrors[0].extensions?.code !== 'DELIVERY_HERO_MENU_SYNC_FAILED' ? null : <Text size={12}>🙋🏽 Detta beror sannolikt på att Foodora inte kopplat ditt konto till Salesforce. Hör av dig till partner@foodora.se och meddela att de behöver göra denna kopplingen så att du kan synkronisera menyn från ditt POS-system. Försök sedan igen genom att klicka på knappen.</Text>}

              <PrimaryButton
                loading={updatingDeliveryHeroEnabled}
                onPress={handleDeliveryHeroEnabledUpdate}
                title='Aktivera Loco-koppling & synkronisera menyn'
              />
            </SetupContainer>
          )}

          {!hasDeliveryHeroAccess || !hasDeliveryHeroChainAndVendorId || !deliveryHeroEnabled ? null : (
            <SetupContainer>
              <HStack alignItems='center' gap={8}>
                <VStack alignItems='center' backgroundColor='orange' borderRadius={12} height={24} justifyContent='center' width={24}>
                  <Text color='white' size={16}>4</Text>
                </VStack>

                <Text color={ACCENT_COLOR} size={24}>Allt klart från Locos sida</Text>
              </HStack>

              <Text size={12}>Det finns inga fler steg som behöver göras hos Loco, utan nu krävs det att Foodora har fullbordat kopplingen hos sig.</Text>

              <HStack alignItems='center' gap={14} padding={12}>
                <TouchableOpacity onPress={() => openLink('https://foodora.portal.restaurant/')}>
                  <Image source={{ uri: 'https://locousercontent.com/s_JC1hp6QlIKWBfgWDf_HvL5kQh5RvYPAofRdMXNiXt68JhVV76GxiySGwj-5UJ2/original.png' }} style={{ borderRadius: 12, height: 60, width: 60 }} />
                </TouchableOpacity>

                <VStack gap={5}>
                  <Text size={16}>Foodoras Partner Service</Text>

                  <Text size={12}>E-post: partner@foodora.se</Text>

                  <Text size={12}>Telefon: 08-12159330</Text>
                </VStack>
              </HStack>

              <TouchableOpacity onPress={() => openLink('https://apps.apple.com/se/app/locopos/id1176103744')}>
                <HStack alignItems='center' gap={14} padding={12}>
                  <Image source={{ uri: 'https://locousercontent.com/WRpmsUQ3KfGSFjS2WMWaMxy3RbuHbRuwjbfAl9c4VoKKM1q3b7l6mUYa4tzAF7UJ/original.png' }} style={{ borderRadius: 12, height: 60, width: 60 }} />

                  <VStack gap={5}>
                    <Text size={16}>LocoPOS</Text>

                    <Text size={12}>Hanterar inkommande beställningar, lagerhantering, returer och bongutskrifter</Text>
                  </VStack>
                </HStack>
              </TouchableOpacity>

              <HStack alignItems='center' gap={20}>
                <Text weight='bold'>Aktivera automatisk acceptans av beställningar från Foodora</Text>

                {deliveryHeroIntegrationSettingsError == null ? null : <Warning message={deliveryHeroIntegrationSettingsError.message} paddingBottom={12} />}

                <Switch
                  ios_backgroundColor='#3e3e3e'
                  onValueChange={() => handleDeliveryHeroIntegrationSettingsUpdate(!autoAcceptDeliveryHeroOrders)}
                  thumbColor={autoAcceptDeliveryHeroOrders ? '#f5dd4b' : '#f4f3f4'}
                  trackColor={{ false: '#767577', true: '#81b0ff' }}
                  value={autoAcceptDeliveryHeroOrders}
                />

                {!updatingDeliveryHeroIntegrationSettings ? null : <ActivityIndicator />}
              </HStack>

              <VStack gap={5}>
                <Text color={ACCENT_COLOR} size={16}>Länksamling</Text>
                <Link title='Terms of Service' url='https://gastrogate.notion.site/Terms-of-Service-Loco-d501714a78054cb5af6315c7ca0df83d' />
                <Link title='Prislista på gastrogate.io' url='https://gastrogate.io/foodora/' />
              </VStack>

              <VStack gap={5}>
                <Text color={ACCENT_COLOR} size={16}>Inaktivera kopplingen till Foodora</Text>

                <Text size={12}>Genom att inaktivera kopplingen kommer beställningar i Foodora sluta dyka upp i Locos system. Du kan enkelt aktivera kopplingen igen när du är redo att ta emot nya beställningar.</Text>

                <Text size={12}>Notera: Detta inaktiverar ingenting hos Foodora, utan för det hänvisar vi till Foodoras egna tjänst och support.</Text>

                {enableDeliveryHeroIntegrationError?.message == null ? null : <Warning message={enableDeliveryHeroIntegrationError.message} paddingBottom={12} />}

                <HStack alignItems='center' gap={12}>
                  <PanicButton
                    isDisabled={!deliveryHeroEnabled}
                    isLoading={updatingDeliveryHeroEnabled}
                    onActivation={handleDeliveryHeroEnabledUpdate}
                  />

                  <Text>Inaktivera Foodora-integrationen</Text>
                </HStack>
              </VStack>
            </SetupContainer>
          )}
        </VStack>
      )}
    </Layout>
  )
}

export default DeliveryDeliveryHeroEdit
