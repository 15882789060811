import gql from 'graphql-tag'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { ActivityIndicator, TouchableOpacity } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'

import { GetMenuCsvDataDocument, type GetMenuCsvDataQuery, type GetMenuCsvDataQueryVariables, GetMenuPagesDocument, ImportMenuCsvDataDocument, type ImportMenuCsvDataMutation, type ImportMenuCsvDataMutationVariables, type MenuPageFieldsFragment, RearrangalStatus, useGetMenuPagesQuery, useRearrangeMenuPagesMutation, useUpdateClientHeaderMessageMutation } from '../../types/graphql'
import client from '../client/graphql'
import { AddButton, DownloadButton, PrimaryButton } from '../components/Buttons'
import DraggableList from '../components/DraggableList'
import Layout from '../components/Layout'
import MenuTypeToggle from '../components/RestaurantMenu/MenuTypeToggle'
import PageToggleButton from '../components/RestaurantMenu/PageToggleButton'
import { TextField } from '../components/TextField'
import TextFilePicker from '../components/TextFilePicker'
import yup, { type ObjectSchema, yupResolver } from '../lib/validation'
import downloadTextAsFile from '../util/downloadTextAsFile'
import ignoreAsync from '../util/ignoreAsync'
import logError from '../util/logError'
import { normalize } from '../util/normalize'
import showAlert from '../util/showAlert'
import useNavigation from '../util/useNavigation'
import usePersistedMenuPageScroll from '../util/usePersistedMenuPageScroll'

gql`
  mutation RearrangeMenuPages($restaurantId: ID!, $order: [ID!]!) {
    rearrangeMenuPages(
      restaurantId: $restaurantId
      order: $order
    )
  }

  mutation ImportMenuCsvData($restaurantId: ID!, $csvData: String!) {
    importMenuFromCsv(restaurantId: $restaurantId, csvData: $csvData)
  }

  mutation UpdateClientHeaderMessage($restaurantId: ID!, $clientHeaderMessage: String) {
    updateRestaurant(
      restaurantId: $restaurantId
      patch: {
        clientHeaderMessage: $clientHeaderMessage
      }
    ) {
      id

      clientHeaderMessage
    }
  }

  query GetMenuCsvData($restaurantId: ID!) {
    restaurant(id: $restaurantId) {
      menu(filter: { active: All, includeProductsWithOpenPrice: true }) {
        csvData
      }
    }
  }
`

interface PageTitleProps {
  categoryCount: number
  name: string
  onPress: () => void
  productCount: number
}

const PageTitle: React.FC<PageTitleProps> = ({ categoryCount, name, onPress, productCount }) => (
  <TouchableOpacity onPress={onPress} style={{ flexGrow: 1, flexShrink: 1, justifyContent: 'center', padding: 12 }}>
    <Text size={16}>{name}</Text>

    <Text size={13}>
      {`${categoryCount} kategori${categoryCount !== 1 ? 'er' : ''}`}, {`${productCount} produkt${productCount !== 1 ? 'er' : ''}`}
    </Text>
  </TouchableOpacity>
)

interface MenuForm {
  clientHeaderMessage?: string | null
}

const schema: ObjectSchema<MenuForm> = yup.object({
  clientHeaderMessage: yup.string().nullable().max(1000)
})

const MenuView: React.FC = () => {
  const [navigation, { restaurantId }] = useNavigation<'MenuView'>()
  const { data, loading } = useGetMenuPagesQuery({ variables: { restaurantId } })
  const [rearrangeMenuPages] = useRearrangeMenuPagesMutation({ awaitRefetchQueries: true })
  const [updateClientHeaderMessage, { loading: updatingClientHeaderMessage }] = useUpdateClientHeaderMessageMutation()

  const [loadingCsvData, setLoadingCsvData] = React.useState(false)
  const [uploadingCsvData, setUploadingCsvData] = React.useState(false)
  const [, setPersistedMenuPageScrollSettings] = usePersistedMenuPageScroll()

  const values = { clientHeaderMessage: data?.restaurant?.clientHeaderMessage ?? null }

  const form = useForm<MenuForm>({
    criteriaMode: 'all',
    mode: 'onTouched',
    resolver: yupResolver(schema),
    values
  })

  useEffect(() => setPersistedMenuPageScrollSettings(null), [])

  const isSuperUser = data?.me?.isSuperUser ?? false

  const handleDragEnd = ignoreAsync(async (rearrangedPages: MenuPageFieldsFragment[]) => {
    await rearrangeMenuPages({
      variables: { restaurantId, order: rearrangedPages.map(page => page.id) },
      optimisticResponse: { rearrangeMenuPages: RearrangalStatus.Rearranged },
      update: (proxy) => {
        const newData = { ...data, restaurant: { ...data?.restaurant, menu: { ...data?.restaurant?.menu, pages: rearrangedPages } } }
        proxy.writeQuery({ query: GetMenuPagesDocument, variables: { restaurantId }, data: newData })
      },
      refetchQueries: [{ query: GetMenuPagesDocument, variables: { restaurantId } }]
    })
  })

  const handleExportCsv = ignoreAsync(async () => {
    setLoadingCsvData(true)

    try {
      const result = await client.query<GetMenuCsvDataQuery, GetMenuCsvDataQueryVariables>({ fetchPolicy: 'no-cache', query: GetMenuCsvDataDocument, variables: { restaurantId } })

      if (result.data.restaurant?.menu?.csvData == null) {
        throw new Error('Kunde inte hämta menydata')
      }

      downloadTextAsFile(result.data.restaurant.menu.csvData, 'meny.csv', 'text/csv')
    } catch (error) {
      showAlert('Fel', error.message)
    } finally {
      setLoadingCsvData(false)
    }
  })

  const handleImportCsv = ignoreAsync(async (csvData: string) => {
    setUploadingCsvData(true)

    try {
      await client.mutate<ImportMenuCsvDataMutation, ImportMenuCsvDataMutationVariables>({
        awaitRefetchQueries: true,
        mutation: ImportMenuCsvDataDocument,
        refetchQueries: [{ query: GetMenuPagesDocument, variables: { restaurantId } }],
        variables: { restaurantId, csvData }
      })
    } catch (error) {
      showAlert('Fel', error.message)
    } finally {
      setUploadingCsvData(false)
    }
  })

  const handleUpdateClientHeaderMessage = (): void => {
    form.handleSubmit((input): void => {
      updateClientHeaderMessage({ variables: { restaurantId, clientHeaderMessage: input.clientHeaderMessage } }).catch(logError)
    })().catch(logError)
  }

  return (
    <Layout title='Meny'>
      {!(data?.restaurant?.hasSettingsWriteAccess ?? false) ? null : <MenuTypeToggle restaurantId={restaurantId} />}

      <VStack gap={16} maxWidth={1024} padding={normalize(12, 20)}>
        {!(data?.restaurant?.hasClientHeaderMessageAccess ?? false) ? null : (
          <>
            <TextField
              form={form}
              hint='Meddelandet syns högst uppe i beställningsappen och vid beställningar på webben.'
              largeTitle
              name='clientHeaderMessage'
              title='Vill du kommunicera ut ett meddelande i beställningsappen eller online?'
            />

            <HStack justifyContent='end'>
              <PrimaryButton
                disabled={!form.formState.isDirty}
                loading={updatingClientHeaderMessage}
                onPress={handleUpdateClientHeaderMessage}
                title='Uppdatera meddelande'
              />
            </HStack>
          </>
        )}

        {!(data?.restaurant?.hasMenuWriteAccess ?? false) ? null : (
          <AddButton
            icon='add-circle'
            onPress={() => navigation.navigate('MenuPageCreate', { restaurantId })}
            title='Skapa ny huvudkategori'
          />
        )}

        {!isSuperUser ? null : (
          <>
            <DownloadButton loading={loadingCsvData} onPress={handleExportCsv} title='Exportera CSV' />
            <TextFilePicker loading={uploadingCsvData} onData={handleImportCsv} title='Importera CSV' />
          </>
        )}

        {data?.restaurant?.menu?.pages?.length === 0 ?
          <Text color='grey'>Ser din meny lite tom ut? Ingen fara, det är enkelt att komma igång. Börja med att skapa en ny huvudkategori</Text>
          : null}

        {loading
          ? <ActivityIndicator />
          : (
            <DraggableList
              data={data?.restaurant?.menu?.pages ?? []}
              disabled={!(data?.restaurant?.hasMenuWriteAccess ?? false)}
              keyExtractor={page => page.id}
              onDragEnd={handleDragEnd}
              renderItem={(dragHandle, page) => (
                <HStack key={page.id} alignItems='center' borderRadius={4} paddingLeft={6} paddingRight={12}>
                  {dragHandle}

                  <Spacer width={8} />

                  <PageTitle
                    categoryCount={page.categoryCount ?? 0}
                    name={page.name ?? ''}
                    onPress={() => navigation.navigate('MenuPageView', { restaurantId, menuPageId: page.id })}
                    productCount={page.productCount ?? 0}
                  />

                  <Spacer width={4} />

                  <PageToggleButton disabled={page.temporarilyDisabled ?? false} menuPageId={page.id} restaurantId={restaurantId} />
                </HStack>
              )}
            />
          )}
      </VStack>
    </Layout>
  )
}

export default MenuView
