import { MaterialIcons } from '@expo/vector-icons'
import React, { useMemo } from 'react'
import { ActivityIndicator, Text, TouchableOpacity } from 'react-native'

import { normalize } from '../util/normalize'

export const ACTIVE_BUTTON_COLOR = '#2C0'
export const INACTIVE_BUTTON_COLOR = '#C02'

const MINIMUM_TARGET_SIZE = 48
interface PanicButtonProps {
  inActivationMessage?: string
  isDisabled: boolean
  isLoading: boolean
  onActivation: () => void
  size?: number
}

export const PanicButton: React.FC<PanicButtonProps> = ({ inActivationMessage, isDisabled, isLoading, onActivation, size = 20 }) => {
  const normalizedSize = normalize(size * 1.5, size * 1.5)
  const inset = useMemo(() => Math.max(0, Math.round((MINIMUM_TARGET_SIZE - normalizedSize) / 2)), [normalizedSize])
  const hitSlop = useMemo(() => ({ bottom: inset, left: inset, right: inset, top: inset }), [inset])

  return (
    <>
      <TouchableOpacity
        hitSlop={hitSlop}
        onPress={() => isLoading ? null : onActivation()}
        style={{
          alignItems: 'center',
          backgroundColor: isDisabled && !isLoading ? INACTIVE_BUTTON_COLOR : ACTIVE_BUTTON_COLOR,
          borderRadius: normalizedSize / 2,
          height: normalizedSize,
          justifyContent: 'center',
          width: normalizedSize
        }}
      >
        {isLoading
          ? <ActivityIndicator color='#fff' size={normalize(size, size)} />
          : <MaterialIcons color='#fff' name='power-settings-new' size={normalize(size, size)} />}
      </TouchableOpacity>

      {inActivationMessage == null
        ? null
        : (
          isDisabled && !isLoading ? <Text style={{ paddingLeft: 5 }}>{inActivationMessage}</Text> : null
        )}
    </>
  )
}
