import { Temporal } from '@js-temporal/polyfill'
import gql from 'graphql-tag'
import React from 'react'
import { FlatList, TouchableOpacity } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'

import { useGetWhiteLabelDiscountListDataQuery } from '../../types/graphql'
import { CreateButton } from '../components/Buttons'
import DynamicIcon from '../components/DynamicIcon'
import Layout, { ScreenType } from '../components/Layout'
import ListContainer from '../components/ListContainer'
import ListItemWrapper from '../components/ListItemWrapper'
import formatCurrency from '../util/formatCurrency'
import formatDateTime from '../util/formatDateTime'
import useNavigation from '../util/useNavigation'

gql`
  query GetWhiteLabelDiscountListData ($id: ID!) {
    whiteLabel(id: $id) {
      id

      createdAt
      updatedAt

      discounts(first: 1000) {
        edges {
          node {
            ...FullDiscount
          }
        }

        pageInfo {
          hasNextPage
        }
      }
    }
  }
`

const WhiteLabelDiscountList: React.FC = () => {
  const [navigation, { whiteLabelId }] = useNavigation<'WhiteLabelDiscountList'>()
  const { data } = useGetWhiteLabelDiscountListDataQuery({ variables: { id: whiteLabelId } })

  const discounts = data?.whiteLabel?.discounts?.edges ?? []

  const handleDiscountPress = (discountId: string): void => {
    navigation.navigate('WhiteLabelDiscountEdit', { whiteLabelId, discountId })
  }

  return (
    <Layout
      breadcrumbs={[
        { link: ['WhiteLabelDashboard', { whiteLabelId }], title: 'App' }
      ]}
      screenType={ScreenType.List}
      title='Rabatter'
    >
      <ListContainer>
        <CreateButton onPress={() => navigation.navigate('WhiteLabelDiscountCreate', { whiteLabelId })} title='Skapa rabatt' />

        <FlatList
          data={discounts}
          ItemSeparatorComponent={() => <Spacer height={8} />}
          keyExtractor={(item) => item.node.id}
          renderItem={({ item }) => {
            return (
              <ListItemWrapper>
                <HStack alignItems='center' width='100%'>
                  <TouchableOpacity onPress={() => handleDiscountPress(item.node.id)} style={{ flexGrow: 1 }}>
                    <HStack>
                      <VStack justifyContent='space-between' paddingHorizontal={12}>
                        <Text weight='bold'>{item.node.code}</Text>

                        <VStack>
                          {item.node.createdAt == null ? null : <Text size={9}>Skapad: {formatDateTime(Temporal.Instant.from(item.node.createdAt).toZonedDateTimeISO('Europe/Stockholm'), { dateStyle: 'medium', timeStyle: 'short' })}</Text>}
                          {item.node.updatedAt == null ? null : <Text size={9}>Ändrad: {formatDateTime(Temporal.Instant.from(item.node.updatedAt).toZonedDateTimeISO('Europe/Stockholm'), { dateStyle: 'medium', timeStyle: 'short' })}</Text>}
                        </VStack>
                      </VStack>

                      <Spacer grow={1} width={16} />

                      <VStack alignItems='start' paddingHorizontal={12}>
                        <Text>Titel: {item.node.title}</Text>
                        <Text>Beskrivning: {item.node.description}</Text>
                        <Text>
                          Rabatt: {item.node.discountAmount != null && item.node.discountAmount > 0
                            ? formatCurrency(item.node.discountAmount)
                            : item.node.discountPercentage != null
                            ? `${item.node.discountPercentage}%`
                            : null}
                        </Text>
                        {item.node.validFrom == null ? null : <Text>Giltig från: {formatDateTime(Temporal.Instant.from(item.node.validFrom).toZonedDateTimeISO('Europe/Stockholm'), { dateStyle: 'medium', timeStyle: 'short' })}</Text>}
                        {item.node.validUntil == null ? null : <Text>Giltig till: {formatDateTime(Temporal.Instant.from(item.node.validUntil).toZonedDateTimeISO('Europe/Stockholm'), { dateStyle: 'medium', timeStyle: 'short' })}</Text>}
                      </VStack>
                      <DynamicIcon name='arrow-right' size={24} type='material' />
                    </HStack>
                  </TouchableOpacity>
                </HStack>
              </ListItemWrapper>
            )
          }}
        />
      </ListContainer>
    </Layout>
  )
}

export default WhiteLabelDiscountList
