import { MaterialIcons } from '@expo/vector-icons'
import gql from 'graphql-tag'
import React from 'react'
import { Image, TouchableOpacity } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'

import { useGetDeliveryListDataQuery } from '../../types/graphql'
import Layout, { ScreenType } from '../components/Layout'
import ListContainer from '../components/ListContainer'
import ListItemWrapper from '../components/ListItemWrapper'
import Warning from '../components/Warning'
import useNavigation from '../util/useNavigation'

gql`
  query GetDeliveryListData($restaurantId: ID!) {
    restaurant(id: $restaurantId) {
      id

      hasDeliveryMenu
      hasDeliveryHeroAccess
    }
  }
`

const RestaurantDeliveryList: React.FC = () => {
  const [navigation, { restaurantId }] = useNavigation<'RestaurantDeliveryList'>()

  const { data, error, loading } = useGetDeliveryListDataQuery({ variables: { restaurantId } })

  const hasDeliveryMenu = data?.restaurant?.hasDeliveryMenu ?? false
  const hasDeliveryHeroAccess = data?.restaurant?.hasDeliveryHeroAccess ?? false

  const deliveryOptions = [
    {
      description: 'Add a delivery menu to your restaurant',
      enabled: hasDeliveryMenu,
      iconUrl: 'https://locousercontent.com/5LCIBsVkm5T55FKawd13u41m4JuIboyibP8PDBskehv24-heopmR1vPidZBhO524/original.png',
      id: 'deliveryMenu',
      onPress: () => navigation.navigate('DeliveryLocoEdit', { restaurantId }),
      title: 'Egen leverans i Loco'
    },
    {
      description: 'Activate your restaurant on Delivery Hero',
      enabled: hasDeliveryHeroAccess,
      iconUrl: 'https://locousercontent.com/s_JC1hp6QlIKWBfgWDf_HvL5kQh5RvYPAofRdMXNiXt68JhVV76GxiySGwj-5UJ2/original.png',
      id: 'deliveryHero',
      onPress: () => navigation.navigate('DeliveryDeliveryHeroEdit', { restaurantId }),
      title: 'Foodora'
    }
  ]

  return (
    <Layout hideTitle loading={loading} screenType={ScreenType.List} title='Leverans'>
      <ListContainer title='Leverans'>
        {error?.message == null ? null : <Warning message={error.message} paddingBottom={12} />}

        {deliveryOptions.map(item => (
          <TouchableOpacity key={item.id} onPress={() => item.onPress()}>
            <ListItemWrapper>
              <HStack alignItems='center' grow={1}>
                <VStack justifyContent='center' shrink={1}>
                  <Image source={{ uri: item.iconUrl ?? undefined }} style={{ width: 60, height: 60, backgroundColor: '#fff' }} />
                </VStack>

                <Text padding={8} size={16}>{item.title}</Text>
              </HStack>

              {!item.enabled
                ? (
                  <HStack alignItems='center'>
                    <Text size={20}>Lägg till</Text>
                    <MaterialIcons name='chevron-right' size={24} />
                  </HStack>
                )
                : (
                  <>
                    <Spacer width={16} />

                    <MaterialIcons name='edit' size={24} />
                  </>
                )}
            </ListItemWrapper>
          </TouchableOpacity>
        ))}
      </ListContainer>
    </Layout>
  )
}

export default RestaurantDeliveryList
