import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons'
import gql from 'graphql-tag'
import React, { useCallback, useState } from 'react'
import { ActivityIndicator, FlatList, TouchableOpacity } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'

import { type DeliveryLocationFieldsFragment, DeliveryLocationType, useListDeliveryLocationsQuery, useSetDeliveryLocationDisabledMutation } from '../../types/graphql'
import { AddButton } from '../components/Buttons'
import Layout from '../components/Layout'
import { PanicButton } from '../components/PanicButton'
import ignoreAsync from '../util/ignoreAsync'
import logError from '../util/logError'
import showAlert from '../util/showAlert'
import useNavigation from '../util/useNavigation'

gql`
  fragment DeliveryLocationFields on DeliveryLocation {
    id
    disabled
    eatInEnabled
    name
    takeAwayEnabled
    type
  }

  query ListDeliveryLocations($restaurantId: ID!) {
    restaurant(id: $restaurantId) {
      id

      deliveryLocations(filter: {active: All}) {
        ...DeliveryLocationFields
      }
    }
  }

  mutation SetDeliveryLocationDisabled($restaurantId: ID!, $deliveryLocationId: ID!, $disabled: Boolean!) {
    updateDeliveryLocation(restaurantId: $restaurantId, deliveryLocationId: $deliveryLocationId, patch: { disabled: $disabled }) {
      ...DeliveryLocationFields
    }
  }
`

const DeliveryLocationList: React.FC = () => {
  const [navigation, { restaurantId }] = useNavigation<'DeliveryLocationList'>()

  const { data, loading } = useListDeliveryLocationsQuery({ variables: { restaurantId } })

  const [setDeliveryLocationDisabled, { loading: updating }] = useSetDeliveryLocationDisabledMutation()

  const [mutationId, setMutationsId] = useState<string>()

  const handleOnPress = useCallback((deliveryLocationId: string) => () => {
    navigation.navigate('DeliveryLocationEdit', { restaurantId, deliveryLocationId })
  }, [navigation, restaurantId])

  const handleToggleDisable = useCallback((deliveryLocation: DeliveryLocationFieldsFragment) =>
    ignoreAsync(async () => {
      setMutationsId(deliveryLocation.id)

      const disabled = deliveryLocation.disabled !== true
      try {
        await setDeliveryLocationDisabled({
          variables: { restaurantId, deliveryLocationId: deliveryLocation.id, disabled },
          optimisticResponse: { updateDeliveryLocation: { ...deliveryLocation, disabled } }
        })
      } catch (error) {
        logError(error)
        showAlert('Ett fel inträffade', error.message)
      }
    }), [restaurantId])

  return (
    <Layout title='Bord / Kassa'>
      <VStack gap={16} maxWidth={1024} padding={20}>
        <HStack gap={16}>
          <VStack grow={1}>
            <AddButton
              icon='table-chair'
              iconType='material-community'
              onPress={() => navigation.navigate('DeliveryLocationCreate', { restaurantId, type: DeliveryLocationType.Table })}
              title='Nytt bord'
            />
          </VStack>

          <VStack grow={1}>
            <AddButton
              icon='cash-register'
              iconType='material-community'
              onPress={() => navigation.navigate('DeliveryLocationCreate', { restaurantId, type: DeliveryLocationType.CashDesk })}
              title='Ny kassa'
            />
          </VStack>
        </HStack>

        {loading
          ? <ActivityIndicator />
          : (
            <FlatList
              data={data?.restaurant?.deliveryLocations ?? []}
              ItemSeparatorComponent={() => <Spacer height={6} />}
              keyExtractor={item => item.id}
              ListEmptyComponent={<Text align='center' padding={16}>Det finns inga bord eller kassor tillagda</Text>}
              renderItem={({ item: deliveryLocation }) => (
                <TouchableOpacity onPress={handleOnPress(deliveryLocation.id)}>
                  <HStack alignItems='center' backgroundColor='#fff' minHeight={48} padding={12}>
                    <PanicButton
                      isDisabled={deliveryLocation.disabled ?? false}
                      isLoading={loading || (updating && deliveryLocation.id === mutationId)}
                      onActivation={handleToggleDisable(deliveryLocation)}
                    />

                    <Spacer width={12} />

                    {deliveryLocation.type === DeliveryLocationType.CashDesk
                      ? <MaterialCommunityIcons name='cash-register' size={24} />
                      : <MaterialCommunityIcons name='table-chair' size={24} />}

                    <Spacer width={12} />

                    <VStack>
                      <Text size={16}>{deliveryLocation.name}</Text>
                      {deliveryLocation.type === DeliveryLocationType.CashDesk && deliveryLocation.eatInEnabled !== true
                        ? <Text color='#555' size={12}>Endast ta med</Text>
                        : undefined}
                    </VStack>

                    <Spacer grow={1} width={8} />

                    <MaterialIcons name='edit' size={16} />
                  </HStack>
                </TouchableOpacity>
              )}
            />
          )}
      </VStack>
    </Layout>
  )
}

export default DeliveryLocationList
