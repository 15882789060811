import { TouchableOpacity } from 'react-native'
import { Text } from 'react-stacked'

interface ToggleButtonProps {
  disabled?: boolean
  toggled: boolean
  onPress: (value: boolean) => void
  title: string
}

const ToggleButton: React.FC<ToggleButtonProps> = ({ disabled, onPress, title, toggled }) => {
  const handlePress = (): void => {
    onPress(!toggled)
  }

  return (
    <TouchableOpacity
      disabled={disabled}
      onPress={handlePress}
      style={{
        alignItems: 'center',
        backgroundColor: toggled ? '#ffa500' : 'transparent',
        borderRadius: 24,
        borderColor: '#ffa500',
        borderWidth: 1,
        justifyContent: 'center',
        paddingHorizontal: 24,
        paddingVertical: 12
      }}
    >
      <Text color={toggled ? '#fff' : '#ffa500'} size={16} transform='uppercase' weight='500'>{title}</Text>
    </TouchableOpacity>
  )
}

export default ToggleButton
