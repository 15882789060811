import React from 'react'
import { ActivityIndicator, TouchableOpacity } from 'react-native'
import { HStack, Text } from 'react-stacked'

import { ACCENT_COLOR } from '../lib/color'

import DynamicIcon, { type DynamicIconType } from './DynamicIcon'

interface IconProps {
  icon?: string
  iconType?: DynamicIconType
}

export interface ButtonProps extends IconProps {
  disabled?: boolean
  loading?: boolean
  onPress: () => void
  title: string
}

interface CreateButtonInput extends IconProps {
  backgroundColor: string
  borderRadius?: number
  color: string
  iconPosition?: 'right'
  paddingHorizontal?: number
  paddingVertical?: number
}

function createButtonComponent ({ backgroundColor, borderRadius, color, icon: defaultIcon, iconType: defaultIconType, iconPosition, paddingHorizontal, paddingVertical }: CreateButtonInput): React.FC<ButtonProps> {
  return ({ disabled = false, icon = defaultIcon, iconType = defaultIconType, loading = false, onPress, title }) => {
    const handlePress = (): void => {
      if (!disabled && !loading) onPress()
    }

    return (
      <TouchableOpacity accessibilityRole='button' accessibilityState={{ busy: loading, disabled }} onPress={handlePress}>
        <HStack alignItems='center' backgroundColor={disabled ? '#eee' : backgroundColor} borderRadius={borderRadius ?? 2} gap={12} justifyContent='center' paddingHorizontal={paddingHorizontal ?? 24} paddingVertical={paddingVertical ?? 8}>
          {loading ? (
            <ActivityIndicator
              color={backgroundColor === 'transparent' ? color : 'white'}
              size='small'
            />
          ) : (
            <>
              {icon != null && iconPosition !== 'right' && <DynamicIcon color={disabled ? '#999' : color} name={icon} size={16} type={iconType} />}
              <Text color={disabled ? '#999' : color} size={16} transform='uppercase'>{title}</Text>
              {icon != null && iconPosition === 'right' && <DynamicIcon color={disabled ? '#999' : color} name={icon} size={16} type={iconType} />}
            </>
          )}
        </HStack>
      </TouchableOpacity>
    )
  }
}

export const AddButton = createButtonComponent({ backgroundColor: '#79bd74', color: '#fff' })
export const DeleteButton = createButtonComponent({ backgroundColor: '#da534f', color: '#fff' })
export const PrimaryButton = createButtonComponent({ backgroundColor: ACCENT_COLOR, color: '#fff' })
export const SecondaryButton = createButtonComponent({ backgroundColor: 'transparent', color: '#1e88e5' })
export const ListItemsPrimaryButton = createButtonComponent({ backgroundColor: ACCENT_COLOR, borderRadius: 8, color: '#fff', paddingVertical: 18 })

export const CancelButton: React.FC<Omit<ButtonProps, keyof IconProps>> = createButtonComponent({ backgroundColor: '#fff', color: '#000' })
export const CopyToButton: React.FC<Omit<ButtonProps, keyof IconProps>> = createButtonComponent({ backgroundColor: '#1e88e5', color: '#fff', icon: 'content-copy' })
export const CreateButton: React.FC<Omit<ButtonProps, keyof IconProps>> = createButtonComponent({ backgroundColor: ACCENT_COLOR, color: '#fff', icon: 'add-circle' })
export const DownloadButton: React.FC<Omit<ButtonProps, keyof IconProps>> = createButtonComponent({ backgroundColor: ACCENT_COLOR, color: '#fff', icon: 'cloud-download' })
export const RedoButton: React.FC<Omit<ButtonProps, keyof IconProps>> = createButtonComponent({ backgroundColor: '#4C1530', color: '#e6e0e2', icon: 'redo' })
export const SaveAsButton: React.FC<Omit<ButtonProps, keyof IconProps>> = createButtonComponent({ backgroundColor: 'transparent', color: '#1e88e5', icon: 'file-csv', iconType: 'font-awesome-5', iconPosition: 'right', paddingHorizontal: 2 })
export const SortButton: React.FC<Omit<ButtonProps, keyof IconProps>> = createButtonComponent({ backgroundColor: '#1e88e5', color: '#fff', icon: 'sort' })
export const UpdateButton: React.FC<Omit<ButtonProps, keyof IconProps>> = createButtonComponent({ backgroundColor: '#fff1e3', color: ACCENT_COLOR, icon: 'refresh' })
export const UploadButton: React.FC<Omit<ButtonProps, keyof IconProps>> = createButtonComponent({ backgroundColor: ACCENT_COLOR, color: '#fff', icon: 'cloud-upload' })
