import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons'
import { Temporal } from '@js-temporal/polyfill'
import React, { type ReactNode, useCallback, useMemo } from 'react'
import { TouchableOpacity } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, TextStyle, VStack } from 'react-stacked'
import unreachable from 'ts-unreachable'
import unwrap from 'ts-unwrap'
import key from 'weak-key'

import { DiscountUsability, type FullDiscountFragment, MenuType } from '../../types/graphql'
import extractPeriods from '../util/extractPeriods'
import formatCurrency from '../util/formatCurrency'
import formatDateTimeUpstream from '../util/formatDateTime'
import getBunchedOpeningPeriods from '../util/getBunchedOpeningPeriods'
import getBunchedPeriodsShortform from '../util/getBunchedPeriodsShortform'
import showAlert from '../util/showAlert'

import DiscountToggleButton from './DiscountToggleButton'
import OpeningHoursShortform from './OpeningHoursShortform'
import { ACTIVE_BUTTON_COLOR, INACTIVE_BUTTON_COLOR } from './PanicButton'

const Container: React.FC<{ children: ReactNode, onPress: () => void }> = ({ children, onPress }) => (
  <TouchableOpacity onPress={onPress} style={{ backgroundColor: '#fff', borderRadius: 8, flex: 1 }}>
    {children}
  </TouchableOpacity>
)

const Row: React.FC<{ left: React.ReactElement, right: React.ReactElement }> = ({ left, right }) => (
  <HStack alignItems='center'>
    {left}
    <Spacer width={16} />
    {right}
  </HStack>
)

function discountInfo (discount: FullDiscountFragment): string {
  const discountPercentage = discount.discountPercentage ?? 0
  if (discountPercentage !== 0) return `${discountPercentage} %`

  return formatCurrency(unwrap(discount.discountAmount))
}

function formatDateTime (date: string, timeZone?: string): string
function formatDateTime (date: string | null | undefined, timeZone?: string): string | null
function formatDateTime (date: string | null | undefined, timeZone?: string): string | null {
  return date == null ? null : formatDateTimeUpstream(Temporal.Instant.from(date).toZonedDateTimeISO(timeZone ?? 'Europe/Stockholm'), { dateStyle: 'medium', timeStyle: 'short' })
}

function minMaxLimitations (discount: FullDiscountFragment): string {
  if (discount.minAmount == null) {
    if (discount.maxAmount == null) return ''

    return `på köp upp till ${formatCurrency(discount.maxAmount)}`
  }

  if (discount.maxAmount == null) return `på köp på minst ${formatCurrency(discount.minAmount)}`

  return `på köp mellan ${formatCurrency(discount.minAmount)} och ${formatCurrency(discount.maxAmount)}`
}

// FIXME: Use normalizedCode from GraphQL when it is available
function normalizedCode (code: string | null | undefined): string | undefined {
  return code?.toLowerCase().replace(/[ _-]/g, '')
}

function translateMenuType (menuType: MenuType): string {
  switch (menuType) {
    case MenuType.Delivery:
      return 'leverans'
    case MenuType.EatIn:
      return 'äta här'
    case MenuType.GiftCard:
      return 'presentkort'
    case MenuType.TakeAway:
      return 'ta med'
    default:
      unreachable(menuType)
  }
}

interface DiscountCardProps {
  discount: FullDiscountFragment
  onPress: () => void
  parentType: 'Restaurant' | 'MetaOrganization'
  timeZone?: string
}

const DiscountCard: React.FC<DiscountCardProps> = ({ discount, onPress, parentType, timeZone }) => {
  // The discount is read only if there is no owner
  const owner = useMemo(() => {
    if (parentType === 'Restaurant' && discount.restaurant != null) {
      return { restaurantId: discount.restaurant.id }
    }

    if (parentType === 'MetaOrganization' && discount.metaOrganization != null) {
      return { metaOrganizationId: discount.metaOrganization.id }
    }
  }, [discount])

  const handleOnPress = useCallback(() => {
    if (owner == null) {
      showAlert('Rabatten låst', 'Det är inte möjligt att ändra denna rabatt då den är skapad centralt. Kontakta support för mer info.')
    } else {
      onPress()
    }
  }, [onPress, owner])

  const usabilityWarning = useMemo(() => {
    if (discount.usability === DiscountUsability.Misconfigured) return 'Felaktigt konfigurerad?!' // This one should never happen!
    if (discount.discountLimit != null && discount.totalDiscountAmount != null && discount.discountLimit <= discount.totalDiscountAmount) return 'Förbrukad'
    if (discount.validUntil != null && new Date(discount.validUntil) < new Date()) return `Slutade gälla ${formatDateTime(discount.validUntil, timeZone)}`
    if (discount.validFrom != null && new Date(discount.validFrom) > new Date()) return `Börjar gälla ${formatDateTime(discount.validFrom, timeZone)}`
  }, [discount, timeZone])
  const validFrom = useMemo(() => formatDateTime(discount.validFrom, timeZone), [discount.validFrom, timeZone])
  const validUntil = useMemo(() => formatDateTime(discount.validUntil, timeZone), [discount.validUntil, timeZone])

  return (
    <Container onPress={handleOnPress}>
      <VStack padding={8}>
        <HStack alignItems='center' maxWidth={480}>
          <Row
            left={owner == null
              ? <MaterialCommunityIcons color={(discount.isDisabled ?? false) ? INACTIVE_BUTTON_COLOR : ACTIVE_BUTTON_COLOR} name='checkbox-blank-circle' style={{ marginLeft: 8 }} />
              : <DiscountToggleButton discount={discount} owner={owner} />}
            right={
              <Text size={16}>
                Kod: <TextStyle size={18} weight='bold'>{normalizedCode(discount.code)}</TextStyle>
              </Text>
            }
          />

          {usabilityWarning == null
            ? null
            : (
              <>
                <Spacer grow={1} />
                <HStack backgroundColor='#FFA50088' borderRadius={4} padding={2} paddingHorizontal={6}>
                  <Text size={16}>{usabilityWarning}</Text>
                </HStack>
              </>
            )}
        </HStack>

        <VStack gap={12} padding={6} paddingVertical={18}>
          {discount.title == null ? null : <Text size={16}>{discount.title}</Text>}

          {discount.description == null ? null : <Text color='#333'>{discount.description}</Text>}

          <Row
            left={<MaterialCommunityIcons name='tag' size={20} />}
            right={
              <Text>
                Ger <TextStyle weight='bold'>{discountInfo(discount)}</TextStyle> i rabatt {minMaxLimitations(discount)}
              </Text>
            }
          />

          {discount.menuType == null ? null : (
            <Row
              left={<MaterialIcons name='restaurant-menu' size={20} />}
              right={
                <Text>
                  Gäller för <TextStyle weight='bold'>{translateMenuType(unwrap(discount.menuType))}</TextStyle>
                </Text>
              }
            />
          )}

          {discount.numberOfTimesPerUser == null || discount.numberOfTimesPerUser === 0 ? null : (
            <Row
              left={<MaterialCommunityIcons name='account' size={20} />}
              right={
                <Text>
                  Kan användas <TextStyle weight='bold'>{discount.numberOfTimesPerUser} gång{discount.numberOfTimesPerUser > 1 ? 'er' : ''}</TextStyle> per användare
                </Text>
              }
            />
          )}

          {validFrom == null && validUntil == null ? null : (
            <Row
              left={<MaterialCommunityIcons name='calendar-text' size={20} />}
              right={validFrom != null && validUntil != null
                ? (
                  <HStack gap={4} wrap>
                    <Text>
                      Gäller från och med <TextStyle weight='bold'>{validFrom}</TextStyle>
                    </Text>

                    <Text>
                      till och med <TextStyle weight='bold'>{validUntil}</TextStyle>
                    </Text>
                  </HStack>
                )
                : validFrom != null
                ? (
                  <Text>
                    Gäller från och med <TextStyle weight='bold'>{validFrom}</TextStyle>
                  </Text>
                )
                : (
                  <Text>
                    Gäller till och med <TextStyle weight='bold'>{validUntil}</TextStyle>
                  </Text>
                )}
            />
          )}

          {discount.openingHours == null ? null : (
            <Row
              left={<MaterialCommunityIcons name='clock-check-outline' size={20} />}
              right={
                <VStack>
                  {getBunchedPeriodsShortform(getBunchedOpeningPeriods(extractPeriods(discount?.openingHours))).map(period => (
                    <OpeningHoursShortform
                      key={key(period)}
                      closeTime={period.closeTime}
                      firstDay={period.firstDay}
                      lastDay={period.lastDay}
                      openTime={period.openTime}
                    />
                  ))}
                </VStack>
              }
            />
          )}

          {discount.discountLimit == null && (discount.totalDiscountAmount ?? 0) === 0 ? null : (
            <Row
              left={<MaterialIcons name='attach-money' size={20} />}
              right={discount.discountLimit == null
                ? (
                  <Text>
                    Förbrukat <TextStyle weight='bold'>{formatCurrency(unwrap(discount.totalDiscountAmount))}</TextStyle>
                  </Text>
                )
                : (
                  <Text>
                    Förbrukat <TextStyle weight='bold'>{formatCurrency(discount.totalDiscountAmount ?? 0)}</TextStyle> av totalt {formatCurrency(discount.discountLimit)}
                  </Text>
                )}
            />
          )}
        </VStack>
      </VStack>
    </Container>
  )
}

export default DiscountCard
