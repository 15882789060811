import { MaterialIcons } from '@expo/vector-icons'
import gql from 'graphql-tag'
import React, { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { ActivityIndicator, Image, ScrollView, TouchableOpacity } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'
import sortOn from 'sort-on'

import { type WhiteLabelListEntryFragment, useListWhiteLabelsQuery } from '../../types/graphql'
import ButtonGroup from '../components/ButtonGroup'
import { AddButton } from '../components/Buttons'
import Layout, { ScreenType } from '../components/Layout'
import ListContainer from '../components/ListContainer'
import { TextField } from '../components/TextField'
import yup, { type ObjectSchema, yupResolver } from '../lib/validation'
import { normalize } from '../util/normalize'
import useNavigation from '../util/useNavigation'

gql`
  fragment WhiteLabelListEntry on WhiteLabel {
    id
    isReadyForPublishing
    label
    createdAt
    updatedAt

    restaurants(filter: { active: All }, first: 999) {
      edges {
        node {
          id
          name
        }
      }
    }

    theme {
      backgroundColor
      borderColor
      cardColor
      notificationColor
      primaryColor
      textColor
    }

    appIconUrl
    appName
    buildNumber
    deliveryEnabled
  }

  query ListWhiteLabels {
    whiteLabels(first: 999) {
      edges {
        node {
          ...WhiteLabelListEntry
        }
      }
    }
  }
`
type WhiteLabelFilter = 'all' | 'isReadyForPublishing' | 'isNotReadyForPublishing'
const whiteLabelFilterOptions: Array<{ label: string, value: WhiteLabelFilter }> = [
  { label: 'Alla', value: 'all' },
  { label: 'Publicerbara', value: 'isReadyForPublishing' },
  { label: 'Ej publicerbara', value: 'isNotReadyForPublishing' }
]

interface WhiteLabelListFormFields {
  searchString?: string
}

const schema: ObjectSchema<WhiteLabelListFormFields> = yup.object({
  searchString: yup.string().trim()
})

const whiteLabelFilterFunction = (whiteLabel: WhiteLabelListEntryFragment, whiteLabelFilter: WhiteLabelFilter): boolean => {
  switch (whiteLabelFilter) {
    case 'all':
      return true
    case 'isReadyForPublishing':
      return whiteLabel.isReadyForPublishing ?? false
    case 'isNotReadyForPublishing':
      return !(whiteLabel.isReadyForPublishing ?? false)
  }
}

const freeTextWhiteLabelFilterFunction = (whiteLabel: WhiteLabelListEntryFragment, searchString: string): boolean => {
  if (searchString === '') return true

  return (
    (whiteLabel.appName?.toLowerCase().includes(searchString.toLowerCase()) ?? false) ||
    (whiteLabel.label?.toLowerCase().includes(searchString.toLowerCase()) ?? false) ||
    (whiteLabel.restaurants?.edges?.some(edge => edge.node.name?.toLowerCase().includes(searchString.toLowerCase())) ?? false) ||
    (whiteLabel.restaurants?.edges?.some(edge => edge.node.id?.toLowerCase() === searchString.toLowerCase()) ?? false)
  )
}

const WhiteLabelList: React.FC = () => {
  const [navigation] = useNavigation<'WhiteLabelList'>()
  const { data, loading } = useListWhiteLabelsQuery()
  const [whiteLabelFilter, setWhiteLabelFilter] = useState<WhiteLabelFilter>('all')

  const form = useForm<WhiteLabelListFormFields>({
    defaultValues: { searchString: '' },
    criteriaMode: 'all',
    resolver: yupResolver(schema)
  })

  const appFilter = form.watch('searchString') ?? ''

  const whiteLabels = useMemo(() => {
    if (data?.whiteLabels?.edges == null) return

    return sortOn(data.whiteLabels.edges.map(edge => edge.node).filter(wl => whiteLabelFilterFunction(wl, whiteLabelFilter)).filter(wl => freeTextWhiteLabelFilterFunction(wl, appFilter)), 'appName')
  }, [appFilter, data?.whiteLabels?.edges, whiteLabelFilter])

  const handleFilterChange = useCallback((filter: WhiteLabelFilter): void => {
    setWhiteLabelFilter(filter)
  }, [])

  const subtitle = useCallback((whiteLabel: WhiteLabelListEntryFragment) => {
    if (whiteLabel?.restaurants?.edges == null) return ''
    if (whiteLabel?.restaurants?.edges.length === 1) return 'En restaurang'
    return `${whiteLabel?.restaurants?.edges.length} restauranger`
  }, [])

  return (
    <Layout screenType={ScreenType.List} title='Applikationer'>
      <ListContainer>
        <HStack alignItems='baseline' columnGap={24} justifyContent='space-between' padding={16} wrap>
          <Text size={24}>{whiteLabels?.length ?? '...'} stycken</Text>

          <AddButton
            icon='add-circle'
            onPress={() => navigation.navigate('WhiteLabelCreate', {})}
            title='Skapa ny Whitelabel'
          />
        </HStack>

        <ButtonGroup
          buttons={whiteLabelFilterOptions}
          onPress={handleFilterChange}
          selected={whiteLabelFilter}
        />

        <TextField
          estimatedNumberOfCharacters={20}
          form={form}
          name='searchString'
          title='Filtrera app'
          value={appFilter}
        />

        {loading
          ? <ActivityIndicator />
          : (
            <ScrollView>
              <HStack gap={16} padding={8} wrap>
                {whiteLabels?.map(whiteLabel => (
                  <TouchableOpacity
                    key={whiteLabel.id}
                    onPress={() => navigation.navigate('WhiteLabelView', { whiteLabelId: whiteLabel.id })}
                    style={{ backgroundColor: whiteLabel.theme?.backgroundColor, borderRadius: 8, overflow: 'hidden', width: 272 }}
                  >
                    <HStack alignItems='center' backgroundColor={whiteLabel.theme?.cardColor} gap={16} padding={16}>
                      <MaterialIcons color={whiteLabel.theme?.primaryColor} name='label' size={32} />

                      <VStack>
                        <Text color={whiteLabel.theme?.textColor} size={16}>{whiteLabel.appName}</Text>
                        <Text color={whiteLabel.theme?.textColor} size={14}>{subtitle(whiteLabel)}</Text>
                      </VStack>
                    </HStack>

                    <VStack padding={16}>
                      <Image
                        source={{ uri: whiteLabel.appIconUrl ?? undefined }}
                        style={{ width: 240, height: 240, borderRadius: 16 }}
                      />
                    </VStack>
                  </TouchableOpacity>
                ))}
              </HStack>

              <Spacer height={normalize(12, 20)} />
            </ScrollView>
          )}
      </ListContainer>
    </Layout>
  )
}

export default WhiteLabelList
