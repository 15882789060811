import { MaterialCommunityIcons } from '@expo/vector-icons'
import gql from 'graphql-tag'
import { useState } from 'react'
import { FlatList, Image, TouchableOpacity, View } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'

import { GetWhiteLabelGuestGroupListDataDocument, useCreateWhiteLabelGuestGroupMutation, useGetWhiteLabelGuestGroupListDataQuery } from '../../types/graphql'
import { CreateButton, PrimaryButton, SecondaryButton } from '../components/Buttons'
import DynamicIcon from '../components/DynamicIcon'
import EmptyListWizard, { EmptyListWizardButton } from '../components/EmptyListWizard'
import Layout, { ScreenType } from '../components/Layout'
import Link from '../components/Link'
import ListContainer from '../components/ListContainer'
import ListItemWrapper from '../components/ListItemWrapper'
import Warning from '../components/Warning'
import Heading from '../components/atoms/Heading'
import Paragraph from '../components/atoms/Paragraph'
import logError from '../util/logError'
import showAlert from '../util/showAlert'
import useNavigation from '../util/useNavigation'

gql`
  query GetWhiteLabelGuestGroupListData($id: ID!) {
    whiteLabel(id: $id) {
      id

      guestGroups {
        edges {
          node {
            id

            cardBackgroundColor
            cardImageUrl
            memberCount
            name
          }
        }
      }
    }
  }
`

const WhiteLabelGuestGroupList: React.FC = () => {
  const [navigation, { whiteLabelId }] = useNavigation<'WhiteLabelGuestGroupList'>()

  const { data, loading } = useGetWhiteLabelGuestGroupListDataQuery({ variables: { id: whiteLabelId } })

  const [createWhiteLabelGuestGroup, { error: createWhiteLabelGuestGroupError, loading: saving }] = useCreateWhiteLabelGuestGroupMutation()

  const [setupOption, setSetupOption] = useState<'custom' | 'default' | null>(null)

  const guestGroups = data?.whiteLabel?.guestGroups?.edges ?? []

  const handleGuestGroupPress = (guestGroupId: string): void => {
    navigation.navigate('WhiteLabelGuestGroupView', { whiteLabelId, guestGroupId })
  }

  const handleSetupOptionPress = (): void => {
    if (setupOption === null) {
      showAlert('Gör val innan du fortsätter')

      return
    }

    if (setupOption === 'custom') {
      navigation.navigate('WhiteLabelGuestGroupCreate', { whiteLabelId })
    }

    const [bronze, silver, gold] = [
      {
        cardBackgroundColor: '#c48062',
        cardImageUrl: 'https://locousercontent.com/-0rPoFz2pt2XSlaUYxP8xhOODZebuNUtbym8yVxrKICm0u0Vk-lpH0HGSJ2l05io/original.webp',
        invitationCode: 'ei309fjkl2',
        name: 'Bronze'
      },
      {
        cardBackgroundColor: '#cccbce',
        cardImageUrl: 'https://locousercontent.com/blb6x8xL2ibZCggaXLD_CP-nHFyvC556w_sRkFbEIpLF6477kefntS_CjlNxMITM/original.webp',
        invitationCode: 'abwj2k10d',
        name: 'Silver'
      },
      {
        cardBackgroundColor: '#c6a66a',
        cardImageUrl: 'https://locousercontent.com/Lnfz-xaCeMegf9HU0C5z43J9hszA6NHd7aIKBk_ZWGU87Sg2NDGOnTTCEdNF4Oeu/original.webp',
        invitationCode: '3j2k1l2qwj',
        name: 'Gold'
      }
    ]

    async function createDefaultGroups (): Promise<void> {
      await createWhiteLabelGuestGroup({ variables: { input: bronze, whiteLabelId } })
      await createWhiteLabelGuestGroup({ variables: { input: silver, whiteLabelId } })
      await createWhiteLabelGuestGroup({
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GetWhiteLabelGuestGroupListDataDocument, variables: { id: whiteLabelId } }],
        variables: { input: gold, whiteLabelId }
      })
    }

    createDefaultGroups().catch(logError)
  }

  return (
    <Layout
      breadcrumbs={[
        { link: ['WhiteLabelDashboard', { whiteLabelId }], title: 'App' }
      ]}
      loading={loading}
      screenType={ScreenType.List}
      title='Lojalitetsgrupper'
    >
      {guestGroups.length === 0 || saving
        ? (
          <EmptyListWizard>
            <Heading size='l'>Så här använder du lojalitetsgrupper</Heading>

            <Paragraph>
              Tänk på att i vårt Helpcenter på <Link title='help.gastrogate.io' url='help.gastrogate.io' /> kan du hitta svar på de vanligaste frågorna!
            </Paragraph>

            <Paragraph>Det är lojalitetsgrupperna som utgör kärnan i Locos kundregister och lojalitetsprogram. I grupperna administrerar du dina medlemmar, hanterar hur lojalitetsprogrammet ska exponeras i appen, och lätt får en överblick över vilka rabatter som just nu är aktiva i programmet.</Paragraph>

            <Paragraph>Nedan kan du snabbt komma igång genom att skapa upp standardgrupperna automatiskt, eller genom att manuellt sätta upp grupperna.</Paragraph>

            <HStack gap={20} justifyContent='space-evenly' wrap>
              <EmptyListWizardButton
                onPress={() => setSetupOption(selectedOption => selectedOption === 'default' ? null : 'default')}
                selected={setupOption === 'default'}
              >
                <VStack alignItems='center' grow={1} justifyContent='center'>
                  <MaterialCommunityIcons color='#ff9900' name='star-cog' size={72} />

                  <Spacer height={12} />

                  <Text color='#ff9900' size={16}>Skapa grupperna:</Text>

                  <Spacer height={3} />

                  <Text color='#ff9900' size={24} weight='bold'>Bronze, Silver, Gold</Text>
                </VStack>
              </EmptyListWizardButton>

              <EmptyListWizardButton
                onPress={() => setSetupOption(selectedOption => selectedOption === 'custom' ? null : 'custom')}
                selected={setupOption === 'custom'}
              >
                <VStack alignItems='center' grow={1} justifyContent='center'>
                  <MaterialCommunityIcons color='#ff9900' name='cog' size={72} />

                  <Spacer height={12} />

                  <Text color='#ff9900' size={24}>Skapa egna grupper</Text>
                </VStack>
              </EmptyListWizardButton>
            </HStack>

            {createWhiteLabelGuestGroupError == null ? null : <Warning message={createWhiteLabelGuestGroupError.message} />}

            <PrimaryButton
              disabled={setupOption === null || saving}
              loading={saving}
              onPress={handleSetupOptionPress}
              title='Fortsätt'
            />
          </EmptyListWizard>
        )
        : (
          <ListContainer>
            <HStack justifyContent='end'>
              <SecondaryButton onPress={() => navigation.navigate('WhiteLabelGuestGroupCustomerRegister', { whiteLabelId })} title='Kundregister' />
            </HStack>

            <CreateButton onPress={() => navigation.navigate('WhiteLabelGuestGroupCreate', { whiteLabelId })} title='Skapa lojalitetsgrupp' />

            <FlatList
              data={guestGroups}
              ItemSeparatorComponent={() => <Spacer height={8} />}
              keyExtractor={(item) => item.node.id}
              ListEmptyComponent={() => <Text align='center' padding={16}>Inga lojalitetsgrupper</Text>}
              renderItem={({ item }) => {
                return (
                  <ListItemWrapper>
                    <HStack gap={8} width='100%'>
                      <VStack justifyContent='center' shrink={1}>
                        <Image source={{ uri: item.node.cardImageUrl ?? undefined }} style={{ width: 86 * 2, height: 54 * 2, backgroundColor: '#fff' }} />
                      </VStack>

                      <TouchableOpacity onPress={() => handleGuestGroupPress(item.node.id)} style={{ flexGrow: 1 }}>
                        <HStack grow={1}>
                          <VStack justifyContent='space-between'>
                            <Text size={16}>{item.node.name}</Text>

                            <VStack gap={3}>
                              <HStack alignItems='center' gap={3}>
                                <Text color='#3f3f3f' size={12}>Temafärg:</Text>

                                <View
                                  style={{
                                    backgroundColor: item.node.cardBackgroundColor ?? undefined,
                                    borderColor: '#f2f2f2',
                                    borderRadius: 5,
                                    borderWidth: 1,
                                    height: 15,
                                    width: 15
                                  }}
                                />

                                <Text color='#3f3f3f' size={12}>{item.node.cardBackgroundColor}</Text>
                              </HStack>

                              <HStack gap={3}>
                                <Text color='#3f3f3f' size={12}>Antal medlemmar:</Text>
                                <Text color='#3f3f3f' size={12}>{item.node.memberCount ?? 0}</Text>
                              </HStack>
                            </VStack>
                          </VStack>

                          <Spacer grow={1} width={16} />

                          <DynamicIcon name='arrow-right' size={24} type='material' />
                        </HStack>
                      </TouchableOpacity>
                    </HStack>
                  </ListItemWrapper>
                )
              }}
            />
          </ListContainer>
        )}
    </Layout>
  )
}

export default WhiteLabelGuestGroupList
