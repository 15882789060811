import { MaterialIcons } from '@expo/vector-icons'
import gql from 'graphql-tag'
import React from 'react'
import { TouchableOpacity } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'
import unwrap from 'ts-unwrap'
import key from 'weak-key'

import { type FullAlternativeGroupFragment, type FullAlternativeItemFragment, GetMenuAlternativeGroupListDataDocument, RearrangalStatus, useGetMenuAlternativeGroupListDataQuery, useRearrangeMenuAlternativeGroupsMutation } from '../../types/graphql'
import AddonProductList from '../components/AddonProductList'
import { AddButton } from '../components/Buttons'
import DraggableList from '../components/DraggableList'
import Layout, { ScreenType } from '../components/Layout'
import ListContainer from '../components/ListContainer'
import formatCurrency from '../util/formatCurrency'
import ignoreAsync from '../util/ignoreAsync'
import useNavigation from '../util/useNavigation'

gql`
  fragment FullAlternativeItem on MenuAlternativeItem {
    countableUsage
    isDefault
    name

    addonProduct {
      id

      name

      properties {
        id

        stateId
        description
        imageUrl
        isActive
        price

        reportGroup {
          ...FullMenuReportGroup
        }
      }
    }
  }

  fragment FullAlternativeGroup on MenuAlternativeGroup {
    id

    forceChoice
    isCountable
    maxChoices
    minChoices
    name

    alternativeItems {
      ...FullAlternativeItem
    }
  }

  query GetMenuAlternativeGroupListData($restaurantId: ID!) {
    restaurant(id: $restaurantId) {
      id
      name

      location {
        latitude
        longitude
      }

      menu(filter: { active: All, includeProductsWithOpenPrice: true }) {
        id
        alternativeGroups {
          ...FullAlternativeGroup
        }

        addonProducts {
          ...FullAddonProduct
        }

        reportGroups {
          ...FullMenuReportGroup
        }
      }
    }
  }

  mutation RearrangeMenuAlternativeGroups($restaurantId: ID!, $order: [ID!]!) {
    rearrangeMenuAlternativeGroups(restaurantId: $restaurantId,  order: $order)
  }
`

interface AlternativeItemProps {
  item: FullAlternativeItemFragment
}

const AlternativeItem: React.FC<AlternativeItemProps> = ({ item }) => {
  return (
    <HStack>
      <Text>
        {item.name}, {formatCurrency(item.addonProduct?.properties?.price ?? 0)}
      </Text>
    </HStack>
  )
}
interface AlternativeGroupListItemProps {
  alternativeItems: readonly FullAlternativeItemFragment[] | null
  onPress: () => void
  title: string
}

const AlternativeGroupListItem: React.FC<AlternativeGroupListItemProps> = ({ alternativeItems, onPress, title }) => {
  return (
    <TouchableOpacity onPress={onPress} style={{ flex: 1 }}>
      <HStack alignItems='center' minHeight={56} padding={12}>
        <VStack grow={1}>
          <Text size={16}>
            {title}
          </Text>
        </VStack>

        <Spacer width={24} />

        <HStack alignItems='center'>
          <VStack alignItems='end' grow={1}>
            {alternativeItems?.map(item => <AlternativeItem key={key(item)} item={item} />)}
          </VStack>

          <Spacer width={16} />

          <MaterialIcons name='edit' size={16} />
        </HStack>
      </HStack>
    </TouchableOpacity>
  )
}

const MenuAlternativeGroupList: React.FC = () => {
  const [navigation, { restaurantId }] = useNavigation<'MenuAlternativeGroupList'>()

  const { data, loading } = useGetMenuAlternativeGroupListDataQuery({ variables: { restaurantId } })

  const [rearrangeMenuAlternativeGroups] = useRearrangeMenuAlternativeGroupsMutation()

  const handleDragEnd = ignoreAsync(async (rearrangedAlternativeGroups: FullAlternativeGroupFragment[]) => {
    await rearrangeMenuAlternativeGroups({
      optimisticResponse: { rearrangeMenuAlternativeGroups: RearrangalStatus.Rearranged },
      refetchQueries: [{ query: GetMenuAlternativeGroupListDataDocument, variables: { restaurantId } }],
      update: (proxy) => {
        const newData = { ...data, restaurant: { ...data?.restaurant, menu: { ...data?.restaurant?.menu, alternativeGroups: rearrangedAlternativeGroups } } }
        proxy.writeQuery({ query: GetMenuAlternativeGroupListDataDocument, variables: { restaurantId }, data: newData })
      },
      variables: { restaurantId, order: rearrangedAlternativeGroups.map(group => group.id) }
    })
  })

  return (
    <Layout loading={loading} screenType={ScreenType.List} title='Tillägg och Alternativgrupper'>
      <ListContainer>
        <AddButton
          icon='add-circle'
          onPress={() => navigation.navigate('MenuAddonProductCreate', { restaurantId })}
          title='Skapa ny tilläggsprodukt'
        />

        <AddonProductList
          data={data ?? null}
          hideItem={product => product.isDeliveryCost === true}
          loading={loading}
          onPress={product => navigation.navigate('MenuAddonProductEdit', { restaurantId, menuAddonProductId: product.id })}
          restaurantId={restaurantId}
        />
      </ListContainer>

      <ListContainer>
        <AddButton
          icon='add-circle'
          onPress={() => navigation.navigate('MenuAlternativeGroupCreate', { restaurantId })}
          title='Skapa ny alternativgrupp'
        />

        <DraggableList
          data={data?.restaurant?.menu?.alternativeGroups ?? []}
          keyExtractor={item => item.id}
          onDragEnd={handleDragEnd}
          renderItem={(dragHandle, alternativeGroup) => (
            <HStack alignItems='center' paddingHorizontal={8} paddingVertical={12}>
              {dragHandle}

              <AlternativeGroupListItem
                alternativeItems={alternativeGroup.alternativeItems ?? null}
                onPress={() => navigation.navigate('MenuAlternativeGroupEdit', { restaurantId, menuAlternativeGroupId: unwrap(alternativeGroup.id) })}
                title={unwrap(alternativeGroup.name)}
              />
            </HStack>
          )}
        />
      </ListContainer>
    </Layout>
  )
}

export default MenuAlternativeGroupList
