import gql from 'graphql-tag'
import React, { useMemo, useState } from 'react'
import { ActivityIndicator, TouchableOpacity } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, VStack } from 'react-stacked'
import key from 'weak-key'

import { GetMenuOpeningHoursDocument, WeekDay, useDeleteOpeningHoursMutation, useGetMenuOpeningHoursQuery, useGetRestaurantOpeningHoursQuery } from '../../types/graphql'
import Bin from '../components/Bin'
import { AddButton, PrimaryButton } from '../components/Buttons'
import Layout from '../components/Layout'
import OpeningHoursShortform from '../components/OpeningHoursShortform'
import VerificationDialog from '../components/VerificationDialog'
import extractPeriods from '../util/extractPeriods'
import getBunchedOpeningPeriods, { type BunchedPeriods } from '../util/getBunchedOpeningPeriods'
import getBunchedPeriodsShortform from '../util/getBunchedPeriodsShortform'
import ignoreAsync from '../util/ignoreAsync'
import { normalize } from '../util/normalize'
import useNavigation from '../util/useNavigation'

gql`
  mutation DeleteOpeningHours($openingHoursId: ID!, $restaurantId: ID!) {
    deleteOpeningHours(
      openingHoursId: $openingHoursId
      restaurantId: $restaurantId
    )
  }
`

interface DeleteOpeningHoursDialogProps {
  name: string | null | undefined
  onDismiss: () => void
  openingHoursId: string
  restaurantId: string
}

const DeleteOpeningHoursDialog: React.FC<DeleteOpeningHoursDialogProps> = ({ name, onDismiss, openingHoursId, restaurantId }) => {
  const [deleteOpeningHours, { error, loading }] = useDeleteOpeningHoursMutation({
    awaitRefetchQueries: true,
    onCompleted: onDismiss,
    refetchQueries: [{ query: GetMenuOpeningHoursDocument, variables: { restaurantId } }],
    variables: { restaurantId, openingHoursId }
  })

  const handleDelete = ignoreAsync(async () => {
    await deleteOpeningHours()
  })

  return (
    <VerificationDialog
      errorMessage={error?.message}
      loading={loading}
      onDelete={handleDelete}
      onDismiss={onDismiss}
      open
      prompt={`Vill du permanent ta bort serveringstiden "${name ?? 'Ej namngiven'}"?`}
      title='Radera serveringstid'
    />
  )
}

interface OpeningHoursListItemProps {
  bunchedPeriods: BunchedPeriods[]
  displayHours?: string | null
  onPress: () => void
  placeholder?: boolean
  title: string
}

const OpeningHoursListItem: React.FC<OpeningHoursListItemProps> = ({ bunchedPeriods, displayHours, placeholder, onPress, title }) => {
  return (
    <TouchableOpacity onPress={onPress} style={{ flexGrow: 1 }}>
      <HStack backgroundColor={(placeholder ?? false) ? '#DBDBDB' : '#FFF'} borderRadius={4} gap={8} minHeight={56} padding={12}>
        <VStack grow={1}>
          <Text color={(placeholder ?? false) ? '#888' : '#000'} size={16}>
            {title}
          </Text>
          {displayHours == null ? null : <Text>Visningstid: {displayHours}</Text>}
        </VStack>

        <VStack alignItems='end' grow={1}>
          {bunchedPeriods.length === 0
            ? <Text>Alltid stängt</Text>
            : (getBunchedPeriodsShortform(bunchedPeriods).map(period => (
              <OpeningHoursShortform
                key={key(period)}
                closeTime={period.closeTime}
                firstDay={period.firstDay}
                lastDay={period.lastDay}
                openTime={period.openTime}
                placeholder={placeholder}
              />
            )))}
        </VStack>
      </HStack>
    </TouchableOpacity>
  )
}

const OpeningHoursList: React.FC = () => {
  const [navigation, { restaurantId }] = useNavigation<'OpeningHoursList'>()

  const { data: restaurantData, loading: loadingRestaurantData } = useGetRestaurantOpeningHoursQuery({ variables: { restaurantId } })
  const { data: menuData, loading: loadingMenuData } = useGetMenuOpeningHoursQuery({ variables: { restaurantId } })

  const openingPeriods = useMemo(() => extractPeriods(restaurantData?.restaurant?.openingHours?.periods), [restaurantData?.restaurant?.openingHours?.periods])
  const bunchedPeriods = useMemo(() => getBunchedOpeningPeriods(openingPeriods), [openingPeriods])

  const [selectedForDeletion, setSelectedForDeletion] = useState<{ id: string, name?: string | null }>()

  return (
    <Layout title='Öppettider'>
      <VStack maxWidth={1024} padding={normalize(10, 15)}>
        <PrimaryButton
          onPress={() => navigation.navigate('RestaurantOpeningHoursView', { restaurantId })}
          title={`${bunchedPeriods.length === 0 ? 'Lägg till' : 'Ändra'} restaurangens öppettider`}
        />

        <Spacer height={16} />

        {loadingRestaurantData
          ? null
          : (
            <OpeningHoursListItem
              bunchedPeriods={bunchedPeriods}
              onPress={() => navigation.navigate('RestaurantOpeningHoursView', { restaurantId })}
              title='Restaurangens öppettider (när det går att beställa)'
            />
          )}

        <Spacer height={32} />

        <AddButton
          icon='add-circle'
          onPress={() => navigation.navigate('MenuOpeningHoursCreate', { restaurantId })}
          title='Skapa ny serveringstid'
        />

        <Spacer height={16} />

        {/* {Placeholder} */}
        {menuData?.restaurant?.menu?.productOpeningHours?.length !== 0 ? null : (
          <OpeningHoursListItem
            bunchedPeriods={getBunchedOpeningPeriods([
              { open: { day: WeekDay.Monday, time: '11:00:00' }, close: { day: WeekDay.Monday, time: '15:00:00' } },
              { open: { day: WeekDay.Tuesday, time: '11:00:00' }, close: { day: WeekDay.Tuesday, time: '14:00:00' } },
              { open: { day: WeekDay.Wednesday, time: '11:00:00' }, close: { day: WeekDay.Wednesday, time: '14:00:00' } },
              { open: { day: WeekDay.Thursday, time: '11:00:00' }, close: { day: WeekDay.Thursday, time: '14:00:00' } },
              { open: { day: WeekDay.Friday, time: '11:00:00' }, close: { day: WeekDay.Friday, time: '14:00:00' } }
            ])}
            onPress={() => null}
            placeholder
            title='Lunch (Serveringstidsexempel)'
          />
        )}

        {/* {Menu opening hours} */}
        {loadingMenuData ? <ActivityIndicator /> : (
          <VStack gap={8}>
            {menuData?.restaurant?.menu?.productOpeningHours?.map(openingHour => (
              <HStack key={key(openingHour)} alignItems='center' gap={16}>
                <OpeningHoursListItem
                  bunchedPeriods={getBunchedOpeningPeriods(extractPeriods(openingHour.periods))}
                  displayHours={openingHour.displayHours?.name}
                  onPress={() => navigation.navigate('MenuOpeningHoursEdit', { restaurantId, menuOpeningHoursId: openingHour.id })}
                  title={openingHour?.name ?? 'Ej namngiven'}
                />

                <Bin
                  onSelected={() => {
                    setSelectedForDeletion({ id: openingHour.id, name: openingHour.name })
                  }}
                />
              </HStack>
            ))}
          </VStack>
        )}
      </VStack>

      {selectedForDeletion == null ? undefined : (
        <DeleteOpeningHoursDialog
          name={selectedForDeletion.name}
          onDismiss={() => setSelectedForDeletion(undefined)}
          openingHoursId={selectedForDeletion.id}
          restaurantId={restaurantId}
        />
      )}
    </Layout>
  )
}

export default OpeningHoursList
