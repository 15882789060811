import gql from 'graphql-tag'
import { useForm } from 'react-hook-form'
import { ActivityIndicator, Image, TouchableOpacity } from 'react-native'
import { HStack, Text, TextStyle, VStack } from 'react-stacked'

import { useConnectDeliveryHeroIntegrationMutation, useGetDeliveryHeroRestaurantIntegrationDataQuery } from '../../types/graphql'
import FormContainer from '../components/FormContainer'
import SubmitFormButtons from '../components/SubmitFormButtons'
import { TextField } from '../components/TextField'
import Heading from '../components/atoms/Heading'
import Paragraph from '../components/atoms/Paragraph'
import yup, { yupResolver } from '../lib/validation'
import ignoreAsync from '../util/ignoreAsync'
import logError from '../util/logError'
import openLink from '../util/openLink'

gql`
  query GetDeliveryHeroRestaurantIntegrationData($restaurantId: ID!) {
    restaurant(id: $restaurantId) {
      id

      name
    }
  }

  mutation ConnectDeliveryHeroIntegration($restaurantId: ID!, $accessToken: String!, $input: ConnectDeliveryHeroIntegrationInput!) {
    connectDeliveryHeroIntegration(restaurantId: $restaurantId, accessToken: $accessToken, input: $input) {
      id

      name
    }
  }
`

interface Form {
  accessToken: string
  chainId: string
  restaurantId: string
  vendorId: string
}

const schema = yup.object({
  accessToken: yup.string().trim().required(),
  chainId: yup.string().trim().required(),
  restaurantId: yup.string().trim().required(),
  vendorId: yup.string().trim().required()
})

const DeliveryHeroRestaurantIntegration: React.FC = () => {
  const form = useForm<Form>({
    criteriaMode: 'all',
    mode: 'onSubmit',
    resolver: yupResolver(schema)
  })

  const restaurantId = form.watch('restaurantId')
  const hasRestaurantId = (restaurantId?.length ?? 0) >= 10

  const { data, loading } = useGetDeliveryHeroRestaurantIntegrationDataQuery({ skip: !hasRestaurantId, variables: { restaurantId } })
  const [connectIntegration, { called, error, loading: saving }] = useConnectDeliveryHeroIntegrationMutation()

  const handleSubmit = (input: Form): void => {
    const variables = {
      restaurantId: input.restaurantId,
      accessToken: input.accessToken,
      input: {
        deliveryHeroChainId: input.chainId,
        deliveryHeroVendorId: input.vendorId
      }
    }

    connectIntegration({ variables }).catch(logError)
  }

  return (
    <VStack alignItems='center' gap={24} padding={16}>
      <TouchableOpacity onPress={() => openLink('https://gastrogate.io')} style={{ maxWidth: 700, width: '100%' }}>
        <HStack alignItems='center' gap={12}>
          <Image source={{ uri: 'https://locousercontent.com/3Qo9-5GAzP3eyc8GqwlgdaDjpQtcPeRXifKJqCuE38QCa1qXW6naDarjS8FAmuiJ/original.png' }} style={{ height: 48, width: 48 }} />
          <Text size={32}>Gastrogate</Text>
        </HStack>
      </TouchableOpacity>

      <FormContainer gap={20}>
        <Heading size='l'>
          Foodora integration
        </Heading>

        <Paragraph>
          Använd detta formulär för att koppla ihop en restaurang i ert system med en av våra restauranger. Notera att i dagsläget så stödjer vi endast "direkt integration", d.v.s. att vårt system ska vara ansvarig för att låta kunden acceptera/neka ordrar.
        </Paragraph>

        <TextField
          estimatedNumberOfCharacters={10}
          form={form}
          hint='ID:t för restaurangen i vårt system. Ni hittar denna i mailet från oss, som ni fick när kunden begärde en integrering.'
          name='restaurantId'
          title='Loco restaurant ID'
        />

        <HStack>
          {loading ? <ActivityIndicator /> : null}
          {data?.restaurant?.name == null ? null : (
            <Text>
              <TextStyle weight='bold'>Restaurang:</TextStyle> {data?.restaurant?.name}
            </Text>
          )}
        </HStack>

        <TextField
          form={form}
          hint='Nyckel för att koppla ihop restaurangen med Foodora. Ni hittar denna i mailet från oss.'
          name='accessToken'
          title='Access Token'
        />

        <TextField form={form} name='chainId' title='Foodora Chain ID' />
        <TextField form={form} name='vendorId' title='Foodora Vendor ID' />

        <SubmitFormButtons
          called={called}
          error={error}
          onSave={ignoreAsync(form.handleSubmit(handleSubmit))}
          saving={saving}
          successMessage='Integrationen är nu förberedd för att synka menyn'
        />
      </FormContainer>
    </VStack>
  )
}

export default DeliveryHeroRestaurantIntegration
