import { MaterialIcons } from '@expo/vector-icons'
import dotProp from 'dot-prop-immutable'
import gql from 'graphql-tag'
import React from 'react'
import { ActivityIndicator, TouchableOpacity } from 'react-native'
import { HStack } from 'react-stacked'

import { type FullAddonProductFragment, GetAddonProductsDocument, type GetAddonProductsQuery, RearrangalStatus, useRearrangeAddonProductsMutation } from '../../types/graphql'
import logError from '../util/logError'

import { AddonProductCard } from './AddonProductList/AddonProductCard'
import DraggableList from './DraggableList'

gql`
  mutation RearrangeAddonProducts($restaurantId: ID!, $order: [ID!]!) {
    rearrangeMenuAddonProducts(
      restaurantId: $restaurantId
      order: $order
    )
  }
`

interface AddonProductListProps {
  data: GetAddonProductsQuery | null
  hideItem: (item: FullAddonProductFragment) => boolean
  loading: boolean
  onPress: (item: FullAddonProductFragment) => void
  restaurantId: string
}

const AddonProductList: React.FC<AddonProductListProps> = ({ data, hideItem, loading, onPress, restaurantId }) => {
  const [rearrangeAddonProducts] = useRearrangeAddonProductsMutation({ awaitRefetchQueries: true })

  const handleDragEnd = (rearrangedAddonProducts: FullAddonProductFragment[]): void => {
    rearrangeAddonProducts({
      variables: { restaurantId, order: rearrangedAddonProducts.map(product => product.id) },
      optimisticResponse: { rearrangeMenuAddonProducts: RearrangalStatus.Rearranged },
      update: (proxy) => {
        const newData = dotProp.set(data, 'restaurant.menu.addonProducts', rearrangedAddonProducts)
        proxy.writeQuery({ query: GetAddonProductsDocument, variables: { restaurantId }, data: newData })
      },
      refetchQueries: [{ query: GetAddonProductsDocument, variables: { restaurantId } }]
    }).catch(logError)
  }

  if (loading) return <ActivityIndicator />

  return (
    <DraggableList
      data={data?.restaurant?.menu?.addonProducts ?? []}
      hideItem={hideItem}
      keyExtractor={addonProduct => addonProduct.id}
      onDragEnd={handleDragEnd}
      renderItem={(dragHandle, product) => (
        <HStack alignItems='center' paddingHorizontal={8} paddingVertical={12}>
          {dragHandle}

          <TouchableOpacity onPress={() => onPress(product)} style={{ alignItems: 'center', flex: 1, flexDirection: 'row' }}>
            <AddonProductCard key={product.id} product={product} />
            <MaterialIcons name='edit' size={16} />
          </TouchableOpacity>
        </HStack>
      )}
    />
  )
}

export default AddonProductList
