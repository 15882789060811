import { TouchableOpacity } from 'react-native'
import { VStack } from 'react-stacked'

import FormContainer from './FormContainer'

interface EmptyListWizardButtonProps {
  children: React.ReactNode
  onPress: () => void
  selected: boolean
}

export const EmptyListWizardButton: React.FC<EmptyListWizardButtonProps> = ({ children, onPress, selected }) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        backgroundColor: '#f7f7f7',
        borderColor: selected ? '#ff9900' : '#ccc',
        borderRadius: 24,
        borderStyle: 'solid',
        borderWidth: selected ? 4 : 2,
        flexGrow: 1,
        maxWidth: 350,
        minHeight: 250,
        padding: 12,
        paddingBottom: 32
      }}
    >
      <VStack
        alignItems='center'
        backgroundColor='#fff'
        borderColor='#aaa'
        borderRadius={10}
        borderStyle='solid'
        borderWidth={2}
        height={20}
        justifyContent='center'
        width={20}
      >
        {!selected ? null : (
          <VStack
            backgroundColor='#ff9900'
            borderRadius={6}
            height={12}
            width={12}
          />
        )}
      </VStack>

      {children}
    </TouchableOpacity>
  )
}

interface EmptyListWizardProps {
  children?: React.ReactNode
}

const EmptyListWizard: React.FC<EmptyListWizardProps> = ({ children }) => {
  return (
    <FormContainer gap={20} wide>
      {children ?? null}
    </FormContainer>
  )
}

export default EmptyListWizard
