import { MaterialIcons } from '@expo/vector-icons'
import { Temporal } from '@js-temporal/polyfill'
import React from 'react'
import { TouchableOpacity } from 'react-native'
import Spacer from 'react-spacer'
import { HStack, Text, TextStyle, VStack } from 'react-stacked'

import { type FullPhysicalControlUnitFragment } from '../../types/graphql'
import formatDateTime from '../util/formatDateTime'

import ListItemWrapper from './ListItemWrapper'

interface ControlUnitListItemProps {
  item: FullPhysicalControlUnitFragment
  onPress?: () => void
}

const ControlUnitListItem: React.FC<ControlUnitListItemProps> = ({ item, onPress }) => {
  const ongoingOutage = item.outages?.edges?.find(edge => edge.node.restoredAt == null)?.node

  return (
    <TouchableOpacity disabled={onPress == null} onPress={onPress}>
      <ListItemWrapper>
        <VStack alignItems='start' grow={1} paddingHorizontal={12}>
          <HStack alignItems='center' justifyContent='start'>
            <Text size={16}>{item.serialNumber}</Text>
          </HStack>

          <Spacer height={16} />

          {ongoingOutage == null
            ? null
            : (
              <VStack backgroundColor='#ffd0cd' borderRadius={3} padding={8}>
                <Text>
                  <TextStyle weight='bold'>Pågående driftstopp</TextStyle>
                </Text>

                <Spacer height={16} />

                {ongoingOutage.createdAt == null ? null : (
                  <Text>
                    <TextStyle weight='300'>Inträffade:</TextStyle> {formatDateTime(Temporal.Instant.from(ongoingOutage.createdAt).toZonedDateTimeISO('Europe/Stockholm'), { dateStyle: 'short', timeStyle: 'short' })}
                  </Text>
                )}
                <Text>
                  <TextStyle weight='300'>Antal påverkade kassaregister:</TextStyle>
                  {ongoingOutage?.cashregisters?.length ?? 'n/a'}
                </Text>
              </VStack>
            )}

          <Spacer height={16} />

          <VStack alignItems='start'>
            <Text size={14}>
              <TextStyle weight='bold'>Tillverkare:</TextStyle> {item.manufacturer}
            </Text>
            <Text size={14}>
              <TextStyle weight='bold'>Modell:</TextStyle> {item.model}
            </Text>
            <Text size={14}>
              <TextStyle weight='bold'>Tillverkningsnummer:</TextStyle> {item.serialNumber}
            </Text>
            <Text size={14}>
              <TextStyle weight='bold'>Adress:</TextStyle> {item.address}
            </Text>
            {item.createdAt == null ? null : (
              <Text size={14}>
                <TextStyle weight='bold'>Skapad:</TextStyle> {formatDateTime(Temporal.Instant.from(item.createdAt).toZonedDateTimeISO('Europe/Stockholm'), { dateStyle: 'short', timeStyle: 'short' })}
              </Text>
            )}
            {item.updatedAt == null ? null : (
              <Text size={14}>
                <TextStyle weight='bold'>Senast uppdaterad:</TextStyle> {formatDateTime(Temporal.Instant.from(item.updatedAt).toZonedDateTimeISO('Europe/Stockholm'), { dateStyle: 'short', timeStyle: 'short' })}
              </Text>
            )}
          </VStack>
        </VStack>

        <Spacer width={16} />

        {onPress == null ? null : <MaterialIcons name='arrow-right' size={24} />}
      </ListItemWrapper>
    </TouchableOpacity>
  )
}

export default ControlUnitListItem
